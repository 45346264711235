import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ModalStyle.css";
import middleware from "../Api/Middleware";

const BrandModal = ({ openBrand, closeBrand }) => {
  const [showBrand, setShowBrand] = useState(false);
  const [merkName, setMerkName] = useState("");
  const [description, setdescription] = useState("");
  const [keywords, setKeywords] = useState("");

  useEffect(() => {
    if (openBrand) {
      setShowBrand(true)
    }
  }, [openBrand]);
  
  const closeBrandModal = () => {
    setShowBrand(false);
    closeBrand(true)
  }

  const submitNewBrand = async (event) => {
    try {
      if (event) {
        event.preventDefault();
      }
      if (merkName) 
      {
        await middleware.post("products/brands", {
          name: merkName,
          description: description,
          keywords: keywords,
        }).then(() => window.location.reload());      
        
      }
    } catch (err) {
      console.error(err);
    }
  };

  
  return (
    <Modal
        id="modalBlueprint"
        show={showBrand}
        onHide={() => closeBrandModal()}
        
        size="md"
        backdrop="static"
      >
        <Modal.Header className="modalTop justify-content-between">
          <h4 className='ms-3'>Voeg een merk toe</h4>
          <button variant="transparent" className=' btn' onClick={() => closeBrandModal()}>
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <Modal.Body className="modalCenter">
          <div className="contendModal w-100">
            <h6 className="inputTitle">Merk naam *</h6>
            <input
              className="inputField borderInputs mb-2 w-100"
              type="text"
              name="merkName"
              onBlur={(e) => setMerkName(e.target.value)}
            ></input>
            <h6 className="inputTitle">Beschrijving </h6>
            <textarea
              className="borderInputs rounded-2 mb-2 w-100"
              type="text"
              name="merkDescription"
              onBlur={(e) => setdescription(e.target.value)}
            ></textarea>
            <h6 className="inputTitle">Trefwoorden </h6>
            <textarea
              className="borderInputs rounded-2 w-100"
              type="text"
              name="merkKeywords"
              onBlur={(e) => setKeywords(e.target.value)}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer className="modalBottom">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => closeBrandModal()}
          >
            Annuleren
          </button>
          <button
            type="sumbit"
            className="btn btn-success"
            onClick={() => {

              submitNewBrand();
              closeBrandModal()
            }}
          >
            Voeg toe
          </button>
        </Modal.Footer>
      </Modal>
  );
}

export default BrandModal;
