import React, { useMemo, useState, useEffect, useCallback } from "react";
import { useTable } from "react-table";
import middleware from "../../../Api/Middleware";
import salesChannels from "../../../Api/SalesChannels";
import TablePreset from "../../../Table/TablePreset";
import "../../../Table/Table.css";
import "../../../Orders/Orders.css";
import "../../../Main/Main.css";
import { useNavigate, useParams } from "react-router";
import { format } from 'date-fns'
import { nl } from 'date-fns/locale';
import TDSTextNavigation from "../../../Templates/TDSTextNavigation";
import CheckboxHeader from "../../../Table/TableComponents/CheckboxHeader";
import CheckboxCell from "../../../Table/TableComponents/CheckboxCell";
import SortableHeader from "../../../Table/TableComponents/SortableHeader";
import InputComponent from "../../AddCustomers/InputComponent";

const AddressData = () => {
  const navigate = useNavigate();
const [loading, setLoading] = useState(true);
  const [addAdress, setAddAdress] = useState(false);
  const [consumers, setConsumers] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [formData, setFormData] = useState({});
  const [salesChannel, setSalesChannel] = useState([]);
  const [orderIds, setOrderIds] = useState([]);
  const [pagination, setPagination] = useState({
    "page_size": 10,
    "page": 1,
    "max_items": 0,
    "begin_of_page": 0,
    "end_of_page": 0,
  });
  const [order, setOrder] = useState({orderBy: '', direction: ''});
  const [searchBar, setSearchBar] = useState("");
  const { consumer_id } = useParams();

//   const fetchAll = async () => {
//     setLoading(true);
//     try {
//         const [dataResponse] = await Promise.all([
//           middleware.get(`consumers?internal_id=${consumers_id}`),
//         ]);
//
//         setData(dataResponse.data.data);
//
//     } catch (error) {
//         // Handle errors here
//         console.error('Error fetching data:', error);
//     }
//     setLoading(false);
// };


const testData = [
  {
    "order_id": "001",
    "TypeOfAdress": "",
  },
  {
    "order_id": "002",
    "TypeOfAdress": "Verzendadres",
  },
  {
    "order_id": "003",
    "TypeOfAdress": "Factuuradres",
  },
  {
    "order_id": "004",
    "TypeOfAdress": "",
  }
];

const [data, setData] = useState(testData);

  // useEffect(() => {
  //   try {
  //     fetchAll();
  //   } catch (error) {
  //     console.warn(error)
  //   }
  // }, [pagination.page_size, pagination.page, order, order.direction]);

  const [selectedIds, setSelectedIds] = useState([]);

  // Handle checkbox selection
  const handleSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedIds(previous => [...previous, productId]);
    } else {
      setSelectedIds(previous => previous.filter(id => id !== productId));
    }
  };

  // Columns for the table
  const columns = useMemo(
    () => [
      {
        Header: (
          <CheckboxHeader currentItem={data} selectedIds={selectedIds} setSelectedIds={setSelectedIds} pk={'order_id'}>
          </CheckboxHeader>
        ),
        accessor: "select",
        Cell: ({ row }) => {
          return <CheckboxCell row={row} handleSelection={handleSelection} selectedIds={selectedIds} pk={'order_id'}/>
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} pos={"start"} field="order_number">
            Adres
          </SortableHeader>
        ),
        accessor: 'order_number',
        Cell: ({ row }) => {
          return (
            <div className="d-flex flex-column cursorPointer" style={{color: "#009fe3"}} onClick={() => handleNewAdres()}>
              <p className="mb-0">Appeltaartstraat 1, 1234 AB Appeldorp Nederland</p>
            </div>
          )
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} classNameHeader={"TypeAdressInfoHeader"} field="delivery_status">
            Soort
          </SortableHeader>
        ),
        accessor: 'TypeOfAdress',
        Cell: ({ row }) => {
          return (
            <div className="text-center">{row.original.TypeOfAdress}</div>
          )
        }
      },
    ], [order.orderBy, order.direction, selectedIds, setSelectedIds, data]
  );

  // Table instance
  const tableInstance = useTable({ columns, data: data });

  const normalizeLabel = (label) => {
    // Verwijder spaties aan het begin en einde, verwijder alle tussenliggende spaties en streepjes
    return label.trim().replace(/[\s\-]+/g, "");
  };

  const getValueInput = useCallback((value, label) => {
    // Normaliseer het label om consistentie te waarborgen
    const normalizedLabel = normalizeLabel(label);

    setFormData((prev) => ({ ...prev, [normalizedLabel]: value }));
  }, []);
  
  const handleNewAdres = () => {
    setAddAdress(currentState => !currentState)
  }

  return (
    <div className="adressOverviewTab h-100 mt-4 px-3">
      {addAdress ? 
        <div className="mt-5">
          <div>
            <div className=" d-flex flex-row mb-4">
              <h5 className="me-5">Adres toevoegen</h5>
              <select
              className="form-select inputFieldAddCustomer ms-5 dropdownsNewCustomers" style={{width: "250px"}}
              // onChange={(e) => setCountryIdInfo(JSON.parse(e.target.value))}
              >
                <option hidden>Kies een land</option>
                <option>Nederland</option>
                <option>België</option>
                {/* {countryInfo?.length > 0 && countryInfo.map(country => (
                  <option key={country.country_code_id} value={JSON.stringify(country)}>
                    {country.country_code_2}
                  </option>
                ))} */}
              </select>
            </div>
            <div className=" mb-4">
              <p className="fw-medium">Selecteer indien nodig primair adres</p>
              <div style={{ backgroundColor: "#FFFFFF" }}>
                <div className="d-flex flex-row align-items-center mb-3">
                  <label className="checkbox-containerAdressAdd me-2">
                    <input
                    type="checkbox"
                    // checked={true}
                    />
                    <span className="checkmarkAdressAdd"></span>
                  </label>
                  <p className="text-black fitContent mb-0 fw-medium">Factuuradres</p>
                </div>
                <div className="d-flex flex-row align-items-center mb-3">
                  <label className="checkbox-containerAdressAdd me-2">
                    <input
                    type="checkbox"
                    // checked={true}
                    />
                    <span className="checkmarkAdressAdd"></span>
                  </label>
                  <p className="text-black fitContent mb-0 fw-medium">Verzendadres</p>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="col-12 d-flex flex-row align-content-end my-3">
                <InputComponent
                  type={"text"}
                  onValueChange={getValueInput}
                  parentCol={6}
                  labelName={"Postcode"}
                  classnamesInput={"col-12 widthInputComponents px-3"}
                />
                <div className="col-6 d-flex justify-content-end">
                  <div className="col-12 d-flex widthInputComponents flex-row justify-content-end">
                    <InputComponent
                      alignmentInputs={"justify-content-start"}
                      onValueChange={getValueInput}
                      type={"number"}
                      parentCol={6}
                      labelName={"Huisnummer"}
                      classnamesInput={"col-12 widthInputComponents px-3"}
                    />
                    <InputComponent
                      alignmentInputs={"justify-content-end"}
                      onValueChange={getValueInput}
                      type={"text"}
                      parentCol={6}
                      labelName={"Toevoeging"}
                      classnamesInput={"col-12 widthInputComponents px-3"}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 d-flex flex-row align-content-end my-3 ">
                <InputComponent
                  type={"text"}
                  onValueChange={getValueInput}
                  alignmentInputs={"justify-content-start"}
                  parentCol={6}
                  labelName={"Straat"}
                  classnamesInput={"col-12 widthInputComponents px-3"}
                />
                <InputComponent
                  type={"text"}
                  onValueChange={getValueInput}
                  alignmentInputs={"justify-content-end"}
                  parentCol={6}
                  labelName={"Stad"}
                  classnamesInput={"col-12 widthInputComponents px-3"}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between mb-3">
            <button className="btn DarkStandardButton" onClick={() => handleNewAdres()}>Annuleren</button>
            <button className="btn lightBlueStandardButton" onClick={() => handleNewAdres()}>Toevoegen</button>
          </div>
        </div> 
      : 
        <div className="my-3 tablesCustomerEdit h-100">
          <TablePreset 
            data={[{'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'order_id'}]} 
            setPagination={setPagination} 
            handleSelection={handleSelection} 
            searchBar={[{ shown: true, value: searchBar, function: setSearchBar}]}
            leftButtons={[{title: "Filteren", function: () => console.log('a')}]} // leftButtons={[{title: "a", function: () => console.log('a')}, {title: "b", function: () => console.log('b')}]} 
            rightButtons={[{title: "Nieuw adres", function: () => handleNewAdres()}]} // rightButtons={[{title: "c", function: () => console.log('c')}]}
          />
        </div>
      }
    </div>
  );
}

export default AddressData;