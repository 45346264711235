import React from "react";
import "../Main/Main.css";
import "./GeneralSettings.css";
import Users from './Icons/users.svg';
import Padlock from './Icons/padlock.svg';
import NavbarIcon from '../Navbars/Sidebar/Icons/gear.png';
import BoxesWarehouse from "../Main/BoxesWarehouse";
import PageTemplate from "../Templates/PageTemplate";

const GeneralSettings = () => {
  return (
    <PageTemplate navbarTitle={"Algemene instellingen"} iconNavbar={NavbarIcon}>
        <div className="d-flex justify-content-between flex-column mb-4 ">
          <h3 className="mb-0   text-center mb-4">
            Algemene instellingen
          </h3>
          
          <div className=" d-flex justify-content-start w-100 flex-wrap">
            <BoxesWarehouse icon={Users} title={"Gebruikers"} link={"./gebruikers"}/>
            <BoxesWarehouse icon={Padlock} title={"Rechten"} link={"./kleuren"}/>
          </div>
        </div>
    </PageTemplate>
  );
}

export default GeneralSettings;