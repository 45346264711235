import React, { useState } from "react";
import PageTemplate from "../../../Templates/PageTemplate";
import middleware from "../../../Api/Middleware";
import { useNavigate } from "react-router";
import Key from "./Icons/key.svg";
import Eye from "./Icons/eye.svg";
import EyeClosed from "./Icons/eye-closed.svg";
import NavbarIcon from '../../../Navbars/Sidebar/Icons/gear.png';
import "./AddUsers.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import Modal from 'react-bootstrap/Modal';
import ToastSuccess from "../../../Toasts/ToastSuccess";


const AddUsers = () => {
  const navigate = useNavigate();
  const [passwordModal, setPasswordModal] = useState(false);
  const [roleModal, setRoleModal] = useState(false); 
  const [saveKeyModal, setSaveKeyModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showSecurityCode, setShowSecurityCode] = useState(false);
const [loading, setLoading] = useState(true); // useState for page loading

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [safetyPin, setSafetyPin] = useState("");
  const [language, setLanguage] = useState("");
  const [role, setRole] = useState();

  const submitNewUser = async (event) => {
    try {
      if (event) {
        event.preventDefault();
      }
      if (firstName, lastName, email, password, safetyPin, language, role) 
      {
        await middleware.post("users", {
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
          security_code: safetyPin,
          language: language,
          role: role
        }).then(() => window.location.reload());      
        
      }
    } catch (err) {
      console.error(err);
    }
  };

  const generateRandomPassword = async event => {
    event.preventDefault();
    const length = 15;
    const charset = "abcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*";
    let password = "";
    
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      let char = charset[randomIndex];
      
      // Randomly convert to uppercase
      if (Math.random() < 0.5 && char.match(/[a-z]/)) {
        char = char.toUpperCase();
      }
      
      password += char;
    }

    setPassword(password);

    try {
      await navigator.clipboard.writeText(password);
      ToastSuccess(`Wachtwoord gekopieerd naar clipboard`, 1500);
      console.log('Password copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy password: ', err);
    }
  }

  const generateRandomSecurityCode = async event => {
    event.preventDefault();
    const length = 6;
    const charset = "0123456789";
    let code = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      let char = charset[randomIndex];      
      code += char;
    }

    setSafetyPin(code);

    try {
      await navigator.clipboard.writeText(code);
      ToastSuccess(`Beveiligingspin gekopieerd naar clipboard`, 1500);
      console.log('Password copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy password: ', err);
    }
  }


  return (
    <>
      <Modal show={passwordModal} onHide={() => setPasswordModal(false)}>
        <Modal.Header closeButton className="bg-white">
          <Modal.Title>Minimale wachtwoordvereisten</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
          <ul>
            <li>6 Tekens</li>
            <li>1 Kleine letter</li>
            <li>1 Hoofdletter</li>
            <li>1 cijfer</li>
          </ul>
        </Modal.Body>
        <Modal.Footer className="bg-white">
          <button className="btn bg-success text-white" onClick={() => setPasswordModal(false)}>
            Oke
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={saveKeyModal} onHide={() => setSaveKeyModal(false)}>
        <Modal.Header closeButton className="bg-white">
          <Modal.Title>Beveiligingscode</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
          <p>
            Beveiligingspin kan worden gebruikt als snelle inlogmethode in plaats van wachtwoord.
          </p>
          <p>
            Je kunt het gebruiken in POS apparaten, de POS App, InStore en InControl.
            Voordat de beveiligingspin kan worden gebruikt in de nieuwe app/het nieuwe apparaat, moet u er één keer op inloggen met uw wachtwoord.
          </p>
        </Modal.Body>
        <Modal.Footer className="bg-white">
          <button className="btn bg-success text-white" onClick={() => setSaveKeyModal(false)}>
            Oke
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={roleModal} onHide={() => setRoleModal(false)}>
        <Modal.Header closeButton className="bg-white">
          <Modal.Title>Gebruikersrollen uitleg</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
          <article>
            <div>
              <h5>Administrator</h5>
              <p>Een Administrator kan overal bij en kan alles zien.</p>
            </div>
            <div>
              <h5>Gebruiker</h5>
              <p>Een Gebruiker kan alles behalve 'Algemene instellingen' zien</p>
            </div>
          </article>
        </Modal.Body>
        <Modal.Footer className="bg-white">
          <button className="btn bg-success text-white" onClick={() => setRoleModal(false)}>
            Oke
          </button>
        </Modal.Footer>
      </Modal>
      <PageTemplate navbarTitle={"Gebruikers bewerken"} pageIsLoading={loading} iconNavbar={NavbarIcon}>
      <form onSubmit={submitNewUser} method="post" className="w-100">
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn bg-white fw-semibold rounded-pill mb-4"
            onClick={() => navigate("/algemene/instellingen/gebruikers")}
          >
            {"< Gebruikers"}
          </button>
          <button className="btn text-white fw-semibold rounded-pill mb-4" style={{backgroundColor: "#79b893"}}>Maak gebruiker aan</button>
        </div>

        <div className="whiteBox parentBoxEditUser">
          <div className="m-3">
            <h3>Systeem gebruiker aanmaken</h3>
            <div className="m-3">
                <div className="d-flex col-12 border-bottom py-4">
                  <h5 className="titlesAddUsers inputTitle col-3 ps-3">Volledige naam</h5>
                  <input className="inputFieldEditUser mx-2 col-4 ps-2" type="text" onChange={(e) => setFirstName(e.target.value)} placeholder="Voornaam" required></input>
                  <input className="inputFieldEditUser mx-3 ms-4 col-4 ps-2" type="text" onChange={(e) => setLastName(e.target.value)} placeholder="Achternaam" required></input>
                </div>
                <div className="d-flex col-12 border-bottom py-4">
                  <h5 className="titlesAddUsers inputTitle col-3 ps-3">E-mail</h5>
                  <div className="col-9 d-flex flex-column">
                    <div className="d-flex flex-row">
                      <input
                        className="inputFieldEditUser ms-2 col-9 ps-2"
                        type="email"
                        required
                        placeholder="Emailadress"
                        onChange={(e) => setEmail(e.target.value)}
                        style={{
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                        }}
                      ></input>
                      <button className="btn btnEditUser col-2" onClick={event => event.preventDefault()}>
                        Gebruik systeememail
                      </button>
                    </div>
                    <small className="ms-4 text-secondary">
                      Het is niet mogelijk om uw wachtwoord te herstellen met
                      systeem-e-mail.
                    </small>
                  </div>
                </div>
                <div className="d-flex col-12 border-bottom py-4">
                  <h5 className="titlesAddUsers inputTitle col-3 ps-3">
                    Wijzig wachtwoord{" "}
                    <FontAwesomeIcon className="iconInfoStyle" onClick={() => setPasswordModal(true)} icon={faCircleInfo} />
                  </h5>
                  <div className="col-9 d-flex">
                    <input
                      className="inputFieldEditUser ps-2 ms-2 col-10"
                      type={showPassword ? 'text' : 'password'}
                      required
                      value={password ?? ''}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Wijzig wachtwoord"
                      style={{
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                      }}
                    ></input>
                    <button className="btn btnPasswordShow" onClick={event => {
                      event.preventDefault();
                      setShowPassword(!showPassword);
                    }}>
                      <img className="h-100" src={showPassword ? Eye : EyeClosed} />
                    </button>
                    <button className="btn btnEditUser" onClick={generateRandomPassword}>
                      <img className="h-100" src={Key} />
                    </button>
                  </div>
                </div>
                <div className="d-flex col-12 border-bottom py-4">
                  <h5 className="titlesAddUsers inputTitle col-3 ps-3">
                    Beveiligingspin instellen {" "}
                    <FontAwesomeIcon className="iconInfoStyle" onClick={() => setSaveKeyModal(true)} icon={faCircleInfo} />
                  </h5>
                  <div className="col-9 d-flex">
                    <input
                      className="inputFieldEditUser ps-2 ms-2 col-10"
                      type={showSecurityCode ? 'number' : 'password'}
                      value={safetyPin ?? ''}
                      placeholder="Beveiligingspin instellen"
                      onChange={(e) => setSafetyPin(e.target.value)}
                      style={{
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                      }}
                      required
                    ></input>
                    <button className="btn btnPasswordShow" onClick={event => {
                      event.preventDefault();
                      setShowSecurityCode(!showSecurityCode);
                    }}>
                      <img className="h-100" src={showSecurityCode ? Eye : EyeClosed} />
                    </button>
                    <button className="btn btnEditUser" onClick={generateRandomSecurityCode}>
                      <img className="h-100" src={Key} />
                    </button>
                  </div>
                </div>
                <div className="d-flex col-12 border-bottom py-4">
                  <h5 className="titlesAddUsers inputTitle mb-0 col-3 ps-3">Taal</h5>
                  <div className="col-9 d-flex flex-column">
                    <div className="d-flex flex-row">
                      <select
                        className="inputFieldEditUser ps-2 ms-2 col-11"
                        onChange={(e) => setLanguage(e.target.value)}
                      >
                      <option hidden>-- kies je taal --</option>
                      <option >NL</option>
                      <option >DE</option>
                      <option >FR</option>
                      <option >IT</option>
                      <option >PO</option>
                      <option >ES</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="d-flex col-12 border-bottom py-4">
                  <h5 className="titlesAddUsers inputTitle mb-0 col-3 ps-3">Rol{" "}<FontAwesomeIcon className="iconInfoStyle" onClick={() => setRoleModal(true)} icon={faCircleInfo} /></h5>
                  <div className="col-9 d-flex flex-column">
                    <div className="d-flex flex-row">
                      <select
                        className="inputFieldEditUser ps-2 ms-2 col-11"
                        onChange={(e) => setRole(e.target.value)}
                        required
                      >
                      <option value="0">Gebruiker</option>
                      <option value="1">Admin</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </PageTemplate>
    </>
  );
};

export default AddUsers;