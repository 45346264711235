import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ModalStyle.css";
import middleware from "../Api/Middleware";

const ConfirmationModal = ({ opened, setOpened, clickYes, message }) => {
    const [shown, setShown] = useState(false);

  useEffect(() => {
    setShown(opened);
  }, [opened]);
  
  return (
    <Modal show={opened} onHide={() => setOpened(false)}>
        <Modal.Header closeButton className="bg-white">
            <Modal.Title>Let op!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
            {message}
        </Modal.Body>
        <Modal.Footer className="bg-white">
          <button className="btn bg-danger text-white" 
            onClick={() => setOpened(false)}>
            Annuleren
          </button>
          <button
            className="btn bg-success text-white"
            onClick={() => {
                clickYes();
                setOpened(false)
            }}
          >
            Ik weet het zeker
          </button>
        </Modal.Footer>
      </Modal>
  );
}

export default ConfirmationModal;
