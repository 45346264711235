// MediaUploaderComponent.js
import React, { forwardRef, useRef } from 'react';
import { useSortable, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import { DndContext, MouseSensor as LibMouseSensor,  KeyboardSensor, TouchSensor} from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
// import warning from '../../Images/path-to-your-warning-image.png';

// Helper function
function shouldHandleEvent(element) {
  let cur = element;
  while (cur) {
    if (cur.dataset && cur.dataset.noDnd) {
      return false;
    }
    cur = cur.parentElement;
  }
  return true;
}

// MouseSensor (assuming you want to use a custom sensor)
class MouseSensor extends LibMouseSensor {
  static activators = [
    {
      eventName: "onMouseDown",
      handler: ({ nativeEvent: event }) => {
        return shouldHandleEvent(event.target);
      },
    },
  ];
}

// Grid component
function Grid({ children, columns }) {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gridGap: 10,
        padding: 10,
      }}>
      {children}
    </div>
  );
}

// SortablePhoto component
const SortablePhoto = (props) => {
  const { id, error, requiredCheck, index, colorId, file, name, handleDelete, handleColorChange, colorOptions } = props;
  const sortable = useSortable({ id });
  const { attributes, listeners, setNodeRef, transform, transition } = sortable;
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  
  return (
    <Photo
      style={style}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      error={error}
      required={requiredCheck}
      index={index}
      colorId={colorId}
      file={file}
      name={name}
      handleDelete={handleDelete}
      handleColorChange={handleColorChange}
      colorOptions={colorOptions}
    />
  );
};

// Photo component
const Photo = forwardRef(({ error, required, index, colorId, file, name, handleDelete, handleColorChange, colorOptions, style, ...props }, ref) => {
  // Functionality for creating an object URL (assuming file is a File object)
  const objectUrl = file ? URL.createObjectURL(file) : null;
  
  // Clear the object URL if the component unmounts
  React.useEffect(() => {
    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [objectUrl]);
  
  return (
    <div ref={ref} {...props} style={style} className="col-12 objectWithinMU">
      {/* ... Photo content ... */}
    </div>
  );
});

const MediaUploaderComponent = ({
  uploading,
  downloading,
  uploadImgStatus,
  uploadCount,
  images,
  downloadCount,
  handleDragEnter,
  handleDragOver,
  handleDrop,
  handleClick,
  handleMouseDown,
  handleMouseUp,
  handleFileUpload,
  handleDelete,
  handleColorChange,
  requiredCheck,
  colors,
  sensors,
  collisionDetection,
  handleDragEnd
}) => {
  const fileInputRef = useRef();

  return (
    <DndContext sensors={sensors} collisionDetection={collisionDetection} onDragEnd={handleDragEnd}>
      <div
        className="mediaUploader"
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        {/* Conditional rendering for uploading/downloading state */}
        {uploading || downloading ? (
          <div className="uploadScreenMU">
            <div className="uploadScreenContainerMU">
              <div className="spinner-border me-2" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              <span className="uploadScreenTextMU">{uploadImgStatus}</span>
              <span className="uploadScreenTextMU uploadScreenSubTextMU">
                (
                {uploading
                  ? `${uploadCount} / ${images.length}`
                  : downloading
                  ? `${uploadCount} / ${downloadCount}`
                  : ""}
                )
              </span>
            </div>
          </div>
        ) : null}

        {/* File input for uploading */}
        <input
          id="fileInput"
          ref={fileInputRef}
          hidden
          type="file"
          onChange={handleFileUpload}
          multiple
        />

        {/* Instructional text */}
        <div className="textForTheMU">
          Sleep of selecteer uw media-bestanden om te uploaden
        </div>

        {/* Sortable context for the grid */}
        <SortableContext items={images} strategy={rectSortingStrategy}>
          <Grid columns={4}>
            {images.map((image) => (
              <SortablePhoto
                key={image.id}
                {...image}
                requiredCheck={requiredCheck}
                handleDelete={handleDelete}
                handleColorChange={handleColorChange}
                colorOptions={colors}
              />
            ))}
          </Grid>
        </SortableContext>
      </div>
    </DndContext>
  );
};

export default MediaUploaderComponent;