import React from "react";
import "../Main/Main.css";
import "./WarehouseSettings.css";
import BoxesWarehouse from "../Main/BoxesWarehouse";
import Size from './Icons/size.png';
import Colors from './Icons/paint-palette.png';
import Brands from './Icons/copyright.svg';
import NavbarIcon from "../Navbars/Sidebar/Icons/distribution-center.png"
import Category from './Icons/categories.svg';
import Label from './Icons/tag.svg';
import PageTemplate from "../Templates/PageTemplate";

function WarehouseSettings() {

  return (
    <PageTemplate iconNavbar={NavbarIcon} navbarTitle={"Warehouse instellingen"}>
      <div className="d-flex justify-content-between flex-column mb-4 ">
        <h3 className="mb-0 text-center mb-4">
          Warehouse instellingen
        </h3>

        <div className=" d-flex justify-content-start w-100 flex-wrap">
          <BoxesWarehouse icon={Size} title={"Blauwdrukken"} link={"./blauwdrukken"}/>
          <BoxesWarehouse icon={Colors} title={"Kleuren"} link={"./kleuren"}/>
          <BoxesWarehouse icon={Brands} title={"Merken"} link={"./merken"}/>
          <BoxesWarehouse icon={Category} title={"Categoriën"} link={"./category_nieuw"}/>
          <BoxesWarehouse icon={Label} title={"Labels"} link={"./labels"}/>
        </div>
      </div>
    </PageTemplate>
  );
}

export default WarehouseSettings;