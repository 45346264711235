import React, { useState, useEffect } from "react";
import middleware from "../Api/Middleware";
import "./Orders.css";
import "../Table/Table.css";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleCheck, faMoneyBill1, faUser } from "@fortawesome/free-regular-svg-icons";
import { faTruck, faChevronUp, faChevronDown, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import NavbarIcon from "../Navbars/Sidebar/Icons/envelope.png"
import productPic from "../WarehouseSettings/Icons/size.png"
import PageTemplate from "../Templates/PageTemplate";
import ToastSuccess from "../Toasts/ToastSuccess";
import ToastError from "../Toasts/ToastError";
import TDSTextNavigation from "../Templates/TDSTextNavigation";
import countries from 'i18n-iso-countries';
countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));

const OrdersEdit = () => {
  const { order_number } = useParams();

  const navigate = useNavigate();

  const [pageIsLoading, setPageIsLoading] = useState(true);

  const [order, setOrder] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [oldOrderNote, setOldOrderNote] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [invoiceAddress, setInvoiceAddress] = useState(null);
  const [shipments, setShipments] = useState([]);
  const [shownShipments, setShownShipments] = useState([]);
  const [productsToProcces, setProductsToProcces] = useState([]);
  const [productsToProccesFullyShown, setProductsToProccesFullyShown] = useState([]);
  const [totalAmountOfProducts, setTotalAmountOfProducts] = useState(0);
  const [taxTypes, setTaxTypes] = useState([]);

  const fetchAll = async () => {
    try {
      setPageIsLoading(true);
      const orderResponse = await middleware.get(`orders?order_number=${order_number}`);
      const order = orderResponse.data[0];
      setOrder(order);
      setOldOrderNote(order.note);

      if (order.internal_id.charAt(0) == "C") {
        const customerResponse = await middleware.get(`consumers?internal_id=${order.internal_id}`);
        setCustomer(...customerResponse.data);
      } else if (order.internal_id.charAt(0) == "B") {
        const businessesResponse = await middleware.get(`businesses?internal_id=${order.internal_id}`);
        setCustomer(...businessesResponse.data);
      }

      const addressResponse = await middleware.get(`addresses?internal_id=${order.internal_id}`);
      if (addressResponse.data.length > 0) {
        const countryCodesResponse = await middleware.get(`countryCodes`);
        addressResponse.data.map((address) => {
          address.country = countryCodesResponse.data.find((countryCode) => countryCode.country_code_id === address.country_code_id).country_code_2;
          address.address_kind === 0 || address.address_kind === 2 ? setDeliveryAddress(address) : setInvoiceAddress(address);
        });
      }
      
      const soldProductsResponse = await middleware.get(`orders/soldproducts?order_id=${order.order_id}`);
      const soldProductsData = soldProductsResponse.data;

      // If there are products that are not proccessed it adds them to the products to proccess. It also calculates how many products where bought in the order
      const addToProcces = [];
      let totalProducts = 0;
      soldProductsData.map(soldProduct => {
        totalProducts += soldProduct.amount;
        if (soldProduct.amount > soldProduct.items_processed) {
          addToProcces.push({
            ...soldProduct,
            amount: soldProduct.amount - soldProduct.items_processed,
          });
        }
      });

      setProductsToProcces(addToProcces);
      setTotalAmountOfProducts(totalProducts);

      // Get all the diffrent tax types
      setTaxTypes(soldProductsData.reduce((acc, soldProduct) => {
        // Find the index of the object with the same taxRate
        const index = acc.findIndex(obj => obj.taxRate === soldProduct.btw_rate);
        
        // Calculate totalPriceExclTax for the current soldProduct
        const totalPriceExclTax = Number(soldProduct.price_excl_tax) * Number(soldProduct.amount);
        const totalTax = (Number(soldProduct.price_incl_tax) - Number(soldProduct.price_excl_tax)) * Number(soldProduct.amount)

        if (index !== -1) {
          // If an object with the same taxRate exists, update the totalPriceExclTax
          acc[index].totalPriceExclTax += totalPriceExclTax;
          acc[index].totalTax += totalTax;
        } else {
          // If not, create a new object and push it to the accumulator array
          acc.push({ taxRate: soldProduct.btw_rate, totalPriceExclTax, totalTax });
        }
        return acc;
      }, []));

      const shipmentsResponse = await middleware.get(`shipments/${order.order_id}`);
      const shipments = shipmentsResponse.data;
      setShipments(shipments);

      const addFullyShownShipments = {};
      shipments.map((_, index) => {
        addFullyShownShipments[index] = true;
      });
      setShownShipments(addFullyShownShipments);

      setPageIsLoading(false); 
    } catch (error) {
      console.error(error);
      // removeLoginCookies(navigate);
    }
  }

  useEffect(() => {
    fetchAll();
  }, []);

  const getCustomerLink = _ => {
    return customer.consumer_id ? (
      `/klanten/bewerken/${customer.consumer_id}`
    ) : (
      `/klanten/bewerken/${customer.company_id}`
    );
  }

  const handleOrderOnChange = event => {
    setOrder({...order, [event.target.name]: event.target.value});
  }

  const handleSaveNote = async event => {
    event.preventDefault();

    if (order.note !== oldOrderNote) {
      try {
        const res = await middleware.put(`orders`, {
          order_id: order.order_id,
          note: order.note,
        });
        
        setOrder(res.data);
        setOldOrderNote(res.data.note);
        ToastSuccess(`Notitie is successvol opgeslagen`);
      } catch (error) {
        ToastError(`Er ging iets fout tijdens de notitie opslaan`);
      }
    }
  }

  const calculateSalePrice = soldProduct => {
    switch (soldProduct.sale_unit) {
      case 0: // This means that the sale was in percentage
        return Number(soldProduct.price_incl_tax) * Number(soldProduct.amount) / 100 * Number(soldProduct.sale_amount);
      case 1: // This means that the sale was in euro's
        return Number(soldProduct.sale_amount) * Number(soldProduct.amount);
    }
  }

  const showPrice = price => {
    return `€ ${Number(price).toFixed(2).replace('.', ',').replace(',00', ',-')}`;
  }

  const showSalePrice = soldProduct => {
    const salePrice = calculateSalePrice(soldProduct);

    return showPrice(Number(soldProduct.price_incl_tax) - salePrice / soldProduct.amount);
  }

  const handleShipmentDropdown = async (event, shipmentId) => {
    try {
      switch (event.target.value) {
        case '1':
          await middleware.delete(`shipments/${shipmentId}`);
          fetchAll();
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <PageTemplate navbarTitle={`Bestelling bewerken`} pageIsLoading={pageIsLoading} iconNavbar={NavbarIcon}>
      <div className="OrderEdit mx-3 w-100">
        <div>
          <div className="d-flex flex-row align-items-center mb-3">
            <button
              className="buttonStyle me-3"
              onClick={(e) => navigate("/bestellingen")}
            >
              <FontAwesomeIcon icon={faCaretLeft} /> Alle Bestellingen
            </button>
            <h1 className="mb-0">{`#${order.order_number}`}</h1>
          </div>
          <div className="d-flex justify-content-between gap-5 parentBoxes flex-column w-100">
            <div className="ordersEditContainer">
              {/* Left side container box */}
              <div className="ordersEditLeftSide d-flex flex-column">
                <div className="bg-white rounded-4 p-4 mb-3 overflow-x-scroll h-50">
                  <div className="orderEditScroll">
                    <div className="mb-3">
                      <h3>
                        <FontAwesomeIcon icon={productsToProcces.length > 0 ? faCircle : faCircleCheck} className={productsToProcces.length > 0 ? 'text-warning' : 'text-success'} />{' '}
                        {productsToProcces.length > 0 ? 'Nog te verwerken' : 'Alles is verwerkt'}
                      </h3>
                    </div>

                    {productsToProcces.length !== 0 ? (
                      <div>
                        <table className="w-100 border-0">
                          <thead>
                            <tr>
                              <th>Aantal</th>
                              <th className="text-center">Foto</th>
                              <th className="w-75">Productnaam</th>
                              <th>Prijs p.s.</th>
                              <th>Totaal</th>
                            </tr>
                          </thead>
                          <tbody>
                            {productsToProcces.map((productToProcces, index) => (
                              <tr key={index}>
                                <td className="text-center">{productToProcces.amount}</td>
                                <td className="text-center"><img src={productPic} className="p-2" height={69} width={69} alt="placeholder..."></img></td>
                                <td className="w-75">
                                  {productToProcces.product_name.length > 100 ? (
                                    productsToProccesFullyShown[index] === undefined ? (
                                      <>
                                        <span>
                                          {productToProcces.product_name.slice(0, 90)}
                                        </span>
                                        <span
                                          className="text-info hover text-nowrap ms-2"
                                          onClick={() => setProductsToProccesFullyShown([...productsToProccesFullyShown, index])}>
                                          (...)
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span>
                                          {productToProcces.product_name}
                                        </span>
                                        <span
                                          className="text-info hover text-nowrap ms-2"
                                          onClick={() => setProductsToProccesFullyShown(productsToProccesFullyShown.filter(i => i !== index))}>
                                          Toon minder
                                        </span>
                                      </>
                                    )
                                  ) : (
                                    productToProcces.product_name
                                  )}
                                </td>
                                <td className="text-nowrap">
                                  {productToProcces.sale_amount > 0 ? (
                                    <div>
                                      <span className="text-decoration-line-through text-secondary">{showPrice(productToProcces.price_incl_tax)}</span> <br/>
                                      <span>{showSalePrice(productToProcces)}</span>
                                    </div>
                                  ) : (
                                    <span>{showPrice(productToProcces.price_incl_tax)}</span>
                                  )}
                                </td>
                                <td className="text-nowrap">{showPrice(productToProcces.price_incl_tax * productToProcces.amount - calculateSalePrice(productToProcces))}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <div className="d-flex justify-content-end">
                          <button className="buttonStyle bg-light" onClick={() => navigate(`/bestellingen/verwerken/${order_number}`)}>
                            <FontAwesomeIcon icon={faTruck} />{' '}
                            Verwerken
                          </button>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>

                <div className="bg-white rounded-4 p-4 mb-3 h-50">
                  <div className="mb-3">
                    <h3>
                      <FontAwesomeIcon icon={faMoneyBill1} className="text-success" />{' '}
                      Betaling
                    </h3>
                  </div>

                  <div>
                    <table className="ordersEditShowPriceTable">
                      <thead>
                        <tr>
                          <th className="pe-2">Omschrijving</th>
                          <th className="pe-2">Incl. BTW</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>{totalAmountOfProducts} {totalAmountOfProducts > 1 ? 'Producten' : 'Product'}</td>
                          <td>{showPrice(order.total_price_incl_tax)}</td>
                        </tr>
                        <tr>
                          <td>Subtotaal</td>
                          <td>{showPrice(order.total_price_incl_tax)}</td>
                        </tr>
                        <tr>
                          <td className="pe-2">Korting</td>
                          <td>{showPrice(order.sale_amount)}</td>
                        </tr>
                        <tr>
                          <td className="pe-2">Loyaliteitspunten</td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <td>Totaal</td>
                          <td>{showPrice(order.total_price_incl_tax - order.sale_amount)}</td>
                        </tr>
                      </tbody>
                    </table>

                    <table className="ordersEditShowTaxTable">
                      <thead>
                        <tr>
                          <th className="pe-2">Omschrijving</th>
                          <th className="pe-2">Excl. BTW</th>
                          <th className="pe-2">BTW</th>
                        </tr>
                      </thead>
                      
                      <tbody>
                        {taxTypes.map((taxType, index) => (
                          <tr key={index}>
                            <th>BTW {taxType.taxRate}%</th>
                            <td className="text-nowrap">{showPrice(taxType.totalPriceExclTax)}</td>
                            <td className="text-nowrap">{showPrice(taxType.totalTax)}</td>
                          </tr>
                        ))}
                        <tr>
                          <th>Korting</th>
                          <td>{showPrice(order.sale_amount)}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <th>Totaal</th>
                          <td>{showPrice(order.total_price_excl_tax - order.sale_amount)}</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                
                {shipments.map((shipment, index) => (
                  <div key={index} className="bg-white rounded-4 p-4 mb-3">
                    <div className="mb-3 d-flex justify-content-between">
                      <h3>
                        <FontAwesomeIcon icon={faTruck} className="text-info" />{' '}
                        Verzending #{shipment.shipment_number}
                      </h3>
                      <h3>
                        <FontAwesomeIcon
                          icon={shownShipments[index] ? faChevronUp : faChevronDown}
                          className="hover"
                          onClick={() => setShownShipments({...shownShipments, [index]: !shownShipments[index]})}
                        />
                      </h3>
                    </div>

                    {shownShipments[index] ? (
                      <div>
                        <table className="w-100 border-0">
                          <thead>
                            <tr>
                              <th>Aantal</th>
                              <th className="text-center">Foto</th>
                              <th className="w-75">Productnaam</th>
                              <th>Prijs p.s.</th>
                              <th>Totaal</th>
                            </tr>
                          </thead>
                          <tbody>
                            {shipment.soldProducts.map((soldProduct, index) => (
                              <tr key={index}>
                                <td className="text-center">{soldProduct.amount}</td>
                                <td className="text-center"><img src={productPic} className="p-2" height={69} width={69} alt="placeholder..."></img></td>
                                <td className="w-75">
                                  {soldProduct.product_name.length > 100 ? (
                                    productsToProccesFullyShown[index] === undefined ? (
                                      <>
                                        <span>
                                          {soldProduct.product_name.slice(0, 90)}
                                        </span>
                                        <span
                                          className="text-info hover text-nowrap ms-2"
                                          onClick={() => setProductsToProccesFullyShown([...productsToProccesFullyShown, index])}>
                                          (...)
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span>
                                          {soldProduct.product_name}
                                        </span>
                                        <span
                                          className="text-info hover text-nowrap ms-2"
                                          onClick={() => setProductsToProccesFullyShown(productsToProccesFullyShown.filter(i => i !== index))}>
                                          Toon minder
                                        </span>
                                      </>
                                    )
                                  ) : (
                                    soldProduct.product_name
                                  )}
                                </td>
                                <td className="text-nowrap">
                                  {soldProduct.sale_amount > 0 ? (
                                    <div>
                                      <span className="text-decoration-line-through text-secondary">{showPrice(soldProduct.price_incl_tax)}</span> <br/>
                                      <span>{showSalePrice(soldProduct)}</span>
                                    </div>
                                  ) : (
                                    <span>{showPrice(soldProduct.price_incl_tax)}</span>
                                  )}
                                </td>
                                <td className="text-nowrap">{showPrice(soldProduct.price_incl_tax * soldProduct.amount - calculateSalePrice(soldProduct))}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        <div className="d-flex justify-content-end">
                          <select defaultValue={''} onChange={event => handleShipmentDropdown(event, shipment.shipment_id)}>
                            <option value=''>Meer</option>
                            <option value='1'>Verwijderen</option>
                          </select>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                ))}
              </div>

              {/* Right side container box */}
              <div className="ordersEditRightSide">
                <div className="bg-white rounded-4 p-4 mb-3">
                  <div className="mb-3">
                    <h3>
                      <FontAwesomeIcon icon={faUser} className="text-info" />{' '}
                      Klant
                    </h3>
                  </div>

                  <div className="mb-3">
                    <TDSTextNavigation
                      text={customer.company_name ?? `${customer.first_name ?? ""} ${customer.last_name ?? ""}`}
                      link={getCustomerLink()}
                    /> <br />
                    <span>E-mail: {customer.email}</span> <br />
                    <span>Telefoon: {customer.phone_number}</span> <br />
                  </div>

                  <div className="mb-3">
                    <span className="fw-bold">Verzendadres</span> <br />
                    {deliveryAddress ? (
                      <>
                        <span>{deliveryAddress.streetname} {deliveryAddress.housenumber}{deliveryAddress.addition}</span> <br/>
                        <span>{deliveryAddress.living_area} {deliveryAddress.postalcode} {countries.getName(deliveryAddress.country, "nl")}</span>
                      </>
                    ) : (
                      <span>Deze klant heeft nog geen verzendadres</span>
                    )}
                  </div>

                  <div>
                    <span className="fw-bold">Factuuradres</span> <br />
                    {invoiceAddress ? (
                      <>
                        <span>{invoiceAddress.streetname} {invoiceAddress.housenumber}{invoiceAddress.addition}</span> <br/>
                        <span>{invoiceAddress.living_area} {invoiceAddress.postalcode} {countries.getName(invoiceAddress.country, "nl")}</span>
                      </>
                    ) : (
                      <span>Deze klant heeft nog geen Factuuradres</span>
                    )}
                  </div>
                </div>

                <div className="bg-white rounded-4 p-4 mb-3">
                  <div className="mb-3">
                    <h3>Referentie</h3>
                  </div>
                </div>

                <div className="bg-white rounded-4 p-4 mb-3">
                  <div className="mb-3">
                    <h3>Email sturen</h3>
                  </div>
                </div>

                <div className="bg-white rounded-4 p-4 mb-3">
                  <div className="mb-3">
                    <h3>Notitie</h3>
                  </div>

                  <div>
                    <textarea
                      className="form-control"
                      value={order.note ?? ''}
                      name="note"
                      rows={5}
                      onChange={handleOrderOnChange}
                      onBlur={handleSaveNote}
                      placeholder="Maak hier uw notities"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default OrdersEdit;