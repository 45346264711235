import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router';
import middleware from '../../../Api/Middleware';
import { Modal } from 'react-bootstrap';
import VariationModal from '../sub_components/VariationModals'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPenToSquare, faGear } from '@fortawesome/free-solid-svg-icons'
import '../css/AddProductPages.css'
import '../../../Main/Main.css'
import "../../product.css"
import ToastError from '../../../Toasts/ToastError';
import ToastSuccess from '../../../Toasts/ToastSuccess';
import NavbarIcon from "../../../Navbars/Sidebar/Icons/globe.png"
import Backbutton from "../images/BackIcon.svg"
// Media
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import CategoryList from '../sub_components/CategoryList';
import PageTemplate from '../../../Templates/PageTemplate';
import CategoryModal from '../../../Modals/CategoryModal';

import MediaUploaderComponent from '../../../MediaUpload.js/MediaUploadComponent';
import TextEditor from '../../../Main/TextEditor';

const AddSimple = () => {
  const [pagination, setPagination] = useState({
    "page_size": 10,
    "page": 1,
    "max_items": 0,
    "begin_of_page": 0,
    "end_of_page": 0,
  });
  const [producten, setProducten] = useState([]);
  const [brands, setBrands] = useState([]);
  const [genders, setGenders] = useState([]);
  const [categories, setCategories] = useState([]);
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [edit, setEdit] = useState(false);
  const [generatedProductNum, setGeneratedProductNum] = useState();
  const [language, setLanguage] = useState('nl');
  const [colorVariations, setColorVariations] = useState([]);  
  const [showBlueprintModal, setShowBlueprintModal] = useState(false);
  const [buttonPressed, SetbuttonPressed] = useState('');  
  const [merkName, setMerkName] = useState("");
  const [brandId, setBrandId] = useState({});
  const [description, setdescription] = useState("");
  const [keywords, setKeywords] = useState("");
  const [titleCategory, setTitleCategory] = useState("");
  const [descriptionCategory, setDescriptionCategory] = useState("");
  const [editorContent, setEditorContent] = useState('');
  const [sellingPrice, setSellingPrice] = useState(0);
  const [salePrice, setSalePrice] = useState(0);
  const [selectedOption, setSelectedOption] = useState('2');
  const [barcodeValue, setBarcodeValue] = useState('');
  const [userTypedValue, setUserTypedValue] = useState('');
  const [productNumberValue, setProductNumberValue] = useState('');
  const [btw, setBtw] = useState('0');
  const [taxClass, setTaxClass] = useState('zero');
  const [show, setShow] = useState(false);
  const [showBrand, setShowBrand] = useState(false);
  const [subCategory, setSubCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [values, setValues] = useState({
    product_number: generatedProductNum,
  });
  // State for holding categories.
  const [initialCategories, setInitialCategories] = useState([
    {id: 1, headId: null, name: 'Categories'}
  ]);

  const [initialCategoriesNew, setInitialCategoriesNew] = useState([
    {id: 1, headId: null, name: 'Categories'}
  ]);

  // A helper function that recursively organizes the products based on their headId.
  const organizeCategory = (categories, parentId = null) => {
    // Ensure the input is an array
    return categories
        .filter(category => category.headId === parentId)
        .map(category => {
            return {
                ...category,
                subcategories: organizeCategory(categories, category.id)
            }
        });
};

  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryNew, setSearchQueryNew] = useState('');
  const [checkedCategories, setCheckedCategories] = useState({});
  const [checkedCategory, setCheckedCategory] = useState({});
  const [checkedCategoryId, setCheckedCategoryId] = useState({});
  const [categoryTree, setCategoryTree] = useState(organizeCategory(initialCategories));
  const [categoryTreeNew, setCategoryTreeNew] = useState(organizeCategory(initialCategoriesNew));
  const [filteredCategory, setFilteredCategory] = useState(categoryTree);
  const [filteredCategoryNew, setFilteredCategoryNew] = useState(categoryTreeNew);
  const [showCategory, setShowCategory] = useState(false);
  const [prices, setPrices] = useState({
    price: { inclBTW: '', exclBTW: '' },
    sale_Price: { inclBTW: '', exclBTW: '' },
    buy_in_Price: { inclBTW: '', exclBTW: '' },
  });

  const fetchAll = async () => {
    setIsLoading(true);
    const [fetchProducts, fetchBrands, fetchCategories, fetchGenders] = await Promise.all([
      middleware.get(`products?page=${pagination.page}&page_size=${pagination.page_size}`),
      middleware.get(`products/brands`),
      middleware.get(`products/category`),
      middleware.get(`products/genders`),
    ]);
    const cat = fetchCategories.data.map(map => {
      return {
        primary_key: map.primary_key,
        id: map.category_id,
        headId: map.head_category_id,
        name: map.title,
      };
    });
    setInitialCategories(cat)
    setInitialCategoriesNew(cat)
    // setProducten(fetchProducts.data.data);
    setBrands(fetchBrands.data);
    setCategories(fetchCategories.data)
    setGenders(fetchGenders.data);
    setPagination({...pagination, "max_items": fetchProducts.data.total, "begin_of_page": fetchProducts.data.from, "end_of_page": fetchProducts.data.to,}) // sets the pagination usestate with the max items begin of the page and the end of the page
    setLoadingScreenFunc();
  };

  // useEffect to update the product tree whenever "productsTree" changes.
  useEffect(() => {
    setFilteredCategory(categoryTree);
    setFilteredCategoryNew(categoryTreeNew);
  }, [categoryTree, categoryTreeNew]);

  useEffect(() => {
    filterCategory(searchQuery);
    filterCategoryNew(searchQueryNew);
  }, [searchQuery, searchQueryNew]);

  useEffect(() => {
    fetchAll();
  }, [pagination.page_size, pagination.page]);

  useEffect(() => {
    const colors = localStorage.getItem('colorsAddVariation');
    if (!colors) {
 
    } else {
      setColorVariations(JSON.parse(colors));
    }
  }, [showBlueprintModal]);

  useEffect(() => { // checks if the user is logged in
    (async () => {
      try {
        await middleware(`users?searchTerm=checkifuserisloggedin`);
        // await getImages();
        setLoadingScreenFunc();
      } catch (error) {
        console.warn(error);
        if (error.response.status === 401) {
          cookies.remove('token');
          navigate('/');
        }
      }
    })()
  }, []);

  useEffect(() => {
    (async () => {
      let newBarcodeValue = '';
  
      if (selectedOption === '0') {
        newBarcodeValue = userTypedValue;
      } else if (selectedOption === '2') {
        newBarcodeValue = await handleGenerateBarcode();
      } else if (selectedOption === '1') {
        const timeoutId = setTimeout(() => {
          newBarcodeValue = productNumberValue;
          setBarcodeValue(newBarcodeValue);
        }, 500);
  
        // Cleanup the timeout if the component unmounts or the selected option changes
        return () => clearTimeout(timeoutId);
      }
  
      setBarcodeValue(newBarcodeValue);
    })();
  }, [selectedOption, userTypedValue]);

  useEffect(() => {
    setIsLoading(true);
  
    const fetchData = async () => { // Define an async function within useEffect.
  
      // The following line of code waits for the middleware.get('producten') promise to be resolved.
      // Only after it is resolved, the execution moves to the next line.
      const [response] = await Promise.all([
        middleware.get('products')
      ]);
  
      const producten = response.data;
  
      // This if statement is executed only after the middleware.get('producten') promise is resolved,
      // i.e., only after the 'producten' data has been fetched.
      if (producten.length === 0 ) {
        setGeneratedProductNum(0);
      } else {
        const lastRow = producten.length - 1;
        setGeneratedProductNum(Number(producten[lastRow].product_number) + 1);
        setValues({
          product_number: `${Number(producten[lastRow].product_number) + 1}`,
        })
        setProductNumberValue(generatedProductNum)
      }
  
      setIsLoading(false); // Set loading to false after everything is done.
    }
  
    fetchData(); // This is the call to the async function.
  }, []);

  // useEffect to filter products when "initialCategories" changes.
  useEffect(() => {
    filterCategory();
  }, [initialCategories])

  useEffect(() => {
    filterCategoryNew();
  }, [initialCategoriesNew])

   // Effect to recalculate prices when BTW rate changes
   useEffect(() => {
    // Function defined inside useEffect to avoid dependencies on external functions
    const recalculatePrices = () => {
      setPrices((currentPrices) => {
        const updatedPrices = {};

        for (const [key, value] of Object.entries(currentPrices)) {
          const inclBTW = value.inclBTW;
          const exclBTW = inclBTW ? (inclBTW / (1 + btw / 100)).toFixed(3) : '';
          
          updatedPrices[key] = { ...value, exclBTW };
        }

        return updatedPrices;
      });
    };

    recalculatePrices();
  }, [btw]);

  let editorInstance = null;  // define a variable to hold the editor instance

  const handleCloseBrand = () => setShowBrand(false);
  const closeModalCategory = () => setShowCategory(false)

  const handlePriceChange = (e, priceType) => {
    const inclBTW = e.target.value;
    const exclBTW = inclBTW ? (inclBTW / (1 + btw / 100)).toFixed(3) : '';

    setPrices(prevPrices => ({
      ...prevPrices,
      [priceType]: { inclBTW, exclBTW },
    }));
  };

  // const verwerkCategorieIds = (obj) => {
  //   const regex = /^(\d+)-(\d+)-(\d+)-(\d+)$/;
  //   const resultaten = Object.entries(obj)
  //     .filter(([key, value]) => value === true && regex.test(key))
  //     .map(([key]) => {
  //       const match = key.match(regex);
  //       return match ? match[3] : null; // Het derde deel is de categorie-ID
  //     })
  //     .filter(id => id !== null) // Filter null waarden uit, voor het geval dat
  //     .join(','); // Voeg de nummers samen tot één string
  
  //   return resultaten;
  // };

  const verwerkCategorieIds = (obj) => {
    const regex = /^(\d+)-(\d+)-(\d+)-(\d+)$/;
    const categoryIds = [];
    const HeadIds = [];
  
    Object.entries(obj)
      .filter(([key, value]) => value === true && regex.test(key))
      .forEach(([key]) => {
        const match = key.match(regex);
        if (match) {
          categoryIds.push(match[3]); // Voeg het derde deel toe aan match3
          HeadIds.push(match[4]); // Voeg het vierde deel toe aan match4
        }
      });
  
    return {
      categoryIds,
      HeadIds,
    };
  };
  
  // const mapIdsToNames = (ids, categories) => {
  //   return ids.map(id => {
  //     const category = categories.find(cat => cat.category_id === parseInt(id));
  //     return category ? category.title : id;
  //   });
  // };

  const transformData = (data) => {
    let headCategoryMap = {};

    data.HeadIds.forEach(headId => {
      const headCategory = categories.find(cat => cat.category_id === parseInt(headId));
      if (headCategory) {
        headCategoryMap[headCategory.title] = data.categoryIds
          .map(catId => {
            const category = categories.find(cat => cat.category_id === parseInt(catId));
            return category && category.head_category_id === headCategory.category_id ? category.title : null;
          })
          .filter(title => title !== null);
      }
    });

    return headCategoryMap;
  };


  const transformedData = transformData(verwerkCategorieIds(checkedCategories));

  // A recursive function to filter products based on a search query.
  const filterCategoryRecursively = (query, categories) => {
    const lowerCaseQuery = query.toLowerCase();
  
    return categories
      .map(category => {
        let categoryMatches = category.name.toLowerCase().includes(lowerCaseQuery);
        let filteredSubcategory = [];
        let includedDueToChild = false;
  
        if (category.subcategory) {
          filteredSubcategory = filterCategoryRecursively(query, category.subcategory);
          includedDueToChild = filteredSubcategory.length > 0;
        }
  
        if (categoryMatches || includedDueToChild) {
          return {
            ...category,
            subcategory: filteredSubcategory,
            isIncludedDueToChild: !categoryMatches && includedDueToChild
          };
        }
  
        return null;
      })
      .filter(Boolean);
  };
  

  const filterCategoryRecursivelyNew = (query, categories) => {
    const lowerCaseQuery = query.toLowerCase();
  
    return categories
      .map(category => {
        let categoryMatches = category.name.toLowerCase().includes(lowerCaseQuery);
        let filteredSubcategory = [];
        let includedDueToChild = false;
        
        if (category.subcategory) {
          filteredSubcategory = filterCategoryRecursivelyNew(query, category.subcategory);
          includedDueToChild = filteredSubcategory.length > 0;
        }
  
        if (categoryMatches || includedDueToChild) {
          return {
            ...category,
            subcategory: filteredSubcategory,
            isIncludedDueToChild: !categoryMatches && includedDueToChild
          };
        }
  
        return null;
      })
      .filter(Boolean);
  };

  // get only the number of the id and not the whole id
  const extractNumericId = (complexId) => {
    const regex = /(\d+)-(\d+)-(\d+)-(\d+)$/;
    const match = complexId.match(regex);
    if (!match) {
      return null;
    }
    return {primary_key: match[1], category_id: match[2]};
  };

  const toggleSimple = (id) => {
      setCheckedCategories(prev => {
        const newCheckedCategory = { ...prev };
        const parts = id.split('-');
  
        // Here we are toggling the check for this particular ID
        newCheckedCategory[id] = !newCheckedCategory[id];
  
        // The loop checks parent IDs and sets them to true
        for (let i = 1; i < parts.length; i++) {
          const parentId = parts.slice(0, i).join('-');
          newCheckedCategory[parentId] = true;
        }
        return newCheckedCategory;
      });
  };

  // A function to filter products.
  const filterCategory = (query) => {
    if (!query) {
      setCategoryTree(organizeCategory(initialCategories));
      return;
    }

    setCategoryTree(filterCategoryRecursively(query, initialCategories));
  };

  const filterCategoryNew = (query) => {
    if (!query) {
      setCategoryTreeNew(organizeCategory(initialCategories));
      return;
    }

    setCategoryTreeNew(filterCategoryRecursivelyNew(query, initialCategoriesNew));
  };

  function handleKeyUp() {
      if (editorInstance) {
          const text = editorInstance.getContent({ format: 'text' });
  
          if (text.length > 20000) {
              // cut the extra text
              editorInstance.setContent(text.substring(0, 20000));
          }
      }
  }

  const handleChange = event => {
    setValues({...values, [event.target.name]: event.target.value});
  }

  function setLoadingScreenFunc() {
    setIsLoading(false);
    setTimeout(() => {
      setLoadingScreen(false);
    }, 500);
  }

  const submitNew = async (event) => {
    event.preventDefault(); // Voorkom de standaardactie van het formulier

    let newProductId = null;
    try {
      // Veronderstel dat we eerst controleren of het productnummer uniek is
      const existingProduct = (await middleware.get(`products?product_number=${encodeURIComponent(event.target.product_number.value)}`)).data;
      if (existingProduct.length > 0) {
        ToastError("Productnummer bestaat al.");
        return;
      }
      // Product aanmaken
      const productResponse = await middleware.post("products", {
        product_name: event.target.product_name.value,
        product_number: event.target.product_number.value,
        product_description: editorContent,
        status: 0,
        tax_class: taxClass,
        type: 0,
      });
      newProductId = productResponse.data.product_id;
  
      // Attributen toevoegen
      await middleware.post("products/attributes/connection", {
        product_id: newProductId,
        // category_ids: verwerkCategorieIds(checkedCategories),
        gender_ids: selectedType.join(','),
        brand_id: brandId,
      });


  
      // Prijzen toevoegen
      for (const [priceType, priceValues] of Object.entries(prices)) {
        const { inclBTW, exclBTW } = priceValues;
        if (inclBTW && exclBTW) {
          await middleware.post("products/prices", {
            product_id: newProductId,
            type: priceType,
            incl_tax: inclBTW,
            excl_tax: exclBTW,
          });
        }
      }
  
      ToastSuccess("Product is aangemaakt");
      navigate('/producten');
  
    } catch (err) {
      console.error(err);
      // Rollback alle succesvolle operaties
      if (newProductId) {
        await rollbackActions(newProductId);
      }
      ToastError("Er is een fout opgetreden bij het aanmaken van het product.");
    }
  };
  
  async function rollbackActions(productId) {
    try {
      // Verwijder prijzen die aan het product zijn toegevoegd
      await middleware.delete(`products/prices/${productId}`);
      // Verwijder attributen die aan het product zijn toegevoegd
      await middleware.delete(`products/attributes/${productId}`);
      // Verwijder het product
      await middleware.delete(`products/${productId}`);
    } catch (deleteErr) {
      console.error("Fout tijdens rollback acties: ", deleteErr);
    }
  }
  

  const submitNewBrand = async (event) => {
    try {
      if (event) {
        event.preventDefault();
      }
      if (merkName) 
      {
        const res = await middleware.post("products/brands", {
          name: merkName,
          description: description,
          keywords: keywords,
        });      
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const submitNewCategorie = async (event) => {
    try {
      if (event) {
        event.preventDefault();
      }
      if (titleCategory) 
      {
        if (subCategory == true && selectedCategory) {
          const res = await middleware.post("products/category", {
            title: titleCategory,
            description: descriptionCategory,
            head_category_id: checkedCategoryId,
            status: 0,
          });  
        } else {
          const res = await middleware.post("products/category", {
            title: titleCategory,
            description: descriptionCategory,
            status: 0,
          });  
        }
        
          window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      // Optionally, trigger any specific button or action you want here
    }
  };
  

  const handleGenerateBarcode = async () => {
    // Generate a 13-digit barcode
    try {
      const res = await middleware.get('products/generateBarcode');
      const barcode = res.data.generatedBarcode;
      return barcode;
    } catch (error) {
      console.log(error);
    }
  };

  const handleProductNumberChange = (event) => {
    setGeneratedProductNum(event.target.value);
    setEdit(false)
    let toastShown = false; // Flag variable to track whether toast has been shown

    producten.forEach((element) => {
      const productNumber = element.product_number;

      if (productNumber === event.target.value && !toastShown) {
        // Product number is already used
        toastShown = true; // Set the flag to indicate that the toast has been shown
        ToastError(`Het productnummer bestaat al`);
        // Perform additional actions if needed
      }
    });
  };

  const handleCheckboxChange = (event) => {
    const valueAsInt = parseInt(event.target.value, 10); // Zet de waarde om naar een integer
  
    if (event.target.checked) {
      setSelectedType([...selectedType, valueAsInt]); // Voeg de integer waarde toe
    } else {
      setSelectedType(selectedType.filter(e => e !== valueAsInt)); // Verwijder de integer waarde
    }
  };
  

    const handleBtwChange = (event) => {
    setBtw(event.target.value)

    if (event.target.value == 9) {
      setTaxClass("reduced")
    }
    else if (event.target.value == 21) {
      setTaxClass("high")
    }
    else {
      setTaxClass("zero")
    }
  }

  return (
    <PageTemplate navbarTitle="Warehouse HUB" pageIsLoading={isLoading} iconNavbar={NavbarIcon}>
      <CategoryModal openCategory={showCategory} closeCategory={closeModalCategory} />
      <VariationModal show={showBlueprintModal} handleClose={() => setShowBlueprintModal(false)}/>
      <Modal
        id="modalBlueprint"
        show={showBrand}
        onHide={handleCloseBrand}
        
        size="md"
        backdrop="static"
      >
        <Modal.Header className="modalTop justify-content-between">
          <h4 className='ms-3'>Voeg een merk toe</h4>
          <button variant="transparent" className=' btn' onClick={handleCloseBrand}>
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <Modal.Body className="modalCenter">
          <div className="contendModal w-100">
            <h6 className="inputTitle">Merk naam : </h6>
            <input
              className="inputField borderInputs mb-2 w-100"
              type="text"
              name="merkName"
              onBlur={(e) => setMerkName(e.target.value)}
            ></input>
            <h6 className="inputTitle">Beschrijving : </h6>
            <textarea
              className="borderInputs rounded-2 mb-2 w-100"
              type="text"
              name="merkDescription"
              onBlur={(e) => setdescription(e.target.value)}
            ></textarea>
            <h6 className="inputTitle">Trefwoorden : </h6>
            <textarea
              className="borderInputs rounded-2 w-100"
              type="text"
              name="merkKeywords"
              onBlur={(e) => setKeywords(e.target.value)}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer className="modalBottom">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => setShowBrand(false)}
          >
            Annuleren
          </button>
          <button
            type="sumbit"
            className="btn btn-success"
            onClick={() => {

              submitNewBrand();
              setShowBrand(false)

            }}
          >
            Voeg toe
          </button>
        </Modal.Footer>
      </Modal>
      <form onSubmit={submitNew} method="post" className='d-flex justify-content-center minFitContent w-100'>
        <div className="mb-3 d-flex flex-column formItems  minFitContent w-100">
          <div className="d-flex justify-content-between mb-4 w-100 flex-wrap-reverse">
            <div className='d-flex flex-row align-items-center' onClick={() => navigate("/producten")}>
               <img style={{width: "25px"}} className='me-3' src={Backbutton}/>
               <p className='mb-0 fw-semibold'>Alle producten</p>
            </div>
            <div className=' fitContent text-nowrap'>
              <button
                type="submit"
                id='1'
                className="btn darkBlueStandardButton me-3 Nunito"
                onClick={(e) => SetbuttonPressed(e.target.id)}
              >
                Opslaan & nieuw aanmaken
              </button>
              <button
                type="submit"
                id='2'
                className="btn lightBlueStandardButton Nunito"
                onClick={(e) => SetbuttonPressed(e.target.id)}
              >
                Opslaan
              </button>
            </div>
          </div>
          <div className='d-flex flex-row justify-content-between'>
            <div style={{width: "75%"}}>
              <div>
                <div className='d-flex flex-column fitcontentHeight' >
                  <div className="d-flex flex-column whiteBox p-4 mb-4 minFitContent">
                    <div className="d-flex flex-row parentBoxSellChannelProduct">
                      <div className='w-100'>
                        <div className="d-flex flex-column mb-3">
                          <label className="inputTitle mt-3 mb-2">
                            Productnaam
                          </label>
                          <input
                            className="inputField col-12 standardInput"
                            type="text"
                            name="product_name"
                            value={values.product_name ?? ''}
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                            required
                          ></input>
                        </div>
                        <div className="d-flex flex-row productNumSelectLists col-12 justify-content-between">
                          <div className="d-flex flex-column" style={{width: "32%"}}>
                            <div className='d-flex flex-nowrap'>
                              <label className="inputTitle me-2">
                                Productnummer
                              </label>
                              {/* <FontAwesomeIcon
                                icon={faPenToSquare}
                                onClick={() => setEdit(!edit)}
                                color="#6a6a6a"
                                className="hover"
                              /> */}
                            </div>
                            <input
                              type="number"
                              className="inputField px-2 standardInput webkitRemove"
                              name="product_number"
                              defaultValue={generatedProductNum}
                              onBlur={(e) => { 
                                handleProductNumberChange(e);
                                handleChange(e);
                              }}
                              disabled={!edit}
                              required
                            />
                          </div>
                          <div className="d-flex flex-column" style={{width: "32%"}}>
                            <div className="d-flex flex-row justify-content-between mb-2">
                              <label className="inputTitle me-2 mb-0">Barcode</label>
                              <select
                                className="optionsList productOptions"
                                id="sel1"
                                name="sellist1"
                                value={selectedOption}
                                onChange={e => setSelectedOption(e.target.value)}
                              >
                                <option value="0">Aangepaste barcode</option>
                                <option value="1">Gelijk aan productnummer</option>
                                <option value="2">Automatisch gegenereerd</option>
                              </select>
                            </div>
                            <div className="d-flex flex-column">
                              {selectedOption === "0" && (
                                <input
                                  type="number"
                                  className="mb-4 standardInput px-2"
                                  value={userTypedValue}
                                  required
                                  onChange={e => setUserTypedValue(e.target.value)}
                                />
                              )}
                              {selectedOption === "2" && (
                                <input
                                  type="number"
                                  className="mb-4 standardInput px-2"
                                  value={barcodeValue}
                                  disabled
                                />
                              )}
                              {selectedOption === "1" && (
                                <input
                                  type="number"
                                  className="mb-4 standardInput px-2"
                                  value={generatedProductNum}
                                  disabled
                                />
                              )}
                            </div>
                          </div>
                          <div className="d-flex flex-column" style={{width: "32%"}}>
                            <label className="inputTitle">
                              Merk
                              {/* <FontAwesomeIcon
                                icon={faPlus}
                                color="#6a6a6a"
                                className="hover"
                                onClick={() => setShowBrand(true)}
                                data-bs-toggle="exampleModal"
                                data-bs-target="#exampleModal"
                              /> */}
                            </label>
                            <select
                              className="standardInput mb-2 px-2 standardDropdownArrow"
                              id="sel1"
                              name="sellist1"
                              onChange={(e) => setBrandId(e.target.value)}
                            >
                              <option hidden></option>
                              {brands.map(brands => {
                                return <option value={brands.brand_id} key={brands.name}>{brands.name}</option>;
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=' mb-2'>
                      <TextEditor onChange={(e) => setEditorContent(e)}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='whiteBox p-4'>
                  <h5>Afbeelding uploaden</h5>
                  <div className='uploaderStyle'>
                      <h5>Sleep of selecteer uw media-bestanden om te uploaden</h5>
                  </div>
              </div>
            </div>
            <div className='col-3' style={{width: "23%"}}>
              <div className="whiteBox boxCategories mb-4">
                <div className="d-flex flex-column">
                  <div className=" widthBoxes w-100 mb-2 p-3 categories">
                    <div className='d-flex  justify-content-between '>
                      <h5 className="inputTitle mb-2">
                        Categorieën
                      </h5>
                      {/* <div className='pt-1' onClick={() => setShowCategory(true)}><FontAwesomeIcon className='inputTitle hover' icon={faPlus}/></div> */}
                    </div>
                    
                    <div className="sellChannelBoxContainer mb-3">
                      <div className='categorieënBox'>
                        <div className='px-3 d-flex position-sticky py-2 inputFieldCategory'>
                          <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Zoek categorie"
                            className='w-100 searchInput SearchIcon'
                          />
                        </div>
                        { showCategory ? 
                          <div className='categoryList'>
                            <CategoryList 
                              categories={filteredCategory}
                              checkedCategories={checkedCategory} 
                              toggle={toggleSimple}
                            />
                          </div>
                          :
                          <div className='categoryList'>
                            <CategoryList 
                              categories={filteredCategory}
                              checkedCategories={checkedCategories} 
                              toggle={toggleSimple}
                            />
                          </div>
                          }
                      </div>
                    </div>
                    <div>
                      <p className='fw-medium text-black'>Geselecteerde categoriën</p>
                      <div>
                        {Object.keys(transformedData).map((headCategory, index) => (
                          <div className='mb-3' key={`head-${index}`}>
                            <p className='fw-medium mb-0'>{headCategory}</p>
                            {transformedData[headCategory].map((category, catIndex) => (
                              <div className='d-flex flex-row align-items-center ms-2' key={`category-${index}-${catIndex}`}>
                                <div className='lineCategories me-2'></div>
                                <p className='mb-0 fw-medium'> {category}</p>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="whiteBox mb-4 col-12 p-5 pb-3 pt-4 widthGender me-3">
                <h5 className="inputTitle mb-4">Geslacht</h5>
                <div>
                  <div className="d-flex flex-row mb-3">
                    <label className="checkbox-containerSimple">
                      <input
                        type="checkbox"
                        value={1}
                        onChange={handleCheckboxChange}
                      />
                      <span className="checkmarkSimple"></span>
                    </label>
                    <h5 className="inputTitle">Man</h5>
                  </div>
                  <div className="d-flex flex-row mb-3">
                    <label className="checkbox-containerSimple">
                      <input
                        type="checkbox"
                        value={2}
                        onChange={handleCheckboxChange}
                      />
                      <span className="checkmarkSimple"></span>
                    </label>
                    <h5 className="inputTitle">Vrouw</h5>
                  </div>
                  <div className="d-flex flex-row mb-3">
                    <label className="checkbox-containerSimple">
                      <input
                        type="checkbox"
                        value={3}
                        onChange={handleCheckboxChange}
                      />
                      <span className="checkmarkSimple"></span>
                    </label>
                    <h5 className="inputTitle">Unisex</h5>
                  </div>
                </div>
              </div>
              <div className="whiteBox p-4 boxSellChannel mb-4">
                <div className="d-flex flex-column ">
                  <div className='d-flex justify-content-between'>
                    <h5 className="inputTitle mb-4 ">Prijzen</h5>
                    <div className='BTWSetting'>
                      <select
                        className="inputField standardInput standardDropdownArrow me-3 fw-medium hover"
                        style={{height: "30px", width: "137px", fontSize: "13px" }}
                        onChange={handleBtwChange}
                        required
                        defaultValue=""
                      >
                        <option value="" disabled hidden>
                          Prijzen incl. BTW
                        </option>
                        <option
                          value="21"
                          {...(btw === "21" ? { hidden: true } : { hidden: false })}
                        >
                          21%
                        </option>
                        <option
                          value="9"
                          {...(btw === "9" ? { hidden: true } : { hidden: false })}
                        >
                          9%
                        </option>
                      </select>
                      <button className=' rounded-pill px-2 text-secondary gearButtonPrices' type='button'><FontAwesomeIcon icon={faGear}/></button>
                    </div>
                  </div>
                  <div className='px-1'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex flex-row justify-content-between standardSaleParent'>
                        <div className="d-flex flex-column me-4">
                          <label className="inputTitle text-small">Standaard prijs</label>
                          <input
                            type="number"
                            step="any"
                            className="standardInput w-100 ps-2 placeholderStyle"
                            placeholder="0.00"
                            value={prices.price.inclBTW}
                            onChange={(e) => handlePriceChange(e, 'price')}
                            required
                          />
                          <p className="bottomText">€ {prices.price.exclBTW ? prices.price.exclBTW : 0.00 } Excl. BTW</p>
                        </div>
                        <div className="d-flex flex-column me-2">
                          <label className="inputTitle text-small">Sale prijs</label>
                          <input
                            type="number"
                            step="any"
                            className="standardInput w-100 ps-2 placeholderStyle"
                            placeholder="0.00"
                            value={prices.sale_Price.inclBTW}
                            onChange={(e) => handlePriceChange(e, 'sale_Price')}
                            required
                          />
                          <p className="bottomText">€ {prices.sale_Price.exclBTW ? prices.sale_Price.exclBTW : 0.00 } Excl. BTW</p>
                        </div>
                      </div>
                      <div className='d-flex flex-row justify-content-between standardSaleParent'>
                        <div className="d-flex flex-column me-4">
                          <label className="inputTitle text-small">Inkoopprijs excl. BTW</label>
                          <input
                            type="number"
                            step="any"
                            className="standardInput w-100 ps-2 placeholderStyle"
                            placeholder="0.00"
                            value={prices.buy_in_Price.inclBTW}
                            onChange={(e) => handlePriceChange(e, 'buy_in_Price')}
                            required
                          />
                          <p className="bottomText">€ {prices.buy_in_Price.exclBTW ? prices.buy_in_Price.exclBTW : 0.00 } Excl. BTW</p>
                        </div>
                        <div className="d-flex flex-column heightSalePrice">
                          <label className="inputTitle text-small text-nowrap">
                            Marge excl. BTW
                          </label>
                          <input
                            type="number"
                            className="inputField w-100 ps-2 buyPrice placeholderStyle"
                            placeholder="€ -"
                            disabled
                          ></input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='whiteBox p-4 mb-4'>
                <h5 className='mb-3 fw-semibold'>Productlabel</h5>
                <ul>
                  <p className='mb-1 fw-medium'>Nieuw</p>
                  <p className='mb-1 fw-medium'>Sale</p>
                  <p className='mb-1 fw-medium'>Uitgelicht</p>
                </ul>
              </div>
              <div className="whiteBox p-4 boxSellChannel mb-4 me-3">
                <div className="d-flex flex-column h-100">
                  <h5 className="inputTitle mb-4">Verkoopkanalen</h5>
                  <div className='px-2 d-flex h-100 flex-column justify-content-around'>
                    <div className="d-flex flex-row mb-3 me-3 slidersSellchannel justify-content-between">
                      <h6 className="switchText">Bedrijfsnaam</h6>
                      <label className="defaultSwitch ms-4">
                        <input type="checkbox" />
                        <span className="defaultSlider round"></span>
                      </label>
                    </div>
                    <div className="d-flex flex-row mb-3 me-3 slidersSellchannel justify-content-between">
                      <h6 className="switchText">Bedrijfsnaam</h6>
                      <label className="defaultSwitch ms-4">
                        <input type="checkbox" />
                        <span className="defaultSlider round"></span>
                      </label>
                    </div>
                    <div className="d-flex flex-row mb-3 me-3 slidersSellchannel justify-content-between">
                      <h6 className="switchText">Bedrijfsnaam</h6>
                      <label className="defaultSwitch ms-4">
                        <input type="checkbox" />
                        <span className="defaultSlider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </PageTemplate>
  );
}


export default AddSimple