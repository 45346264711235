import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import Pen from "./IconsAccordion/Pen.svg";
import Xmark from "./IconsAccordion/xmark.svg";
import '../../Main/Main.css';
import { useNavigate } from 'react-router';

const AccordionCategory = ({ title, content, statusCategory }) => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const panelRef = useRef(null);

  const toggleAccordion = () => {
    if (content !== "") {
      setIsActive(prevState => !prevState);
    }
  };

  useEffect(() => {
    const panel = panelRef.current;
    if (panel) {
      if (isActive) {
        panel.style.height = panel.scrollHeight + "px";
      } else {
        panel.style.height = panel.scrollHeight + "px"; // Set the height to its scrollHeight to allow transition
        requestAnimationFrame(() => {
          panel.style.height = "0px"; // Then set it to 0 to trigger the transition
        });
      }

      const handleTransitionEnd = () => {
        if (isActive) {
          panel.style.height = "auto";
        }
      };

      panel.addEventListener('transitionend', handleTransitionEnd);
      return () => {
        panel.removeEventListener('transitionend', handleTransitionEnd);
      };
    }
  }, [isActive]);

  const handleEditClick = (e) => {
    e.stopPropagation(); // Stop event propagation
    navigate("category_Bewerken");
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation(); // Stop event propagation
  };

  return (
    <div className='containerAccordion'>
      <div 
        onClick={toggleAccordion} 
        className={`d-flex flex-row justify-content-between ${content !== "" ? 'cursorPointer' : ''}`}
        style={{ cursor: content !== "" ? 'pointer' : 'default' }}
      >
        <div className='d-flex flex-row'>
          <button className={`fitContent accordion ps-0 fs-5 ${isActive ? 'active' : ''}`}>
            {title}
          </button>
          <span className={`arrow fitcontentHeight d-flex align-self-center ${content === "" ? "d-none" : ""} ${isActive ? 'active' : ''}`}>
            <FontAwesomeIcon icon={faCaretDown} />
          </span>
        </div>
        <div className='d-flex align-items-center'>
          <div style={{ color: "#A9D974" }}>{statusCategory}</div>
        </div>
        <div className='d-flex flex-row'>
          <button className='lightBlueStandardButton buttonsAccordion me-2 d-flex align-self-center' onClick={handleEditClick}>
            <img src={Pen} style={{ width: "15px" }} alt="Edit" />
          </button>
          <button className='darkBlueStandardButton buttonsAccordion d-flex align-self-center' onClick={handleDeleteClick}>
            <img src={Xmark} style={{ width: "15px", height: "15px" }} alt="Delete" />
          </button>
        </div>
      </div>
      <div className={`panel ${isActive && content !== "" ? "active" : ""}`} ref={panelRef}>
        <div className="panel-content">
          {typeof content === 'string' ? <span className='mb-0'>{content}</span> : content}
        </div>
      </div>
    </div>
  );
};

export default AccordionCategory;
