import React, { useEffect, useState } from 'react'
import PageTemplate from '../Templates/PageTemplate'
import middleware from '../Api/Middleware'
import { useParams, useNavigate } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import ToastError from '../Toasts/ToastError'
import ConfirmationModal from '../Modals/ConfirmationModal';

const ProviderActivate = () => {
    const provider_id = sessionStorage.getItem('provider_id');
    const [provider, setProvider] = useState([])
    const [backofficeSettings, setBackofficeSettings] = useState([])
    const [existingOnboarding, setExistingOnboarding] = useState([])
    const [loading, setLoading] = useState(true);
    const nav = useNavigate();

    const fetchAll = async () => {
        try {
            const [fetchProvider, fetchBackofficeSettings, findExistingOnboarding] = await Promise.all([
                middleware.get(`paymentproviders?provider_id=${provider_id}`),
                middleware.get(`settings/backoffice`),
                middleware.get(`paymentproviders/onboarding?provider_id=${provider_id}`)
            ])
            setProvider(fetchProvider.data)
            setBackofficeSettings(fetchBackofficeSettings.data[0])
            if(findExistingOnboarding.data) {
                setExistingOnboarding(findExistingOnboarding.data)
            }
            setLoading(false)
        } catch (err) {
            console.warn(err)
        }
    }

    console.log(existingOnboarding.length);

    useEffect(() => {
        if(!provider_id) {
            nav('/betalingproviders')
        }
        fetchAll()
    }, [])

    const reactivateProvider = async() => {
        try {
            const [reactivateProviderRes] = await Promise.all([
                middleware.put('paymentproviders', {provider_id: provider_id, provider_status: 0})
            ]);
            nav(-1)
        } catch (err) {
            ToastError(`Er ging iets fout met het heractiveren van ${provider.provider_name} Payments`)
            console.warn(err)
        }
    }

    return (
        <PageTemplate navbarTitle={'Activeer Provider'} pageIsLoading={loading}>
            <div className='d-flex flex-column w-100'>
                <div className="d-flex flex-row mb-3">
                    <button
                    className="btn bg-white fw-semibold rounded-pill"
                    onClick={() => nav(-1)}
                    >
                        <FontAwesomeIcon icon={faChevronLeft}/>
                    </button>
                    <h3 className="ms-3 mb-0">{provider.provider_name} Payments</h3>
                </div>
                <div className='whiteBox w-100'>
                    <h4>Controlleer jouw gegevens</h4>
                    <table className='table table-borderless w-50'>
                        <tbody>
                            <tr>
                                <td>Bedrijfsnaam KVK</td>
                                <td>{backofficeSettings.company_name}</td>
                            </tr>
                            <tr>
                                <td>Factuuradres</td>
                                <td>{backofficeSettings.street_name}</td>
                            </tr>
                            <tr>
                                <td>Land</td>
                                <td>{backofficeSettings.country}</td>
                            </tr>
                        </tbody>
                    </table>
                        <div className='d-flex flex-row gap-3'>
                            {existingOnboarding.length <= 0 &&
                                <div className='btn btn-success' onClick={e => nav(`/betalingproviders/onboarding`)}>Activeer {provider.provider_name} Payments</div>
                            }
                            {existingOnboarding.length > 0 &&
                                <div className='btn btn-success' onClick={e => reactivateProvider()}>Heractiveer {provider.provider_name} Payments</div>
                            }
                            <div className='btn btn-primary'>Ga naar jouw bedrijfsgegevens</div>
                        </div>
                </div>
            </div>
        </PageTemplate>
    );
}

export default ProviderActivate