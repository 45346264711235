import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import middleware from "../Api/Middleware";
import salesChannels from "../Api/SalesChannels";
import TablePreset from "../Table/TablePreset";
import "../Table/Table.css";
import "./Orders.css";
import { useNavigate } from "react-router";
import { format } from 'date-fns'
import { nl } from 'date-fns/locale';
import NavbarIcon from "../Navbars/Sidebar/Icons/envelope.png"
import PageTemplate from "../Templates/PageTemplate";
import TDSTextNavigation from "../Templates/TDSTextNavigation";
import CheckboxHeader from "../Table/TableComponents/CheckboxHeader";
import CheckboxCell from "../Table/TableComponents/CheckboxCell";
import SortableHeader from "../Table/TableComponents/SortableHeader";

function Orders() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [data, setData] = useState([]);
  const [consumers, setConsumers] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [salesChannel, setSalesChannel] = useState([]);
  const [orderIds, setOrderIds] = useState([]);
  const [pagination, setPagination] = useState({
    "page_size": 10,
    "page": 1,
    "max_items": 0,
    "begin_of_page": 0,
    "end_of_page": 0,
  });
  const [order, setOrder] = useState({orderBy: '', direction: ''});
  const [searchBar, setSearchBar] = useState("");

  const fetchAll = async () => {
    setLoading(true);
    const [ordersResponse, consumersResponse, salesChannelsResponse, businessResponse] = await Promise.all([
      middleware.get(`sp/ordersTableAll?page=${pagination.page}&page_size=${pagination.page_size}${order.orderBy !== '' && order.direction !== '' ? `&orderBy=${order.orderBy}&direction=${order.direction}` : ''}`),
      middleware.get(`sp/customersTableAll`),
      salesChannels.get(`saleschannels`),
      middleware.get(`businesses`),
    ]);
    setData(ordersResponse.data.data);
    setConsumers(consumersResponse.data);
    setBusinesses(businessResponse.data);
    setSalesChannel(salesChannelsResponse.data);
    setOrderIds(ordersResponse.data.data.map(data => data.order_id));
    setPagination({...pagination, "max_items": ordersResponse.data.total, "begin_of_page": ordersResponse.data.from, "end_of_page": ordersResponse.data.to})
    setLoading(false);
  };


  useEffect(() => {
    try {
      fetchAll();
    } catch (error) {
      console.warn(error)
    }
  }, [pagination.page_size, pagination.page, order, order.direction]);

  const [selectedIds, setSelectedIds] = useState([]);

  // Handle checkbox selection
  const handleSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedIds(previous => [...previous, productId]);
    } else {
      setSelectedIds(previous => previous.filter(id => id !== productId));
    }
  };

  const GetOrderStatus = ({ orderStatus }) => {
    switch (orderStatus) {
      case 0:
        return <label style={{color: "#FFB36D"}}>Openstaand</label>;
      case 1:
        return <label style={{color: "#FFB36D"}}>Klaar voor verzenden</label>;
      case 2:
        return <label style={{color: "#a9d974"}}>Afgerond</label>;
    }
  }

  const GetPaymentStatus = ({ paymentStatus }) => {
    switch (paymentStatus) {
      case 0:
        return <label className="text-info">Geen factuur</label>
      case 1:
        return <label className="text-info">Factuur</label>
    }
  }

  const GetDeliveryStatus = ({ deliveryStatus, items_sent, items_total }) => {
    switch (deliveryStatus) {
      case 0:
        return <label style={{ color: "#f97374"}}>Niet verzonden</label>
      case 1:
        return  <label style={{ color: "#a9d974"}}>Verzonden</label>
    }
  }

  // Columns for the table
  const columns = useMemo(
    () => [
      {
        Header: (
          <CheckboxHeader currentItem={data} selectedIds={selectedIds} setSelectedIds={setSelectedIds} pk={'order_id'}>
          </CheckboxHeader>
        ),
        accessor: "select",
        Cell: ({ row }) => {
          return <CheckboxCell row={row} handleSelection={handleSelection} selectedIds={selectedIds} pk={'order_id'}/>
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} field="order_number">
            Bestelling
          </SortableHeader>
        ),
        accessor: 'order_number',
        Cell: ({ row }) => {
          const date = format(new Date(row.original.time_of_order), 'dd MMMM yyyy HH:mm', { locale: nl });

          return (
            <div className="d-flex flex-column">
              <TDSTextNavigation
                text={row.original.order_number}
                link={`/bestellingen/bewerken/${row.original.order_number}`}
                extraClassNames={'text-nowrap'}
              />

              <div>
                <label>{date}</label>
              </div>

              {/* <div style={{fontSize: "12px"}}>{
                row.original.internal_id != "" ? (
                  consumers.filter(filter =>
                    filter.internal_id == row.original.internal_id
                  ).map((consumer, index) => (
                    <TDSTextNavigation
                      key={index}
                      text={(consumer.name || "")}
                      link={`/klanten/bewerken/${consumer.consumer_id}`}
                    />
                  ))
                ) : (
                  businesses.filter(filter => (
                    filter.internal_id == row.original.internal_id
                  )).map((company, index) =>
                    <TDSTextNavigation
                      key={index}
                      text={company.company_name}
                      link={`/klanten/bewerken/${company.company_id}`}
                    />
                  )
                )}
              </div> */}
            </div>
          )
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} field="total_price_incl_tax">
            Prijs incl.
          </SortableHeader>
        ),
        accessor: 'total_price_incl_tax',
        Cell: ({ row }) => {  
          const price = `€ ${Number(row.original.total_price_incl_tax).toFixed(2).replace('.', ',').replace(',00', ',-')}`;
          return (
            <div>{price}</div>
          )
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} field="sold_at">
            Verkoopkanaal
          </SortableHeader>
        ),
        accessor: 'sold_at',
        Cell: ({ row }) => {
        
          return (
            <div className="d-flex flex-column">
            {row.original.sales_channel_name || "Onbekend verkoop kanaal"}
            </div>
          );
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} field="order_status">
            Status
          </SortableHeader>
        ),
        accessor: 'order_status',
        Cell: ({ row }) => {
          return <div><GetOrderStatus orderStatus={row.original.order_status} /></div>;
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} field="payment_status">
            Betaling
          </SortableHeader>
        ),
        accessor: 'payment_status',
        Cell: ({ row }) => {
          return (
            <div><GetPaymentStatus paymentStatus={row.original.payment_status} /></div>
          )
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} field="delivery_status">
            Verzending
          </SortableHeader>
        ),
        accessor: 'delivery_status',
        Cell: ({ row }) => {
          return (
            <div><GetDeliveryStatus deliveryStatus={row.original.delivery_status} items_sent={row.original.items_sent} items_total={row.original.items_total} /></div>
          )
        }
      },
    ], [order.orderBy, order.direction, selectedIds, setSelectedIds, data]
  );

  // Table instance
  const tableInstance = useTable({ columns, data: data });
  
  const Action = (methode) => {
    if (methode == 'select all') {
      setSelectedIds(orderIds)
    }
    else if (methode == 'de-select all') {
      setSelectedIds([])
    }
  }



  return (
    <PageTemplate navbarTitle="Bestellingen" pageIsLoading={loadingPage} iconNavbar={NavbarIcon}>
      <div className="orderTableOverview w-100 mx-3">
        <div className="orderTable ">
          <TablePreset 
            data={[{'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'order_id'}]} 
            tableLoading={loading}
            setPagination={setPagination} 
            handleSelection={handleSelection} 
            searchBar={[{ shown: true, value: searchBar, function: setSearchBar}]}
            actionOptions={[{title: "Selecteer alles", function: () => Action('select all')}, {title: "De-selecteer alles", function: () => Action('de-select all')}]} // actionOptions={[{title: "selecteer alles", function: () => console.log(true)}]}
            leftButtons={[{title: "Filteren", function: () => console.log('a')}]} // leftButtons={[{title: "a", function: () => console.log('a')}, {title: "b", function: () => console.log('b')}]} 
            rightButtons={[{title: "Nieuwe bestelling", function: () => navigate('./nieuw')}]} // rightButtons={[{title: "c", function: () => console.log('c')}]}
          />   
        </div>
      </div>
      
    </PageTemplate>
  );
}

export default Orders;