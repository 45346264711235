import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import warning from "../Icons/Warning-sign.svg";

const LoyaltyParticipationModal = ({ openLoyaltyParticipation, closeLoyaltyParticipation, decision }) => {
  const [showLoyaltyParticipation, setShowLoyaltyParticipation] = useState(false);

  useEffect(() => {
    if (openLoyaltyParticipation) {
      setShowLoyaltyParticipation(true);
    }
  }, [openLoyaltyParticipation]);

  const closeLoyaltyParticipationModal = () => {
    setShowLoyaltyParticipation(false);
    closeLoyaltyParticipation();
  };

  const DecisionParticipation = (result) => {
    decision(result);
    closeLoyaltyParticipationModal();
  };

  return (
    <Modal
      id="modalBlueprint"
      show={showLoyaltyParticipation}
      onHide={closeLoyaltyParticipationModal}
      
      size="lg"
      centered
      backdrop="static"
    >
      <Modal.Header className="modalTop justify-content-end" style={{ height: "60px" }}>
      </Modal.Header>
      <Modal.Body className="modalCenter d-flex justify-content-center">
        <div className='d-flex flex-column justify-content-center'>
          <img style={{ width: "75px" }} className=' d-flex align-self-center mb-4' src={warning} />
          <div className='w-100 d-flex justify-content-center mb-3'>
            <h3 style={{ width: "60%" }} className='text-center'>Weet je zeker dat je de deelname uit wilt zetten?</h3>
          </div>
          <div className='d-flex justify-content-between align-self-center' style={{ width: "60%" }}>
            <button className='btn DarkStandardButton' onClick={() => DecisionParticipation(false)}>Annuleren</button>
            <button style={{ padding: "5px 15px" }} className='btn lightBlueStandardButton' onClick={() => DecisionParticipation(true)}>Ja, ik weet het zeker</button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="modalBottom">
      </Modal.Footer>
    </Modal>
  );
};

export default LoyaltyParticipationModal;
