import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import TableDrag from "../Table/TableDrag/TableDrag";
import middleware from "../Api/Middleware";
import PageTemplate from "../Templates/PageTemplate";
import NavbarIcon from "../WarehouseSettings/Icons/copyright.svg"
import BrandModal from "../Modals/BrandModal";
import "./Brands.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const Brands = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [pagination, setPagination] = useState({
    page_size: 10,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  }); // the useState for the pagination for the table
  const [loading, setLoading] = useState(true); // useState for page loading
  const [loadingPage, setLoadingPage] = useState(false);
  const [brands, setBrands] = useState([]); // useState for Brands
  const [loadingScreen, setLoadingScreen] = useState(true); // useState for the Loadingscreen
  const [searchengine, setSearchengine] = useState("");
  const [timerId, setTimerId] = useState(null);
  const [searchBarInput, setSearchBarInput] = useState("");
  const [showBrand, setShowBrand] = useState(false);


  const fetchAll = async () => {
    try {
      setLoading(true);
      const [fetchBrands] = await Promise.all([
        middleware.get(
          `sp/brandsTableActive?page=${pagination.page}&page_size=${pagination.page_size}&orderBy=sorting_order`),
      ]);

      const updatedBrandsOrder = fetchBrands.data.data.map((item, index) => ({
        ...item,
        sorting_order:
          index +
          (pagination.page === 1
            ? 0
            : pagination.page_size * (pagination.page - 1)) +
          1, // updating the sorting order based on position
      }));

      const isOrderChanged = fetchBrands.data.data.some((item, index) => {
        return item.sorting_order !== updatedBrandsOrder[index].sorting_order;
      });

      if (isOrderChanged) {
        await middleware.put(`products/brands`, {
          bulk_data: updatedBrandsOrder,
        });
      }

      setBrands(updatedBrandsOrder);
      setPagination({
        ...pagination,
        max_items: fetchBrands.data.total,
        begin_of_page: fetchBrands.data.from,
        end_of_page: fetchBrands.data.to,
      }); // sets the pagination usestate with the max items begin of the page and the end of the page
      setLoadingScreenFunc();
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
  };

  useEffect(() => {
    fetchAll();
  }, [pagination.page_size, pagination.page]);

  const openBrandModal = (title) => {
    if (title == "merk") {
      setShowBrand(true)
    }
  }

  useEffect(() => {
    try {
      if (searchengine.trim() === "" && searchengine === "") {
        // Code to fetch all when searchengine is empty
        fetchAll();
      } else if (searchengine.trim() !== "") {
        // Code to perform the search when searchengine has actual characters
        const doIt = async () => {
          const [fetchBrands] = await Promise.all([
            middleware.get(
              `products/brands?page=${pagination.page}&filter=status=0&page_size=${pagination.page_size}&searchTerm=${searchengine}&noSearch=[brand_id,version,updated_at,created_at]`
            ),
          ]);
          setBrands(fetchBrands.data.data);
          setPagination({
            ...pagination,
            max_items: fetchBrands.data.total,
            begin_of_page: fetchBrands.data.from,
            end_of_page: fetchBrands.data.to,
          });
        };
        doIt();
      } 
    } catch (error) {
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
}, [searchengine]);


  const searchDelay = async (value) => {
    // const value = await e.target.value
    setSearchBarInput(value)
    if (timerId) {
      clearTimeout(timerId);
    }
    const newTimerId = setTimeout(() => {
      setSearchengine(value);
    }, 500);

    setTimerId(newTimerId);
  };

  const closeModalBrand = () => setShowBrand(false)


  function setLoadingScreenFunc() {
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }

  return (
    <>
      <BrandModal openBrand={showBrand} closeBrand={closeModalBrand} />
      <PageTemplate navbarTitle={"Merken"} pageIsLoading={loadingPage} iconNavbar={NavbarIcon}>
        <div className="w-100">
          <div className="d-flex flex-row mb-3">
            <button
              className="btn bg-white fw-bold rounded-pill"
              onClick={() => navigate("/warehouse/instellingen")}
            >
                <FontAwesomeIcon icon={faChevronLeft}/>
            </button>
            <h2 className="ms-3 mb-0">Merken</h2>
          </div>
          <div className="brandDrag">
            <TableDrag
              dataParameter={brands}
              tableLoading={loading}
              pagination={pagination}
              handlePaginationChange={(event) => setPagination(event)}
              primaryKey={"primary_key"}
              urlEndPoint={"products/brands"}
              newItemTitle={"merk"}
              openModal={(e) => openBrandModal(e)}
              dataEditModalSize={() => {}}
              searchTerm={(e) => searchDelay(e)}
              columns={[
                { key: "sorting_order", title: "Sorteer volgorde" },
                { key: "name", title: "Naam" },
                { key: "description", title: "Beschrijving", maxLength: 60 },
              ]}
            />
          </div>
        </div>
        
      </PageTemplate>
    </>
  );
};

export default Brands;
