import React from 'react';
import { Link } from 'react-router-dom';

const TDSTextNavigation = ({ text, link, extraClassNames }) => {
  return (
    <Link
      to={link}
      className={`text-info hover text-decoration-none ${extraClassNames}`}
    >
      {text}
    </Link>
  );
}

export default TDSTextNavigation;