import React from 'react'

const ThirdBox = (props) => {
  return (
    <>
    <div className="whiteBox p-5 m-5 mt-0 align-center">
        <h4>Voorraad</h4>
        <div className='d-flex flex-column'>
            <div className='d-flex flex-column'>
                <label>Minimale voorraad</label>
                <input 
                    type='number'
                    value={props?.stock?.minimal ?? ""}
                    onChange={e => props.setStock({...props.stock, minimal: e.target.value}) + props.setUpdatedStock(true)}
                    className=' w-25 productInputWidth'
                />
            </div>
            <div className='d-flex flex-column'>
                <label>Maximale voorraad</label>
                <input 
                    type='number' 
                    value={props?.stock?.maximal ?? ""}
                    onChange={e => props.setStock({...props.stock, maximal: e.target.value}) + props.setUpdatedStock(true)}
                    className=' w-25 productInputWidth'
                />
            </div>
            <div className='d-flex flex-column'>
                <label>Voorraad hoeveelheid</label>
                <input 
                    type='number' 
                    value={props?.stock?.amount ?? ""}
                    onChange={e => props.setStock({...props.stock, amount: e.target.value}) + props.setUpdatedStock(true)}
                    className=' w-25 productInputWidth'
                />
            </div>
            <div className='d-flex flex-column'>
                <label>Voorraad gedrag</label>
                    <select 
                        className=' w-25 productInputWidth'
                        value={props?.stock?.stock_behaviour ?? ""}
                        onChange={e => props.setStock({...props.stock, stock_behaviour: e.target.value}) + props.setUpdatedStock(true)}
                    >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                </select>
            </div>
            <div className='d-flex flex-column'>
                <label>Bestelbaarheid</label>
                    <select 
                        className=' w-25 productInputWidth border-1'
                        value={props?.stock?.deliverability ?? ""}
                        onChange={e => props.setStock({...props.stock, deliverability: e.target.value}) + props.setUpdatedProduct(true)}
                    >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                </select>
            </div>
            {/* <div className='d-flex flex-column'>
                <label>Heeft serienummers</label>
                <div className='d-flex flex-row'>
                    <select 
                        className=' w-25 productInputWidth'
                        value={props.stock.stock_behaviour}
                        onChange={e => props.setStock({...props.stock, stock_behaviour: e.target.value})}
                    >
                        <option>1</option>
                        <option>2</option>
                    </select>
                    <button type='button' className='btn btn-outline-primary col-1'>toon</button>
                </div>
            </div> */}
        </div>
    </div>
    </>
  )
}

export default ThirdBox