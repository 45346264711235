import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import warning from "../Icons/Warning-sign.svg";

const QuotationModal = ({ openQuotation, closeQuotation }) => {
  const [showQuotation, setShowQuotation] = useState(false);
  // const [merkName, setMerkName] = useState("");
  // const [description, setdescription] = useState("");
  // const [keywords, setKeywords] = useState("");

  useEffect(() => {
    if (openQuotation) {
      setShowQuotation(true)
    }
  }, [openQuotation]);
  
  const closeQuotationModal = () => {
    setShowQuotation(false);
    closeQuotation(true)
  }

  // const submitNewQuotation = async (event) => {
  //   try {
  //     if (event) {
  //       event.preventDefault();
  //     }
  //     if (merkName) 
  //     {
  //       await middleware.post("products/Quotations", {
  //         name: merkName,
  //         description: description,
  //         keywords: keywords,
  //       }).then(() => window.location.reload());      
        
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  
  return (
    <Modal
        id="modalBlueprint"
        show={showQuotation}
        onHide={() => closeQuotationModal()}
        
        size="lg"
        centered
        backdrop="static"
      >
        <Modal.Header className="modalTop justify-content-end" style={{height: "60px"}}>
        </Modal.Header>
        <Modal.Body className="modalCenter d-flex justify-content-center">
          <div className='d-flex flex-column justify-content-center'>
            <img style={{width: "75px" }} className=' d-flex align-self-center mb-4' src={warning}/>
            <div className='w-100 d-flex justify-content-center mb-3'>
              <h3 style={{width: "60%"}} className='text-center'>Weet je zeker dat je dit bestand wilt verwijderen</h3>
            </div>
            <div className='d-flex justify-content-between align-self-center' style={{width: "60%"}}>
              <button className='btn DarkStandardButton' onClick={() => closeQuotationModal()}>Annuleren</button>
              <button style={{padding: "5px 15px"}} className='btn lightBlueStandardButton'>Ja, ik weet het zeker</button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modalBottom">

        </Modal.Footer>
      </Modal>
  );
}

export default QuotationModal;
