import React, { useMemo, useState, useEffect } from 'react'
import { useTable } from 'react-table'
import middleware from '../Api/Middleware'
import PageTemplate from '../Templates/PageTemplate'
import TablePreset from '../Table/TablePreset'
import CheckboxCell from '../Table/TableComponents/CheckboxCell'
import CheckboxHeader from '../Table/TableComponents/CheckboxHeader'
import SortableHeader from '../Table/TableComponents/SortableHeader'
import LoadingScreen from '../LoadingScreen/LoadingScreen'
import { useNavigate } from 'react-router'
import ConfirmationModal from '../Modals/ConfirmationModal'

const PaymentProviders = () => {
  const nav = useNavigate();
  const [selectedIds, setSelectedIds] = useState([])
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [clickedId, setClickedId] = useState(null);
  const [loading, setLoading] = useState(true)
  const [order, setOrder] = useState({orderBy: '', direction: ''}); // useState for ordering the columns in the table
  const [pagination, setPagination] = useState({
    "page_size": 10,
    "page": 1,
    "max_items": 0,
    "begin_of_page": 0,
    "end_of_page": 0,
  }); // the useState for the pagination for the table
  const [providers, setProviders] = useState([]);


  const fetchAll = async () => {
    try {
      const [fetchProviders] = await Promise.all([
        middleware.get('paymentproviders')
      ]);
      setProviders(fetchProviders.data)
      setLoading(false)
    } catch (error) {
        console.warn(error)
    }
  }

  const deactivateProvider = async(provider_id) => {
    const [deactivateResponse] = await Promise.all([
      middleware.put('paymentproviders', {provider_id: provider_id, provider_status: 1})
    ])
  }

  const deactivateFuncs = async (provider_id) => {
    setClickedId(provider_id)
    setConfirmModalOpen(true);
  }

  const deactivateConfirm = async(provider_id) => {
    deactivateProvider(provider_id);
    setLoading(true);
    fetchAll();
  }


  useEffect(() => {
    fetchAll();
  }, []);


  // Handle checkbox selection
  const handleSelection = (paymentId, isSelected) => {
    if (isSelected) {
      setSelectedIds([...selectedIds, paymentId]);
    } else {
      setSelectedIds(selectedIds.filter(id => id !== paymentId));
    }
  };

  //columns for the table
  const columns = useMemo(
    () => {
      let columnsArray = [
        {
          Header: (
            <div>Image</div>
          ),
          accessor: "provider_img",
          Cell: ({row}) => {
            return <img style={{height: "30px"}} src={row.original.provider_icon}/>
          }
        },
        {
          Header: (
            <SortableHeader currentItem={order} setItem={setOrder} field={'provider_name'} pos={'start'}>
              Provider
            </SortableHeader>
          ),
          accessor: "provider_name",
          Cell: ({row}) => {
            return (
              <a className='text-info text-decoration-none fw-bold' href={`/provider`}>{row.original.provider_name}</a>
            )
          }
        },
        {
          Header: (
            <SortableHeader currentItem={order} setItem={setOrder} field={'provider_status'} pos={'center'}>
              Geactiveerd
            </SortableHeader>
          ),
          accessor: "provider_status",
          Cell: ({row}) => {
            if(row.original.provider_status == 1) {
              return (
                <div className='text-danger fw-bold text-center'>nee</div>
              )
            } else {
              return (
                <div className='text-success fw-bold text-center'>ja</div>
              )
            }
          }
        },
        {
          Header: (""),
          accessor: "provider_status_actions",
          Cell: ({row}) => {
            if(row.original.provider_status == 1) {
              return (
                <div className='d-flex justify-content-end'>
                  <div className='btn btn-outline-primary' onClick={() => {
                    sessionStorage.setItem('provider_id', row.original.provider_id);
                    nav(`details`)
                  }}>Activeren</div>
                </div>
              )
            } else {
              return (
                <div className='d-flex justify-content-end'>
                  <div className='btn btn-outline-primary' onClick={e => deactivateFuncs(row.original.provider_id)}>Deactiveren</div>
                </div>
              )
            }
          }
        },
      ];
        return columnsArray; 
    },
    [order, selectedIds, setSelectedIds, providers, setConfirmModalOpen]
  );

  //   Table instance
  const tableInstance = useTable({ columns, data: providers || [] });


  if (loading) {
    return (
      <LoadingScreen/>
    );
  }
  
  

  return (
    <PageTemplate navbarTitle={'Betalingproviders'}>
    <ConfirmationModal opened={confirmModalOpen} setOpened={setConfirmModalOpen} clickYes={e => deactivateConfirm(clickedId)} message={`Je gaat ${providers.filter(filt => filt.provider_id == clickedId).map(map => map.provider_name)} Payments deactiveren, weet je het zeker?`}/>
      <div className="productOverview">
        <div className='productTable w-100'>
          <TablePreset
            data={[{'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination}]}
          />
        </div>
      </div>
    </PageTemplate>
  )
}

export default PaymentProviders