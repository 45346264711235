import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Modals/ModalStyle.css";

const OrderRowEdit = ({ openOrderRow, closeOrderRow }) => {
  const [showOrderRow, setShowOrderRow] = useState(false);

  useEffect(() => {
    if (openOrderRow) {
      setShowOrderRow(true)
    }
  }, [openOrderRow]);
  
  const closeOrderRowModal = () => {
    setShowOrderRow(false);
    closeOrderRow(true)
  }
  
  return (
    <Modal
        id="modalOrderRow"
        show={showOrderRow}
        onHide={() => closeOrderRowModal()}
        centered
        size="xl"
        backdrop="static"
      >
        <Modal.Header className="modalTop justify-content-between">
          <h4 className='ms-3 fw-semibold'>Orderregel wijzigen</h4>
          <button variant="transparent" className=' btn' onClick={() => closeOrderRowModal()}>
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <Modal.Body className="modalCenter">
          <div className="contendModal w-100 d-flex flex-column">
            <div className='d-flex flex-column mb-4'>
              <label className='fw-semibold mb-2'>Productnaam</label>
              <input className='standardInput'/>
            </div>
            <div className='d-flex flex-column'>
              <label className='fw-semibold mb-2'>Opmerking wordt op orderregel getoond</label>
              <textarea className='standardInput'style={{height: "200px"}}/>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modalBottom">
          <button
            type="sumbit"
            className="btn lightBlueStandardButton"
            onClick={() => {
              closeOrderRowModal()
            }}
          >
            Opslaan
          </button>
        </Modal.Footer>
      </Modal>
  );
}

export default OrderRowEdit;
