import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import TableDrag from "../Table/TableDrag/TableDrag";
import middleware from "../Api/Middleware";
import PageTemplate from "../Templates/PageTemplate";
import NavbarIcon from "../WarehouseSettings/Icons/categories.svg"
import CategoryModal from "../Modals/CategoryModal";
import "./Category.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const Category = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [pagination, setPagination] = useState({
    page_size: 10,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  }); // the useState for the pagination for the table
  const [loading, setLoading] = useState(true); // useState for page loading
  const [category, setCategory] = useState([]); // useState for Category
  const [loadingScreen, setLoadingScreen] = useState(true); // useState for the Loadingscreen
  const [searchengine, setSearchengine] = useState("");
  const [searchBarInput, setSearchBarInput] = useState("");
  const [timerId, setTimerId] = useState(null);
  const [showCategory, setShowCategory] = useState(false);


  const fetchAll = async () => {
    try {
      setLoading(true);
      const [fetchCategory] = await Promise.all([
        middleware.get(
          `sp/categoriesTableAll?page=${pagination.page}&page_size=${pagination.page_size}&orderBy=sorting_order&status=0`
        ),
      ]);

      const updatedCategoryOrder = fetchCategory.data.data.map(
        (item, index) => ({
          ...item,
          sorting_order:
            index +
            (pagination.page === 1
              ? 0
              : pagination.page_size * (pagination.page - 1)) +
            1, // updating the sorting order based on position
        })
      );

      const isOrderChanged = fetchCategory.data.data.some((item, index) => {
        return item.sorting_order !== updatedCategoryOrder[index].sorting_order;
      });

      if (isOrderChanged) {
        await middleware.put(`products/category`, {
          bulk_data: updatedCategoryOrder,
        });
      }

      setCategory(updatedCategoryOrder);
      setPagination({
        ...pagination,
        max_items: fetchCategory.data.total,
        begin_of_page: fetchCategory.data.from,
        end_of_page: fetchCategory.data.to,
      }); // sets the pagination usestate with the max items begin of the page and the end of the page
      setLoadingScreenFunc();
    } catch (error) {
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
      console.log(error.response);
    }
  };

  useEffect(() => {
    try {
      if (searchengine.trim() === "" && searchengine === "") {
        // Code to fetch all when searchengine is empty
        fetchAll();
      } else if (searchengine.trim() !== "") {
        // Code to perform the search when searchengine has actual characters
        const doIt = async () => {
          const [fetchCategory] = await Promise.all([
            middleware.get(
              `sp/categoriesTableAll?page=${pagination.page}&page_size=${pagination.page_size}&searchTerm=${searchengine}&noSearch=[primary_key,size_id,version,status,updated_at,created_at]`
            ),
          ]);
          setCategory(fetchCategory.data);
          setPagination({
            ...pagination,
            max_items: fetchCategory.data.total,
            begin_of_page: fetchCategory.data.from,
            end_of_page: fetchCategory.data.to,
          });
        };
        doIt();
      }
    } catch (error) {
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
}, [searchengine]);

  const searchDelay = async (value) => {
    // const value = await e.target.value
    setSearchBarInput(value)
    if (timerId) {
      clearTimeout(timerId);
    }
    const newTimerId = setTimeout(() => {
      setSearchengine(value);
    }, 500);

    setTimerId(newTimerId);
  };

  const openCategoryModal = (title) => {
    if (title == "categorie") {
      setShowCategory(true)
    }
  }

  const closeModalCategory = () => setShowCategory(false)


  useEffect(() => {
    fetchAll();
  }, [pagination.page_size, pagination.page]);

  function setLoadingScreenFunc() {
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }

  return (
    <>
    <CategoryModal openCategory={showCategory} closeCategory={closeModalCategory} />
      <PageTemplate navbarTitle={"Categorieën"} iconNavbar={NavbarIcon}>
        <div className="w-100">
          <div className="d-flex flex-row mb-3">
            <button
              className="btn bg-white fw-semibold rounded-pill"
              onClick={() => navigate("/warehouse/instellingen")}
            >
                <FontAwesomeIcon icon={faChevronLeft}/>
            </button>
            <h2 className="ms-3 mb-0">Categoriën</h2>
          </div>
          <div className="categoryTable">
            <TableDrag
              dataParameter={category}
              tableLoading={loading}
              newItemTitle={"categorie"}
              pagination={pagination}
              handlePaginationChange={(event) => setPagination(event)}
              primaryKey={"primary_key"}
              urlEndPoint={"products/category"}
              openModal={(e) => openCategoryModal(e)}
              dataEditModalSize={() => {}}
              searchTerm={(e) => searchDelay(e)}
              columns={[
                { key: "sorting_order", title: "Sorteer volgorde" },
                { key: "title", title: "Titel" },
              ]}
            />
          </div>
        </div>
      </PageTemplate>
    </>
  );
};

export default Category;
