import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NoteModal = ({ openNote, closeNote }) => {
  const [showNote, setShowNote] = useState(false);
  // const [merkName, setMerkName] = useState("");
  // const [description, setdescription] = useState("");
  // const [keywords, setKeywords] = useState("");

  useEffect(() => {
    if (openNote) {
      setShowNote(true)
    }
  }, [openNote]);
  
  const closeNoteModal = () => {
    setShowNote(false);
    closeNote(true)
  }

  // const submitNewNote = async (event) => {
  //   try {
  //     if (event) {
  //       event.preventDefault();
  //     }
  //     if (merkName) 
  //     {
  //       await middleware.post("products/Notes", {
  //         name: merkName,
  //         description: description,
  //         keywords: keywords,
  //       }).then(() => window.location.reload());      
        
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  
  return (
    <Modal
        id="modalBlueprint"
        show={showNote}
        onHide={() => closeNoteModal()}
        
        size="xl"
        centered
        backdrop="static"
      >
        <Modal.Header className="modalTop justify-content-end" style={{ paddingBottom: "0px"}}>
          <button variant="transparent" className=' btn' onClick={() => closeNoteModal()}>
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <Modal.Body className="modalCenter">
          <div>
            <h3>Notitie titel</h3>
            <small className=' fw-medium'>1 april 2023 09:10  |  Medewerker</small>
            <p className='mt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla lacus risus, lacinia sed placerat at, pellentesque ut neque. Vivamus diam orci, eleifend aliquam placerat vel, pulvinar at sapien. Donec dolor eros, facilisis vitae orci sed, varius condimentum purus. Phasellus auctor est eu ultrices dignissim. Vestibulum venenatis fringilla ipsum non egestas. Donec sit amet arcu cursus, tristique urna vitae, pulvinar ligula. Proin eleifend consectetur neque vitae condimentum. Quisque sit amet eros.</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="modalBottom">

        </Modal.Footer>
      </Modal>
  );
}

export default NoteModal;
