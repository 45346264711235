import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import middleware from "../../../Api/Middleware";
import salesChannels from "../../../Api/SalesChannels";
import TablePreset from "../../../Table/TablePreset";
import "../../../Table/Table.css";
import "../../../Orders/Orders.css";
import { useNavigate, useParams } from "react-router";
import CheckboxHeader from "../../../Table/TableComponents/CheckboxHeader";
import CheckboxCell from "../../../Table/TableComponents/CheckboxCell";
import SortableHeader from "../../../Table/TableComponents/SortableHeader";
import Downloaden from "../Icons/download.svg";

const Invoice = () => {
  const navigate = useNavigate();
const [loading, setLoading] = useState(true);

  const [consumers, setConsumers] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [salesChannel, setSalesChannel] = useState([]);
  const [orderIds, setOrderIds] = useState([]);
  const [pagination, setPagination] = useState({
    "page_size": 10,
    "page": 1,
    "max_items": 0,
    "begin_of_page": 0,
    "end_of_page": 0,
  });
  const [order, setOrder] = useState({orderBy: '', direction: ''});
  const [searchBar, setSearchBar] = useState("");
  const { consumer_id } = useParams();

//   const fetchAll = async () => {
//     setLoading(true);
//     const consumers_id = consumer_id.replace(':', '');
//     try {
//         const [ConsumerEdit] = await Promise.all([
//           middleware.get(`consumers?internal_id=${consumers_id}`),
//         ]);
//         const internalId = ConsumerEdit.data.internal_id;
//         const [ordersResponse, salesChannelsResponse, businessResponse] = await Promise.all([
//             middleware.get(`orders?page=${pagination.page}&internal_id=${internalId}&page_size=${pagination.page_size}${order.orderBy !== '' && order.direction !== '' ? `&orderBy=${order.orderBy}&direction=${order.direction}` : ''}`),
//             salesChannels.get(`saleschannels`),
//             middleware.get(`businesses`),
//         ]);

//         setData(ordersResponse.data.data);
//         setBusinesses(businessResponse.data);
//         setSalesChannel(salesChannelsResponse.data);
//         setOrderIds(ordersResponse.data.data.map(data => data.order_id));
//         setPagination({...pagination, "max_items": ordersResponse.data.total, "begin_of_page": ordersResponse.data.from, "end_of_page": ordersResponse.data.to});
//     } catch (error) {
//         // Handle errors here
//         console.error('Error fetching data:', error);
//     }
//     setLoading(false);
// };


const testData = [
  {
    "order_id": "001",
    "QuotationNumber": "TDSO-000023",
    "CreatedAtDate": "1 april 2023",
    "CreatedAtTime": "9:10",
    "Price": "69,00",
    "status": 1,
  },
];

const [data, setData] = useState(testData);



  // useEffect(() => {
  //   try {
  //     fetchAll();
  //   } catch (error) {
  //     console.warn(error)
  //   }
  // }, [pagination.page_size, pagination.page, order, order.direction]);

  const [selectedIds, setSelectedIds] = useState([]);

  // Handle checkbox selection
  const handleSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedIds(previous => [...previous, productId]);
    } else {
      setSelectedIds(previous => previous.filter(id => id !== productId));
    }
  };

  const GetStatus = ({ Status }) => {
    switch (Status) {
      case 0:
        return <label className="text-danger">Ge-annuleerd</label>;
      case 1:
        return <label className="text-warning">Openstaand</label>;
      case 2:
        return <label className="text-info">Nieuw</label>;
      case 3:
        return <label className="text-success">Voltooid</label>;
    }
  }
  // Columns for the table
  const columns = useMemo(
    () => [
      {
        Header: (
          <CheckboxHeader currentItem={data} selectedIds={selectedIds} setSelectedIds={setSelectedIds} pk={'order_id'}>
          </CheckboxHeader>
        ),
        accessor: "select",
        Cell: ({ row }) => {
          return <CheckboxCell row={row} handleSelection={handleSelection} selectedIds={selectedIds} pk={'order_id'}/>
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} pos={"start"} field="order_number">
            Factuurnummer
          </SortableHeader>
        ),
        accessor: 'QuotationNumber',
        Cell: ({ row }) => {
          return (
            <div className="d-flex flex-column" style={{color: "#009fe3"}}>
              <p className="mb-0">{row.original.QuotationNumber}</p>
            </div>
          )
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} classNameHeader={"TypeAdressInfoHeader"} field="delivery_status">
            Factuur datum
          </SortableHeader>
        ),
        accessor: 'CreatedAtDate',
        Cell: ({ row }) => {
          return (
            <div className="text-center">
              <div>{row.original.CreatedAtDate}</div>
              <div>{row.original.CreatedAtTime}</div>
            </div>
          )
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} classNameHeader={"TypeAdressInfoHeader"} field="delivery_status">
            Waarde (excl. BTW)
          </SortableHeader>
        ),
        accessor: 'Price',
        Cell: ({ row }) => {
          return (
            <div className="text-center">
              <div>€ {row.original.Price}</div>
            </div>
          )
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} classNameHeader={"TypeAdressInfoHeader"} field="delivery_status">
            Status
          </SortableHeader>
        ),
        accessor: 'status',
        Cell: ({ row }) => {
          return (
            <div className="text-start"><GetStatus Status={row.original.status} /></div>
          )
        }
      },
      {
        Header: () => (
          <div className="text-center">Downloaden</div>
        ),
        accessor: 'Download',
        Cell: ({ row }) => {
          return (
            <div className="d-flex flex-column">
              <img className="downloadImg" src={Downloaden} />
            </div>
          )
        }
      },
    ], [order.orderBy, order.direction, selectedIds, setSelectedIds, data]
  );

  // Table instance
  const tableInstance = useTable({ columns, data: data });
  
  const Action = (methode) => {
    if (methode == 'select all') {
      setSelectedIds(orderIds)
    }
    else if (methode == 'de-select all') {
      setSelectedIds([])
    }
  }

  return (
    <div className="invoiceOverviewTab h-100 mt-4">
      <div className="my-3 tablesCustomerEdit  h-100">
        <TablePreset 
          data={[{'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'order_id'}]} 
          setPagination={setPagination} 
          handleSelection={handleSelection} 
          searchBar={[{ shown: true, value: searchBar, function: setSearchBar}]}
          leftButtons={[{title: "Filteren", function: () => console.log('a')}]} // leftButtons={[{title: "a", function: () => console.log('a')}, {title: "b", function: () => console.log('b')}]} 
          rightButtons={[{title: "Nieuw offerte", function: () => navigate(-1)}]} // rightButtons={[{title: "c", function: () => console.log('c')}]}
        />
      </div>
    </div>
  );
}

export default Invoice;