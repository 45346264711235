import React, { useRef, useState, useEffect } from "react";
import tinycolor from 'tinycolor2';
import { EyeDropper } from "react-eyedrop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeDropper } from '@fortawesome/free-solid-svg-icons'

const ColorPicker = ({ onColorPick }) => {
  const inputRef = useRef(null);

  const handleClickIcon = () => {
    inputRef.current.click();
  }

  const handleColorChange = (event) => {
    onColorPick(event.target.value);
  }

  return (
    <div className=" align-items-center d-flex">
      <FontAwesomeIcon 
        icon={faEyeDropper} 
        className="iconEyedropper" 
        onClick={handleClickIcon} 
      />
      <input 
        type="color"
        style={{visibility: 'hidden', width: "10px"}} 
        ref={inputRef} 
        onChange={handleColorChange} 
      />
    </div>
  );
}

export default ColorPicker;