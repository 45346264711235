import React, { useEffect, useState } from 'react';
import Sidebar from '../Navbars/Sidebar/Sidebar';
import Navbar from '../Navbars/Navbar/Navbar';
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import './Template.css';

const PageTemplate = ({ navbarTitle, pageIsLoading, children, iconNavbar }) => {
  const [sidebarIsLoading, setSidebarIsLoading] = useState(true);
  const [navbarIsLoading, setNavbarIsLoading] = useState(true);
  const [contentClicked, setContentClicked] = useState(false);


  const sidebarIsLoadingChange = event => {
    setSidebarIsLoading(event);
  }

  const navbarIsLoadingChange = event => {
    setNavbarIsLoading(event);
  }

  return (
    <div className="d-flex flex-nowrap">
      {pageIsLoading || sidebarIsLoading || navbarIsLoading ? (
        <LoadingScreen/>
      ) : ('')}
      <Sidebar shortcuts={[]} callbackFuncIsLoading={sidebarIsLoadingChange} contentClick={contentClicked} />
      <div className="d-flex flex-column " onClick={() => setContentClicked(!contentClicked)}>
        <Navbar title={navbarTitle} callbackFuncIsLoading={navbarIsLoadingChange} icon={iconNavbar} />
        <div className="mainBox d-flex justify-content-center">
          <div className="mb-5 position-absolute pb-4 widthContentMainbox">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageTemplate;