import React, { useMemo, useState, useRef, forwardRef, useEffect } from "react";
import Cookies from "universal-cookie";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../Navbars/Sidebar/Sidebar";
import Navbar from "../../../Navbars/Navbar/Navbar";
import middleware from "../../../Api/Middleware";
import saleschannels from "../../../Api/SalesChannels";
import "../css/AddProductPages.css";
import "../../../Main/Main.css";
import "../../product.css";
import PageTemplate from "../../../Templates/PageTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPenToSquare, faCaretLeft, faGear,} from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../LoadingScreen/LoadingScreen";
import TablePreset from "../../../Table/TablePreset";
import ToastError from "../../../Toasts/ToastError";
import ToastSuccess from "../../../Toasts/ToastSuccess";
import ToastWarning from "../../../Toasts/ToastWarning";
import useUpdateEffect from "./useUpdateEffect";
// Media
import '../css/UploadArea.css'
import LoadingScreen from "../../../LoadingScreen/LoadingScreen";
import e from "cors";
// boxes
import FirstBox from "./edit_components/boxfirst";
import SecondBox from "./edit_components/boxsecond";
import ThirdBox from "./edit_components/boxthird";
import FourthBox from "./edit_components/boxfourth";


const EditPage = () => {

  // variables

  const { product_id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [showBrandModal, setShowBrandModal] = useState(true);
  const [selectedOption, setSelectedOption] = useState(2);
  const [taxSelect, setTaxSelect] = useState(0);
  const [product, setProduct] = useState([{}]);
  const [barcode, setBarcode] = useState([{}]);
  const [brands, setBrands] = useState([{}]);
  const [prices, setPrices] = useState([{}]);
  const [stock, setStock] = useState([{}]);
  const [suppliers, setSuppliers] = useState([{}]);
  const [businesses, setBusinesses] = useState([{}]);
  const [attributeConnections, setAttributeConnections] = useState([{}]);
  const [updatedProduct, setUpdatedProduct] = useState(false);
  const [updatedBarcode, setUpdatedBarcode] = useState(false);
  const [updatedBrands, setUpdatedBrands] = useState(false);
  const [updatedPrices, setUpdatedPrices] = useState(false);
  const [updatedStock, setUpdatedStock] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);

  // variables end

  //functions

  const fetchAll = async () => {
    const [fetchBrands, fetchProduct,fetchStock,fetchPrices, fetchBarcode, fetchAttributeConns, fetchSuppliers, fetchBusinesses] = await Promise.all([
      middleware.get(`products/brands`),
      middleware.get(`products?product_id=${product_id}`),
      middleware.get(`products/stock?product_id=${product_id}`),
      middleware.get(`products/prices?product_id=${product_id}`),
      middleware.get(`products/barcodes?product_id=${product_id}`),
      middleware.get(`products/attributes/connection?product_id=${product_id}`),
      middleware.get(`suppliers`),
      middleware.get(`businesses`),
    ])
    setProduct(fetchProduct.data)
    setBarcode(...fetchBarcode.data)
    setBrands(fetchBrands.data)
    setPrices(fetchPrices.data)
    setStock(...fetchStock.data)
    setSuppliers(fetchSuppliers.data)
    setBusinesses(fetchBusinesses.data)
    setAttributeConnections(...fetchAttributeConns.data)
    setFirstLoad(true)
    setLoading(false)
  }

  // updatebutton function

  const UpdateAll = (e) => {
    e.preventDefault()
    if(updatedBarcode == false && updatedProduct == false && updatedBrands == false && updatedPrices == false) {
      ToastError('Nothing to save')
    } else {
      ToastSuccess('Updated the product')
      navigate('/producten');
    }
    console.log(updatedBarcode, updatedBrands, updatedPrices, updatedProduct);
  }

  // updatebutton function end
  const ShowPrice = (type, turned, fixed) => {
    var res = [];
    if(turned == undefined || turned == false) {
      res = prices.filter(filter => filter.type == type).map(map => map[taxSelect == 0 ? "incl_tax" : "excl_tax"])
    } else {
      res =  prices.filter(filter => filter.type == type).map(map => map[taxSelect == 0 ? "excl_tax" : "incl_tax"])
    }
    return fixed !== undefined || fixed == false ? 
    Number(res).toFixed(2) : 
    String(Number(res));
  };

  const UpdatePrice = (e, type, turned) => {
    const index = prices.findIndex(price => price.type == type) + 1
    if(index) {
      const updated = [...prices]
      updated[index-1][taxSelect == 0 ? "incl_tax" : "excl_tax"] = Number(e.target.value.replace(',', '.'))
      setPrices(updated)
    }
  }

  const ChangeStatus = async(newStatus) => {
    const change = await middleware.put('/products', {
      product_id: product_id,
      status: newStatus,
    });

  }
  
  //functions end
  //useEffects

  useEffect(()=>{
    fetchAll()
  }, [])

  //useEffects end

  if(loading) {
    return (
      <LoadingScreen/>
    )
  }

  return (
    <PageTemplate navbarTitle={'Warehouse HUB'}>
    <button className='buttonStyleLight fitContent d-flex text-nowrap' onClick={() => navigate("/producten")}><FontAwesomeIcon icon={faCaretLeft} className='me-2 pt-1'/>Alle producten</button>
      <form onSubmit={UpdateAll}>
        <div className="d-flex justify-content-between mx-5">
          <div className="d-flex gap-2">
            <h1>{product.product_name} bewerken</h1>
            {product.status == 0 ?
              <div className="btn btn-secondary m-2 fw-bolder"
              onClick={e => ChangeStatus(1)}>Deactiveer</div>
            :
              <div className="btn btn-success m-2 fw-bolder"
              onClick={e => ChangeStatus(0)}>Activeer</div>
            }
          </div>
            <div className=" saveButtonsBox fitContent">
              <button type="submit" className="btn buttonStyle saveButton px-4 saveProduct mb-3 ">
                Opslaan
              </button>
            </div>
        </div>
        <FirstBox 
        product={product}
        setProduct={setProduct}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        FontAwesomeIcon={FontAwesomeIcon}
        faPlus={faPlus}
        setShowBrandModal={setShowBrandModal}
        brands={brands}
        barcode={barcode}
        setBarcode={setBarcode}
        setUpdatedProduct={setUpdatedProduct}
        setUpdatedBarcode={setUpdatedBarcode}
        setUpdatedBrands={setUpdatedBrands}
        />
        <SecondBox
        taxSelect={taxSelect}
        setTaxSelect={setTaxSelect}
        ShowPrice={ShowPrice}
        UpdatePrice={UpdatePrice}
        prices={prices}
        setUpdatedPrices={setUpdatedPrices}
        />
        <ThirdBox
        stock={stock}
        setStock={setStock}
        setUpdatedStock={setUpdatedStock}
        />
        <FourthBox
        suppliers={suppliers}
        setSuppliers={setSuppliers}
        businesses={businesses}
        setBusinesses={setBusinesses}
        attributeConnections={attributeConnections}
        setAttributeConnections={setAttributeConnections}
        />
    
    </form>
    </PageTemplate>
  )
}

export default EditPage;