import React, { useMemo, useState } from 'react';
import TablePreset from '../../../Table/TablePreset';
import SortableHeader from '../../../Table/TableComponents/SortableHeader';
import { useTable } from 'react-table';
import NoteModal from '../CustomerModals/NoteModal';

const Notes = () => {
  const [pagination, setPagination] = useState({
    "page_size": 10,
    "page": 1,
    "max_items": 0,
    "begin_of_page": 0,
    "end_of_page": 0,
  });
  const [order, setOrder] = useState({orderBy: '', direction: ''});
  const [showNoteModal, setShowNoteModal] = useState(false);

  const testData = [
    {
      "note_id": "1",
      "noteTitle": "Notitie titel",
      "noteDescription": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla lacus risus, lacinia sed placerat at, pellentesque ut neque. Vivamus diam orci, eleifend aliquam placerat vel, pulvinar at sapien. Donec dolor eros, facilisis vitae orci sed, varius condimentum purus. Phasellus auctor est eu ultrices dignissim. Vestibulum venenatis fringilla ipsum non egestas. Donec sit amet arcu cursus, tristique urna vitae, pulvinar ligula. Proin eleifend consectetur neque vitae condimentum. Quisque sit amet eros.",
      "noteCreatedDate": "1 april 2023",
      "noteCreatedTime": "9:10",
      "noteOwner": "Medewerker",
    },
  ];
  
  const [data, setData] = useState(testData);
  const [selectedIds, setSelectedIds] = useState([]);

  const handleSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedIds(previous => [...previous, productId]);
    } else {
      setSelectedIds(previous => previous.filter(id => id !== productId));
    }
  };

  const closeModalNote = () => setShowNoteModal(false)
  
    // useEffect(() => {
    //   try {
    //     fetchAll();
    //   } catch (error) {
    //     console.warn(error)
    //   }
    // }, [pagination.page_size, pagination.page, order, order.direction]);
  
    // Columns for the table
    const columns = useMemo(
      () => [
        {
          Header: () => (
            <div className='fw-bold'>Titel</div>
          ),
          accessor: 'noteTitel',
          Cell: ({ row }) => {
            return (
              <div className="d-flex flex-column">
                <p className="mb-0 text-nowrap cursorPointer" onClick={() => setShowNoteModal(true)}  style={{color: "#009fe3"}}>{row.original.noteTitle}</p>
                {row.original.noteDescription?.length > 250 ?
                  <p className=''>{row.original.noteDescription?.slice(0, 250)}...</p>
                :
                  <p className=''>{row.original.noteDescription}</p>
                }
              </div>
            )
          }
        },
        {
          Header: () => (
            <div className='text-center fw-bold'>Datum</div>
          ),
          accessor: 'noteCreatedDate',
          Cell: ({ row }) => {
            return (
              <div className="text-center">
                <p className='mb-0'>{row.original.noteCreatedDate}</p>
                <p>{row.original.noteCreatedTime}</p>
              </div>
            )
          }
        },
        {
          Header: () => (
            <div className='text-center fw-bold'>Gemaakt door</div>
          ),
          accessor: 'noteOwner',
          Cell: ({ row }) => {
            return (
              <div className="text-center">
                <p className='mb-0'>{row.original.noteOwner}</p>
              </div>
            )
          }
        },
      ], [order.orderBy, order.direction, selectedIds, setSelectedIds, data]
    );
  
    // Table instance
    const tableInstance = useTable({ columns, data: data });

  return (
    <div className='my-5 px-4'>
      <NoteModal openNote={showNoteModal} closeNote={closeModalNote}/>
      <div>
        <form>
          <div className='d-flex flex-column mb-3'>
            <label className='mb-2 fw-medium'>Titel</label>
            <input className='standardInput'></input>
          </div>
          <div className='d-flex flex-column mb-3'>
            <label className='mb-2 fw-medium'>Notitie</label>
            <textarea cols={10} rows={10} className='standardInput p-3' style={{minHeight: "150px"}}></textarea>
          </div>
          <div className='d-flex justify-content-end'>
            <button className='btn lightBlueStandardButton'>Aanmaken</button>
          </div>
        </form>
      </div>
      <div className='notesTable'>
        <div className="my-3 tablesCustomerEdit h-100">
          <TablePreset
            data={[{'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'order_id'}]} 
            setPagination={setPagination} 
            handleSelection={handleSelection}
            noCheckbox={true}
          />
        </div>
      </div>
    </div>
  );
}

export default Notes;
