import React, { useMemo, useState, useEffect } from "react";
import Sidebar from "../../Navbars/Sidebar/Sidebar";
import Navbar from "../../Navbars/Navbar/Navbar";
import { useTable } from "react-table";
import Modal from "react-bootstrap/Modal";
import middleware from "../../Api/Middleware";
import "./Users.css";
import NavbarIcon from '../../Navbars/Sidebar/Icons/gear.png';
import { useNavigate, useLocation } from "react-router";
import "../../Main/Main.css";
import Loading from "../../LoadingScreen/LoadingScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faChevronRight,
  faChevronLeft,
  faMagnifyingGlass,
  faCaretUp,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "universal-cookie";
import PageTemplate from "../../Templates/PageTemplate";
import CheckboxHeader from "../../Table/TableComponents/CheckboxHeader";
import CheckboxCell from "../../Table/TableComponents/CheckboxCell";
import SortableHeader from "../../Table/TableComponents/SortableHeader";
import TablePreset from "../../Table/TablePreset";

const Users = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();
const [loading, setLoading] = useState(true); // useState for page loading
  const [loadingScreen, setLoadingScreen] = useState(true); // useState for the Loadingscreen
  const [users, setUsers] = useState([]); // useState for all products in the database
  const [barcodes, setBarcodes] = useState([]); // useState for all products in the database
  const [stock, setStock] = useState([]); // useState for the stock of every Product in the database
  const [pagination, setPagination] = useState({
    page_size: 10,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  }); // the useState for the pagination for the table
  const [order, setOrder] = useState({ orderBy: "", direction: "" }); // useState for ordering the columns in the table
  const [selectedProductIds, setSelectedProductIds] = useState([]); // usestate for the selected checkboxes
  const [fullyShownProducts, setFullyShownProducts] = useState([]); // usestate fpr the productname that u can fully show and shortend it
  const [showBlueprintModal, setShowBlueprintModal] = useState(false);
  const [searchBarInput, setSearchBarInput] = useState("");
  const [timerId, setTimerId] = useState(null);
  const [productsSearchengine, setProductsSearchengine] = useState("");
  const [barcodeSearchengine, setBarcodeSearchengine] = useState("");
  const [inputChangeInternalCode, setInputChangeInternalCode] = useState("");
  const [show, setShow] = useState(false);

  // fetched all the products and the stock an the colors from the database
  const fetchAll = async () => {
    try {
      setLoading(true);
      const [fetchUsers] = await Promise.all([middleware.get(
        `sp/usersTableAll?page=${pagination.page}&page_size=${pagination.page_size}&searchTerm=${productsSearchengine}&noSearch=[password,api_token,updated_at,created_at]`
      ),]);
      setUsers(fetchUsers.data.data); // sets the the data in the products usestate
      console.log(fetchUsers);
      setLoadingScreenFunc();
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
  };

  // fetches all the data when pagination.page_size, pagination.page, order or location.pathname changes
  useEffect(() => {
    fetchAll();
  }, [pagination.page_size, pagination.page, order, location.pathname]);

  //a useEffect that makes the search work
  useEffect(() => {
    try {
      if (productsSearchengine !== "") {
        const doIt = async () => {
          const [fetchUsers] = await Promise.all([middleware.get(`users`)]);
          setUsers(fetchUsers.data); // sets the the data in the products usestate
          setPagination({
            ...pagination,
            max_items: fetchUsers.data.total,
            begin_of_page: fetchUsers.data.from,
            end_of_page: fetchUsers.data.to,
          }); // sets the pagination usestate with the max items begin of the page and the end of the page
        };
        doIt();
      } else {
        const doIt = async () => {
          const [fetchUsers] = await Promise.all([
            middleware.get(`sp/usersTableAll?page=${pagination.page}&page_size=${pagination.page_size}&searchTerm=${productsSearchengine}&noSearch=[password,api_token,updated_at,created_at]`),
          ]);
          setUsers(fetchUsers.data.data);
          setPagination({
            ...pagination,
            max_items: fetchUsers.data.total,
            begin_of_page: fetchUsers.data.from,
            end_of_page: fetchUsers.data.to,
          }); // sets the pagination usestate with the max items begin of the page and the end of the page
        };
        doIt();
      }
    } catch (error) {
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
  }, [productsSearchengine]);

  // handles to close the de-activate modal
  const handleClose = () => setShow(false);

  //handles to show the de-activate modal
  const handleShow = () => setShow(true);

  // Define ref for your modals
  const handleModal1Open = () => setShowBlueprintModal(true);

  const closeBlueprintModal = () => {
    setShowBlueprintModal(false);
  };

  // sets loading on false an waits 0.5 sec to also set the Loadingscreen to false
  function setLoadingScreenFunc() {
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }

  // Handle checkbox selection
  const handleProductSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedProductIds([...selectedProductIds, productId]);
    } else {
      setSelectedProductIds(
        selectedProductIds.filter((id) => id !== productId)
      );
    }
  };

  // a function that delays
  function delayedFunction(Func) {
    setTimeout(() => {
      Func();
    }, 500);
  }

  // a function that creates a new modal and sets the state to show



  const columns = useMemo(
    () => [
        {
          Header: (
            <CheckboxHeader currentItem={users} selectedIds={selectedProductIds} setSelectedIds={setSelectedProductIds} pk={'user_id'}>
            </CheckboxHeader>
          ),
          accessor: "select",
          Cell: ({ row }) => {
            return <CheckboxCell row={row} handleSelection={handleProductSelection} selectedIds={selectedProductIds} pk={'user_id'}/>
          }
        },
        {
          Header: () => (
            <SortableHeader currentItem={order} setItem={setOrder} field="first_name">
              Voornaam
            </SortableHeader>
          ),
          accessor: 'first_name',
          Cell: ({ row }) => {
            return <a href={`/algemene/instellingen/gebruikers/bewerken/${row.original.user_id}`} className="text-info text-decoration-none ">{row.original.first_name}</a>
          }
        },
        {
          Header: () => (
            <SortableHeader currentItem={order} setItem={setOrder} field="last_name">
              Achternaam
            </SortableHeader>
          ),
          accessor: 'last_name',
          Cell: ({ row }) => {
            return row.original.last_name
          }
        },
        {
          Header: () => (
            <SortableHeader currentItem={order} setItem={setOrder} field="email">
              Email
            </SortableHeader>
          ),
          accessor: 'email',
          Cell: ({ row }) => {
            return row.original.email
          }
        },
    ],
    [order.orderBy, order.direction, selectedProductIds, setSelectedProductIds, users]
  );

  //   Table instance
  const tableInstance = useTable({ columns, data: users });

  

  const searchDelay = async (value) => {
    // const value = await e.target.value
    setSearchBarInput(value)
    if (timerId) {
      clearTimeout(timerId);
    }
    const newTimerId = setTimeout(() => {
      setProductsSearchengine(value);
    }, 500);

    setTimerId(newTimerId);
  };

  const suspend = async () => {
    // Iterate over each product id in the selectedProductIds array
    selectedProductIds.map(async (data) => {
      // For each product id, send a PUT request to the 'products' endpoint
      // to update the status of the product to 1 (this might signify that the product is suspended)
      await middleware.put("products", {
        product_id: data,
        status: 1,
      });
    });

    // After updating all products, call fetchAll function to get updated list of products
    await fetchAll();

    // Reload the page to show the updated product statuses to the user
    window.location.reload();
  };

  // handles when and wich buton is clicked
  const Action = (methode) => {
    if (methode == 'select-all') {
      setSelectedProductIds([...selectedProductIds, ...users.filter(cons => !selectedProductIds.includes(cons.user_id)).map(e => e.user_id)])
    }
    else if (methode == 'unselect-all') {
      setSelectedProductIds([])
    }
    else  if (methode === "suspend") {
      handleShow();
    }
  }

  // if (loading) {
  //   return (
  //     <div
  //       className={`loading-container ${
  //         loadingScreen ? "fade-in" : "fade-out"
  //       }`}
  //     >
  //       <Loading />
  //     </div>
  //   );
  // }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="bg-white">
          <Modal.Title>Let op!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
          U gaat {selectedProductIds.length}{" "}
          {selectedProductIds.length === 1 ? "item" : "items"} de-activeren.
          Weet U dat zeker?
        </Modal.Body>
        <Modal.Footer className="bg-white">
          <button className="btn bg-danger text-white" onClick={handleClose}>
            Annuleren
          </button>
          <button
            className="btn bg-success text-white"
            onClick={() => {
              handleClose();
              delayedFunction(suspend);
              setSelectedProductIds([]);
            }}
          >
            Ik weet het zeker
          </button>
        </Modal.Footer>
      </Modal>
      <PageTemplate navbarTitle={"Gebruikers"} iconNavbar={NavbarIcon}>
        <div className="w-100">
          <div className="mb-3">
            <button
              className="btn bg-white fw-semibold rounded-pill"
              onClick={() => navigate("/algemene/instellingen")}
            >
              {"< Algemene instellingen"}
            </button>
          </div>
          <div className="d-flex justify-content-between flex-column">
            <h3 className="mt-2 mb-3  ">
              {location.pathname == "/users" ? "" : "Alle"} Gebruikers
            </h3>
          </div>
          <div className="tableUsers">
            <TablePreset
              data={[{'selectedIds': selectedProductIds, 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'user_id'}]} 
              tableLoading={loading}
              setPagination={setPagination} 
              handleSelection={handleProductSelection} 
              searchBar={[{ shown: true, value: searchBarInput, function: searchDelay}]}
              actionOptions={[{title: "Deactiveer", function: () => Action("suspend")}, {title: "Selecteer alles", function: () => Action("select-all")}, {title: "De-selecteer alles", function: () => Action("unselect-all")}]} // actionOptions={[{title: "selecteer alles", function: () => console.log(true)}]}
              // leftButtons={[{title: "a", function: () => console.log('a')}, {title: "b", function: () => console.log('b')}]} 
              rightButtons={[{title: "Nieuwe Gebruiker", function: () => navigate("/algemene/instellingen/gebruikers/aanmaken")}]} // rightButtons={[{title: "c", function: () => console.log('c')}]}
            />
          </div>
        </div>
      </PageTemplate>
    </>
  );
};

export default Users;
