import React from 'react'

const FirstBox = (props) => {
  return (
    <>
      {/* First Box */}
      <div className="whiteBox p-5 m-5 mt-0 align-center">
        <div className="d-flex flex-column">
            <label className="inputTitle me-2">
              Productnaam
            </label>
          <input className="col-12 productInputWidth" value={props?.product?.product_name ?? ""} onChange={e => props.setProduct({...props.product, product_name: e.target.value})}/>
        </div>
        <div className="d-flex flex-row productNumSelectLists fitContent w-100">
          <div className="d-flex flex-column me-4 marginRight0">
            <div className="d-flex flex-nowrap">
              <label className="inputTitle me-2">
                Productnummer
              </label>
            </div>
            <input
              type="number"
              className="mb-4 productNumInputWidth webkitRemove"
              name="product_number"
              defaultValue={props?.product?.product_number ?? ""}
              onChange={(e) => props.setProduct({...props.product, product_number: e.target.value}) + props.setUpdatedProduct(true)}
              // disabled={!edit}
              required
            />
          </div>
          <div className="d-flex flex-column barcodeParent">
            <div className="d-flex flex-row justify-content-between">
              <label className="inputTitle me-2">
                Barcode
              </label>
              <select
                className="optionsList productOptions me-4"
                id="sel1"
                name="sellist1"
                value={props?.selectedOption ?? ""}
                onChange={e => props.setSelectedOption(e.target.value) + props.setUpdatedBarcode(true)}
              >
                <option value="0">
                  Aangepaste barcode
                </option>
                <option value="1">
                  Gelijk aan productnummer
                </option>
                <option value="2">
                  Automatisch gegenereerd
                </option>
              </select>
            </div>
            <div className="d-flex flex-column me-4 mt-0 marginRight0">
              {props.selectedOption === "0" && (
                <input
                  type="number"
                  className="mb-4 barcodeInputfield inputBarcode"
                  placeholder="Barcode (ISBN, UPS, GTIN, etc.)"
                  value={props?.barcode?.barcode ?? ""}
                  onChange={e => props.setBarcode(e.target.value) + props.setUpdatedBarcode(true)}
                />
              )}
              {props.selectedOption == "2" && (
                <input
                  type="number"
                  className="mb-4 barcodeInputfield inputBarcode"
                  placeholder="Barcode (ISBN, UPS, GTIN, etc.)"
                  value={props?.barcode ?? ""}
                  disabled
                />
              )}
              {props.selectedOption == "1" && (
                <input
                  type="number"
                  className="mb-4 barcodeInputfield inputBarcode"
                  placeholder="Barcode (ISBN, UPS, GTIN, etc.)"
                  value={props?.product?.product_number ?? ""}
                  disabled
                />
              )}
            </div>
          </div>
          <div className="d-flex flex-column me-4 marginRight0">
            <div className="d-flex flex-column me-4 marginRight0">
              <label className="inputTitle">
                Merk{" "}
                <props.FontAwesomeIcon
                  icon={props.faPlus}
                  color="#6a6a6a"
                  className="hover"
                  onClick={() => props.setShowBrandModal(true)}
                  data-bs-toggle="exampleModal"
                  data-bs-target="#exampleModal"
                />
              </label>
              <select
                className="optionsList mb-2 brandOptions form-select"
                id="sel1"
                name="sellist1"
                value={props?.product?.brand_id ?? ""}
                onChange={e => props.setProduct({...props.product, brand_id: e.target.value}) + props.setUpdatedProduct(true)}
              >
                <option hidden></option>
                  {props.brands.map(brands => {
                    return <option key={brands.brand_id} value={brands.brand_id}>{brands.name}</option>;
                  })}
              </select>
              </div>
            </div>
          </div>
        <div>
      </div>
    </div>
    {/* First Box end */}
  </>
  )
}

export default FirstBox