import React from 'react';
import { useNavigate } from 'react-router';


const BoxesWarehouse = ({title, icon, link}) => {

  const navigate = useNavigate();

  return (
    <div className='btn bg-white boxStyle p-2 d-flex justify-content-center mx-3' onClick={() => navigate(link)}>
      <div className='d-flex flex-column justify-content-between flex-wrap'>
        <div className='h-50 d-flex justify-content-center'>
          <img src={icon} className='img-fluid '/>
        </div>
        <h4 className='d-flex mb-0 mt-2 mb-4 justify-content-center text-secondary'>{title}</h4>
      </div>
    </div>
  );
}

export default BoxesWarehouse;
