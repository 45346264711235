import axios from "axios";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";

// I made this so you only have to add the endpoint for each request and that you dont have to always get the token.
// How to use it:
// Just do middleware.method('endpoint') its almost the same as axios but you dont have to put the baseUrl in every request.

const cookies = new Cookies();

const middleware = axios.create({
  baseURL: 'https://api-middleware.dev.techdogcloud.com/',
});

// Request interceptor to add the ApiKey header
middleware.interceptors.request.use(config => {
  const token = cookies.get('token');
  if (token) {
    config.headers.ApiKey = token; // Adds the header to the request if it exists.
  }
  return config;
}, error => {
  // Do something with request error
  return Promise.reject(error);
});

middleware.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response && error.response.status === 401) {
    // Dispatch a custom event
    window.dispatchEvent(new CustomEvent('unauthorized-event'));
  }
  return Promise.reject(error);
});

export default middleware;