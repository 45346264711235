import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import middleware from "../../../Api/Middleware";
import salesChannels from "../../../Api/SalesChannels";
import TablePreset from "../../../Table/TablePreset";
import "../../../Table/Table.css";
import "../../../Orders/Orders.css";
import { useNavigate, useParams } from "react-router";
import { format } from 'date-fns'
import { nl } from 'date-fns/locale';
import TDSTextNavigation from "../../../Templates/TDSTextNavigation";
import CheckboxHeader from "../../../Table/TableComponents/CheckboxHeader";
import CheckboxCell from "../../../Table/TableComponents/CheckboxCell";
import SortableHeader from "../../../Table/TableComponents/SortableHeader";

const OrdersTab = () => {
  const navigate = useNavigate();
const [loading, setLoading] = useState(true);

  const [consumers, setConsumers] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [salesChannel, setSalesChannel] = useState([]);
  const [orderIds, setOrderIds] = useState([]);
  const [pagination, setPagination] = useState({
    "page_size": 10,
    "page": 1,
    "max_items": 0,
    "begin_of_page": 0,
    "end_of_page": 0,
  });
  const [order, setOrder] = useState({orderBy: '', direction: ''});
  const [searchBar, setSearchBar] = useState("");
  const { consumer_id } = useParams();

//   const fetchAll = async () => {
//     setLoading(true);
//     const consumers_id = consumer_id.replace(':', '');
//     try {
//         const [ConsumerEdit] = await Promise.all([
//           middleware.get(`consumers?internal_id=${consumers_id}`),
//         ]);
//         const internalId = ConsumerEdit.data.internal_id;
//         const [ordersResponse, salesChannelsResponse, businessResponse] = await Promise.all([
//             middleware.get(`orders?page=${pagination.page}&internal_id=${internalId}&page_size=${pagination.page_size}${order.orderBy !== '' && order.direction !== '' ? `&orderBy=${order.orderBy}&direction=${order.direction}` : ''}`),
//             salesChannels.get(`saleschannels`),
//             middleware.get(`businesses`),
//         ]);

//         setData(ordersResponse.data.data);
//         setBusinesses(businessResponse.data);
//         setSalesChannel(salesChannelsResponse.data);
//         setOrderIds(ordersResponse.data.data.map(data => data.order_id));
//         setPagination({...pagination, "max_items": ordersResponse.data.total, "begin_of_page": ordersResponse.data.from, "end_of_page": ordersResponse.data.to});
//     } catch (error) {
//         // Handle errors here
//         console.error('Error fetching data:', error);
//     }
//     setLoading(false);
// };


const testData = [
  {
    "order_id": "001",
    "order_number": "ORD1001",
    "created_at": "2023-05-09T14:22:00Z",
    "internal_id": "C001",
    "sales_channel_id": "SC001",
    "order_status": 2,
    "payment_status": 1,
    "delivery_status": 0,
    "total_price_incl_tax": 299.99,
    "sale_amount": 20.00
  },
  {
    "order_id": "002",
    "order_number": "ORD1002",
    "created_at": "2023-05-10T09:15:00Z",
    "internal_id": "C002",
    "sales_channel_id": "SC002",
    "order_status": 3,
    "payment_status": 3,
    "delivery_status": 2,
    "total_price_incl_tax": 450.50,
    "sale_amount": 0.00
  },
  {
    "order_id": "003",
    "order_number": "ORD1003",
    "created_at": "2023-05-08T11:00:00Z",
    "internal_id": "B001",
    "sales_channel_id": "SC001",
    "order_status": 1,
    "payment_status": 0,
    "delivery_status": 1,
    "total_price_incl_tax": 120.75,
    "sale_amount": 5.00
  },
  {
    "order_id": "004",
    "order_number": "ORD1004",
    "created_at": "2023-05-07T16:42:00Z",
    "internal_id": "B002",
    "sales_channel_id": "SC003",
    "order_status": 0,
    "payment_status": 2,
    "delivery_status": 0,
    "total_price_incl_tax": 788.20,
    "sale_amount": 50.00
  }
];

const [data, setData] = useState(testData);



  // useEffect(() => {
  //   try {
  //     fetchAll();
  //   } catch (error) {
  //     console.warn(error)
  //   }
  // }, [pagination.page_size, pagination.page, order, order.direction]);

  const [selectedIds, setSelectedIds] = useState([]);

  // Handle checkbox selection
  const handleSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedIds(previous => [...previous, productId]);
    } else {
      setSelectedIds(previous => previous.filter(id => id !== productId));
    }
  };

  const GetOrderStatus = ({ orderStatus }) => {
    switch (orderStatus) {
      case 0:
        return <label className="text-danger">Ge-annuleerd</label>;
      case 1:
        return <label className="text-danger">Concept</label>;
      case 2:
        return <label className="text-info">Nieuw</label>;
      case 3:
        return <label className="text-success">Voltooid</label>;
    }
  }

  const GetPaymentStatus = ({ paymentStatus }) => {
    switch (paymentStatus) {
      case 0:
        return <label className="text-warning">Concept</label>
      case 1:
        return <label className="text-danger">Openstaand</label>
      case 2:
        return <label className="text-success">Deels betaald</label>
      case 3:
        return <label className="text-success">Volledig betaald</label>
    }
  }

  const GetDeliveryStatus = ({ deliveryStatus }) => {
    switch (deliveryStatus) {
      case 0:
        return <label className="text-danger">Niet verzonden</label>
      case 1:
        return <label className="text-info">Verzonden</label>
      case 2:
        return <label className="text-success">Aangekomen</label>
    }
  }

  // Columns for the table
  const columns = useMemo(
    () => [
      {
        Header: (
          <CheckboxHeader currentItem={data} selectedIds={selectedIds} setSelectedIds={setSelectedIds} pk={'order_id'}>
          </CheckboxHeader>
        ),
        accessor: "select",
        Cell: ({ row }) => {
          return <CheckboxCell row={row} handleSelection={handleSelection} selectedIds={selectedIds} pk={'order_id'}/>
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} pos={"start"} field="order_number">
            Bestelling
          </SortableHeader>
        ),
        accessor: 'order_number',
        Cell: ({ row }) => {
          const date = format(new Date(row.original.created_at), 'dd MMMM yyyy HH:mm', { locale: nl });

          return (
            <div className="d-flex flex-column">
              <TDSTextNavigation
                text={row.original.order_number}
                link={`/bestellingen/bewerken/${row.original.order_number}`}
                extraClassNames={'text-nowrap'}
              />

              <div>
                <label>{date}</label>
              </div>

              <div style={{fontSize: "12px"}}>{
                row.original.internal_id.charAt(0) == "C" ? (
                  consumers.filter(filter =>
                    filter.internal_id == row.original.internal_id
                  ).map((consumer, index) => (
                    <TDSTextNavigation
                      key={index}
                      text={(consumer.first_name || "") + ' ' + (consumer.last_name || "")}
                      link={`/klanten/bewerken/${consumer.consumer_id}`}
                    />
                  ))
                ) : (
                  businesses.filter(filter => (
                    filter.internal_id == row.original.internal_id
                  )).map((company, index) =>
                    <TDSTextNavigation
                      key={index}
                      text={company.company_name}
                      link={`/klanten/bewerken/${company.company_id}`}
                    />
                  )
                )}
              </div>
            </div>
          )
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} field="total_price_incl_tax">
            Prijs incl.
          </SortableHeader>
        ),
        accessor: 'total_price_incl_tax',
        Cell: ({ row }) => {
          return (
            <div className="d-flex flex-column text-center">
              <div>{showPrice(row.original.total_price_incl_tax - row.original.sale_amount)}</div>
            </div>
          );
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} field="sales_channel_id">
            Verkooplocatie
          </SortableHeader>
        ),
        accessor: 'sales_channel_id',
        Cell: ({ row }) => {
          return (
            // <div>{salesChannel.filter(filter => filter.sales_channel_id == row.original.sales_channel_id).map(map => map.sales_channel_name)}</div>
            <div className="text-center">Webshop</div>
          )
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} field="order_status">
            Status
          </SortableHeader>
        ),
        accessor: 'order_status',
        Cell: ({ row }) => {
          return <div><GetOrderStatus orderStatus={row.original.order_status} /></div>;
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} field="payment_status">
            Betaling
          </SortableHeader>
        ),
        accessor: 'payment_status',
        Cell: ({ row }) => {
          return (
            <div className="text-center"><GetPaymentStatus paymentStatus={row.original.payment_status} /></div>
          )
        }
      },
      {
        Header: () => (
          <SortableHeader currentItem={order} setItem={setOrder} field="delivery_status">
            Verzending
          </SortableHeader>
        ),
        accessor: 'delivery_status',
        Cell: ({ row }) => {
          return (
            <div className="text-center"><GetDeliveryStatus deliveryStatus={row.original.delivery_status} /></div>
          )
        }
      },
    ], [order.orderBy, order.direction, selectedIds, setSelectedIds, data]
  );

  // Table instance
  const tableInstance = useTable({ columns, data: data });
  
  const Action = (methode) => {
    if (methode == 'select all') {
      setSelectedIds(orderIds)
    }
    else if (methode == 'de-select all') {
      setSelectedIds([])
    }
  }

  const showPrice = price => {
    return `€ ${Number(price).toFixed(2).replace('.', ',').replace(',00', ',-')}`;
  }

  return (
    <div className="ordersOverviewTab h-100 mt-4">
      <div className="my-3 tablesCustomerEdit  h-100">
        <TablePreset 
          data={[{'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'order_id'}]} 
          setPagination={setPagination} 
          handleSelection={handleSelection} 
          searchBar={[{ shown: true, value: searchBar, function: setSearchBar}]}
          leftButtons={[{title: "Filteren", function: () => console.log('a')}]} // leftButtons={[{title: "a", function: () => console.log('a')}, {title: "b", function: () => console.log('b')}]} 
          rightButtons={[{title: "Nieuwe bestelling", function: () => navigate('/bestellingen/nieuw')}]} // rightButtons={[{title: "c", function: () => console.log('c')}]}
        />
      </div>
    </div>
  );
}

export default OrdersTab;