import React, { useState } from 'react';
import PageTemplate from '../../Templates/PageTemplate';
import TextEditor from '../../Main/TextEditor';
import { useNavigate } from 'react-router';

const CatEdit = () => {
  const [editorContentSummary, setEditorContentSummary] = useState('');
  const navigate = useNavigate();
  const [editorContentDescription, setEditorContentDescription] = useState('');


  return (
    <PageTemplate navbarTitle={"Warehouse HUB"}>
      <div className='whiteBox w-100 px-5 py-4 fitcontentHeight' style={{minheight: '80vh'}}>
        <div className='d-flex flex-row justify-content-between pb-3 mb-3 borderBottomGray'>
          <h3 className='mb-0 fw-bold'>Categorieën aanpassen</h3>
          <div>
            <button className=' me-3 lightDarkGrayStandardButton px-4' onClick={() => navigate(-1)}>Annuleren</button>
            <button className=' lightBlueStandardButton px-4'>Opslaan</button>
          </div>
        </div>
        <div className='borderBottomGray'>
          <div className='d-flex flex-row w-100 mb-3'>
            <div className='d-flex flex-column w-50 pe-3'>
              <label className='mb-2 fw-semibold'>Naam</label>
              <input className='standardInput' />
            </div>
            <div className='d-flex flex-column w-50'>
              <label className='mb-2 fw-semibold'>Slug</label>
              <input className='standardInput' />
            </div>
          </div>
          <div className='mb-3'>
            <label  className='mb-2 fw-semibold'>Hoofdcategorie</label>
            <select className='standardInput standardDropdownArrow w-100'>
            </select>
          </div>
          <div className='mb-3'>
            <label className='mb-2 fw-semibold'>Samenvatting</label>
            <TextEditor onChange={(e) => setEditorContentSummary(e)}/>
          </div>
          <div className='mb-3'>
            <label className='mb-2 fw-semibold'>Omschrijving</label>
            <TextEditor onChange={(e) => setEditorContentDescription(e)}/>
          </div>
          <div className='mb-5'>
            <label className='mb-2 fw-semibold'>Afbeelding uploaden</label>
            <div className='uploaderStyle'>
                <h5>Sleep of selecteer uw media-bestanden om te uploaden</h5>
            </div>
          </div>
        </div>
        <div className='mt-4 borderBottomGray'>
          <h3 className=' fw-semibold mb-3'>SEO</h3>
          <div className='d-flex flex-column mb-3'>
            <label className='mb-2 fw-semibold'>SEO titel</label>
              <input className='standardInput' />
          </div>
          <div className='d-flex flex-column mb-3'>
            <label className='mb-0 fw-semibold'>SEO zoekwoorden</label>
            <small style={{color: "#c9c9c9"}} className='mb-2'>Vul een zoekwoord in en druk op enter om het zoekwoord toe te voegen.</small>
            <textarea className='standardInput p-2' style={{height: "125px"}} />
          </div>
          <div className='d-flex flex-column mb-4'>
            <label className='mb-2 fw-semibold'>SEO omschrijving</label>
            <textarea className='standardInput p-2' style={{height: "125px"}} />
          </div>
        </div>
        <div className="mt-4 d-flex flex-column">
          <label className="mb-2 fw-semibold">Gepubliceerd</label>
          <label className="switchNewCustomer">
            <input type="checkbox" />
            <span className="sliderNewCustomer round"></span>
          </label>
        </div>
      </div>
    </PageTemplate>
  );
}

export default CatEdit;
