import React, {useState, useRef, useEffect} from 'react';
import { useNavigate } from 'react-router';
import Cookies from 'universal-cookie';
import './Navbar.css';
import ProfilePic from './logoTechDogDark.png'
import Bell from "./IconsNavbar/Bell.svg"
import Mail from "./IconsNavbar/Message.svg"
import SearchIcon from "../../Customer/AddCustomers/Icons/Search.svg"
import Help from "./IconsNavbar/Help.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faArrowRightFromBracket, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import middleware from '../../Api/Middleware';


const Navbar = ({ title, callbackFuncIsLoading, icon }) => {
  const cookies = new Cookies();
  const navigate = useNavigate();

  const dropdownContentRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState('auto');
  const lineRef = useRef(null);

  const [user, setUser] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const fetchAll = async () => {
    const [fetchUserRes] = await Promise.all([
      middleware.get(`users?api_token=${cookies.get('token')}`)
    ])
    setUser(fetchUserRes.data[0])
  }

  useEffect(() => {
    fetchAll();
  }, []);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const logout = () => {
    cookies.remove('token', {path: '/'});
    navigate('/login');
  }

  useEffect(() => {
    //Get data from database
    callbackFuncIsLoading(false);
  }, []);

  useEffect(() => {
    if (dropdownContentRef.current) {
      const container = dropdownContentRef.current.previousSibling;
      setContainerWidth(`${container.offsetWidth}px`);
    }
  }, [showDropdown]);

  useEffect(() => {
    if (lineRef.current) {
      lineRef.current.style.width = containerWidth;
    }
  }, [containerWidth]);

  return (
    <nav className='navbar py-2 pe-5 ps-5 col-lg-10 d-flex flex-row flex-nowrap justify-content-between'>
      <div className=' d-flex flex-row justify-content-center'>
          <h3 className='mb-0 pageTitle text-nowrap fw-bold'>{title !== null ? title : ""}</h3>
      </div>
        <div className='iconHeightNav d-flex pt-1'>
          <div className='iconBox me-3 d-flex flex-row'>
              <div className={`d-flex flex-nowrap position-relative`}>
                <div className='d-flex align-self-center'>
                  <input type="text" placeholder="Zoeken..." name="search" className="searchbarNav bg-transparent border-0"></input>
                </div>
                <img src={SearchIcon} alt="Zoekicon" className='searchIcon me-5' />
              </div>
              <div className='indicatorIcons'>
                <img src={Help} className='iconsNav'/>
                <span className="dot mailIconPosistion">
                  <img src={Mail} className='iconsNav'/>
                </span>
                <img src={Bell} className='iconsNav'/>
              </div>
          </div>
        <div className={`dropdownNav ps-4 ${showDropdown ? 'clicked' : ''}`}>
          <div className='userInfoMenu hover d-flex flex-row' onClick={toggleDropdown}>
            <div className="profile-container d-flex align-items-center">
              <div className='profile-picture'>
                <img src={ProfilePic} />
              </div>
              <p className='fw-bold mb-4 text-nowrap userName'>
                {`Hi, ${user.first_name ? (user.first_name + ' ' + (user.last_name ? user.last_name : '')) : ('Gebruiker')}`}
              </p>
            </div>
            <FontAwesomeIcon className={`px-1 dropdownIndicator ${showDropdown ? 'rotate' : ''}`} icon={faChevronDown} />
          </div>
          <div className="hover-area" onClick={toggleDropdown}></div>
          <div className="dropdown-content" style={{ display: showDropdown ? 'flex' : 'none', width: containerWidth }} ref={dropdownContentRef}>
            <div className=' d-flex flex-column dropdownListedItems'>
            </div>
            <a href="#" className='link mt-5 pt-3'></a>
            <p className='line' ref={lineRef}></p>
            <div className='logOut d-flex flex-nowrap' onClick={logout}><FontAwesomeIcon icon={faArrowRightFromBracket} className='logOutIcon' />  <p className='text-black logOutText fw-semibold'>Log out</p></div>
          </div>
        </div>
      </div>
  </nav>
  );
}

export default Navbar;