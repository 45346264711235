import React, { useState, useEffect } from 'react';
import { useParams } from "react-router";
import middleware from "../../../Api/Middleware";
import ToastSuccess from "../../../Toasts/ToastSuccess";
import arrowCircleRight from "../Icons/Arrow-in-circle-right.svg";
import '../../../Main/Main.css'
import "../EditCustomers.css"

const Overzicht = ({NavigateTab, typeCustomer}) => {
  const { consumer_id } = useParams();
const [loading, setLoading] = useState(true);
  const [consumerData, setConsumerData] = useState([]);
  const [customerType, setCustomerType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [tellNum, setTellNum] = useState("");
  const [birthDate, setBirthDate] = useState();


  const fetchAll = async () => {
    setLoading(true);

    try {
      const [ConsumerEdit] = await Promise.all([
        middleware.get(`sp/customersTableAll?searchTerm=${consumer_id}`),
      ]);
      setConsumerData(ConsumerEdit.data[0])
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    try {
      fetchAll();

      let customerTypes = "";

      if (consumerData && typeof consumerData.internal_id === 'string') {
          customerTypes = consumerData.internal_id.split('-')[0];
      }
      
      if (customerTypes === 'C') {
          setCustomerType("Consumer");
      } else if (customerTypes === 'B') {
          setCustomerType("Company");
      }
    } catch (error) {
      console.warn(error)
    }
  }, [consumerData]);

  const updateCustomer = async (event) => {
    const consumers_id = consumer_id.replace(':', '');
    try {
      setLoading(true)
      event.preventDefault();
      await middleware.put(`consumers`, {
        consumer_id: consumers_id,
        first_name: firstName || consumerData.first_name,
        last_name: lastName || consumerData.last_name,
        email: email || consumerData.email,
        phone_number: tellNum || consumerData.phone_number,
        birthday: birthDate || consumerData.birthday,
      });

      setLoading(false);
      ToastSuccess('Wijzigingen opgeslagen');
    } catch (err) {
      console.error(err);
    }
  };


  return (
    <div className='d-flex flex-row col-12 justify-content-between HeightOverviewInfo'>
      <div className='widthOverviewSmallboxes'>
        <div className='whiteBox mb-4 generalInfoBox'>
          <h5 className='fw-bold mb-3'>Algemeen</h5>
          <div>
            <label className='labelGenerelInfoitems fw-medium'>Klantnummer</label>
            <div className='fw-medium mb-3'>C12548965</div>
            <label className='labelGenerelInfoitems fw-medium'>Relatiesoort</label>
            <div className='fw-medium mb-3'>Consument</div>
            <label className='labelGenerelInfoitems fw-medium'>Voornaam</label>
            <div className='fw-medium mb-3'>John</div>
            <label className='labelGenerelInfoitems fw-medium'>Achternaam</label>
            <div className='fw-medium mb-3'>Doe</div>
            <label className='labelGenerelInfoitems fw-medium'>E-mailadres</label>
            <div className='fw-medium mb-2'>john.doe@e-mail.com</div>
            <div className={`fw-medium mb-2 ${typeCustomer == "B" ? "d-none" : ""}`}>john.doe@e-mail.com</div>
            <div className={`fw-medium mb-2 ${typeCustomer == "B" ? "d-none" : ""}`}>john.doe@e-mail.com</div>
            <label className='labelGenerelInfoitems fw-medium'>Telefoonnummer</label>
            <div className='fw-medium mb-2'>+31 6 12 34 56 78</div>
            <div className={`fw-medium mb-2 ${typeCustomer == "B" ? "d-none" : ""}`}>+31 6 12 34 56 78</div>
            <div className={`fw-medium mb-2 ${typeCustomer == "B" ? "d-none" : ""}`}>+31 6 12 34 56 78</div>
            <label className='labelGenerelInfoitems fw-medium'>Geboortedatum</label>
            <div className='fw-medium'>01 januari 1990</div>
          </div>
        </div>
        <div className='whiteBox px-4 py-3 adressInfoBox'>
          <h5 className='fw-bold mb-3'>Adresgegevens</h5>
          {typeCustomer == "C" ? 
          <>
            <label className='noteTitle fw-medium'>Factuuradres</label>
            <div>
              <p className='mb-0 fw-semibold'>Appeltaartstraat 1</p>
              <p className='mb-0 fw-semibold'>1234 AB Appeldorp</p>
              <p className='mb-0 fw-semibold'>Nederland</p>
            </div>
          </>
          :
          <>
            <div className='mb-3'>
              <label  className='noteTitle fw-medium'>Factuuradres</label>
              <div>
                <p className='mb-0 fw-semibold'>Appeltaartstraat 1</p>
                <p className='mb-0 fw-semibold'>1234 AB Appeldorp</p>
                <p className='mb-0 fw-semibold'>Nederland</p>
              </div>
            </div>
            <div>
              <label className='noteTitle fw-medium'>Verzendadres</label>
              <div>
                <p className='mb-0 fw-semibold'>Appeltaartstraat 1</p>
                <p className='mb-0 fw-semibold'>1234 AB Appeldorp</p>
                <p className='mb-0 fw-semibold'>Nederland</p>
              </div>
            </div>
          </>
          }
        </div>
      </div>
      <div className='widthOverviewLargeboxes'>
        <div className='whiteBox heightLargeboxesOverview'>
          <h5 className='fw-bold mb-3'>Bestellingen</h5>
          <div className='d-flex flex-row justify-content-start'>
            <div className=' widthLargeBoxitems'>
              <h1 className='textSizeLargeboxesOverview'>8</h1>
              <div className='d-flex flex-row cursorPointer' onClick={() => NavigateTab("tab4")}>
                <p className='mb-0 fw-semibold me-4 darkBlueLinkText'>Open bestellingen</p>
                <img className='arrowCircleRight align-self-center' src={arrowCircleRight}/>
              </div>
            </div>

            <div className=' widthLargeBoxitems'>
              <h1 className='textSizeLargeboxesOverview'>€ 1650,00</h1>
              <div className='d-flex flex-row cursorPointer' onClick={() => NavigateTab("tab4")}>
                <p className='mb-0 fw-semibold me-4 darkBlueLinkText'>Openstaand bedrag</p>
                <img className='arrowCircleRight align-self-center' src={arrowCircleRight}/>
              </div>
            </div>

            <div className=' widthLargeBoxitems'>
              <h1 className='textSizeLargeboxesOverview'>20</h1>
              <div className='d-flex flex-row cursorPointer' onClick={() => NavigateTab("tab4")}>
                <p className='mb-0 fw-semibold me-4 darkBlueLinkText'>Afgeronde bestellingen</p>
                <img className='arrowCircleRight align-self-center' src={arrowCircleRight}/>
              </div>
            </div>
          </div>
        </div>
        <div className='whiteBox heightLargeboxesOverview'>
          <h5 className='fw-bold mb-3'>Offertes</h5>
          <div className='d-flex flex-row justify-content-start'>
            <div className=' widthLargeBoxitems'>
              <h1 className='textSizeLargeboxesOverview'>2</h1>
              <div className='d-flex flex-row cursorPointer' onClick={() => NavigateTab("tab5")}>
                <p className='mb-0 fw-semibold me-4 darkBlueLinkText'>Openstaande offertes</p>
                <img className='arrowCircleRight align-self-center' src={arrowCircleRight}/>
              </div>
            </div>
            <div className=' widthLargeBoxitems'>
              <h1 className='textSizeLargeboxesOverview'>20</h1>
              <div className='d-flex flex-row cursorPointer' onClick={() => NavigateTab("tab5")}>
                <p className='mb-0 fw-semibold me-4 darkBlueLinkText'>Getekende offertes</p>
                <img className='arrowCircleRight align-self-center' src={arrowCircleRight}/>
              </div>
            </div>
          </div>
        </div>
        <div className='whiteBox heightLargeboxesOverview'>
          <h5 className='fw-bold mb-3'>Facturen</h5>
          <div className='d-flex flex-row justify-content-start'>
            <div className=' widthLargeBoxitems'>
              <h1 className='textSizeLargeboxesOverview'>5</h1>
              <div className='d-flex flex-row cursorPointer' onClick={() => NavigateTab("tab6")}>
                <p className='mb-0 fw-semibold me-4 darkBlueLinkText'>Open facturen</p>
                <img className='arrowCircleRight align-self-center' src={arrowCircleRight}/>
              </div>
            </div>
            <div className=' widthLargeBoxitems'>
              <h1 className='textSizeLargeboxesOverview'>€ 2950,00</h1>
              <div className='d-flex flex-row cursorPointer' onClick={() => NavigateTab("tab6")}>
                <p className='mb-0 fw-semibold me-4 darkBlueLinkText'>Openstaand bedrag</p>
                <img className='arrowCircleRight align-self-center' src={arrowCircleRight}/>
              </div>
            </div>
            <div className=' widthLargeBoxitems'>
              <h1 className='textSizeLargeboxesOverview'>€ 250,00</h1>
              <div className='d-flex flex-row cursorPointer' onClick={() => NavigateTab("tab6")}>
                <p className='mb-0 fw-semibold me-4 darkBlueLinkText'>Klaar om te facturen</p>
                <img className='arrowCircleRight align-self-center' src={arrowCircleRight}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overzicht;
