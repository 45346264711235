import { toast } from 'react-toastify';

const ToastWarning = (text) => {
  return (
    toast.warning(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      style: {
        top: '100px'
      }
    })
  )
}

export default ToastWarning;