import React, { useState, useEffect, useCallback, useRef } from "react";
import { Modal } from "react-bootstrap";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import middleware from "../../Api/Middleware";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Customer.css";
import InputComponent from "./InputComponent";
import ToastSuccess from "../../Toasts/ToastSuccess";
import ToastError from "../../Toasts/ToastError";

const AddCustomerModal = ({ show, close }) => {
  const [showCustomer, setShowCustomer] = useState(false);
  const [countryInfo, setCountryInfo] = useState({});
  const [countryIdInfo, setCountryIdInfo] = useState([]);
  const [prefixId, setPrefixId] = useState();
  const [formData, setFormData] = useState({});
  const formRef = useRef(null); // Step 1: Create a ref for the form

  const normalizeLabel = (label) => {
    // Verwijder spaties aan het begin en einde, verwijder alle tussenliggende spaties en streepjes
    return label.trim().replace(/[\s\-]+/g, "");
  };

  const getValueInput = useCallback((value, label) => {
    // Normaliseer het label om consistentie te waarborgen
    const normalizedLabel = normalizeLabel(label);

    setFormData((prev) => ({ ...prev, [normalizedLabel]: value }));
  }, []);

  // const fetchCountry = async () => {
  //   const [fetchCountries] = await Promise.all([
  //     middleware.get(`/countryCodes`),
  //   ]);
  //   setCountryInfo(fetchCountries.data);
  // };

  // useEffect(() => {
  //   try {
  //     fetchCountry();
  //   } catch (error) {
  //     console.warn(error);
  //   }
  // }, [showCustomer]);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Voorkom de standaardactie van het formulier

    let consumerCreated = false; // Houd bij of het bedrijf succesvol is aangemaakt
    let consumerId = null; // Sla het ID van het aangemaakte bedrijf op voor mogelijke compensatieactie

    try {
      // Eerst controleren of het e-mailadres al bestaat
      const emailResponse = await middleware.get(
        `consumers?email=${encodeURIComponent(formData.Emailadres)}`
      );
      const existingConsumer = emailResponse.data;

      if (existingConsumer && existingConsumer.length > 0) {
        ToastError("E-mailadres bestaat al.");
        return; // Vroegtijdige terugkeer om de uitvoering te stoppen
      }

      // Maak een nieuwe internal code aan
      const internalCodeResponse = await middleware.post("/internalcodes", {
        internal_id: "C",
      });
      const internalCodeId = internalCodeResponse.data.internal_id;

      // Maak een nieuw bedrijf aan met de nieuwe internal code
      const consumersResponse = await middleware.post("/consumers", {
        internal_id: internalCodeId,
        prefix: prefixId,
        first_name: formData.Voornaam,
        last_name: formData.Achternaam || null,
        email: formData.Emailadres || null,
        phone_number: formData.Telefoonnummer || null,
        birthday: formData.Geboortedatum || null,
        language: countryIdInfo.country_code_2,
        fax_number: formData.Faxnummer || null,
        permanent_sale: parseInt(formData.Permanetekorting, 10),
        loyalty_points: 0,
        status: 0,
      });

      consumerCreated = true; // Update de status naar succesvol aangemaakt
      consumerId = consumersResponse.data.id; // Verondersteld dat je API het ID van het aangemaakte bedrijf teruggeeft

      // Probeer het adres toe te voegen
      await middleware.post("/addresses", {
        internal_id: internalCodeId,
        country_code_id: countryIdInfo.country_code_id || null,
        city: formData.Stad,
        streetname: formData.Straat,
        housenumber: formData.Huisnummer,
        addition: formData.Toevoeging === "" ? null : formData.Toevoeging,
        postalcode: formData.Postcode,
        address_kind: 2,
      });

      ToastSuccess("Bedrijf is aangemaakt");
      window.location.reload();
    } catch (err) {
      if (consumerCreated) {
        // Als het bedrijf is aangemaakt maar het toevoegen van het adres faalt, voer dan een compensatieactie uit
        await middleware.delete(`/consumers/${consumerId}`);
        ToastError("Aanmaken van Consument mislukt");
      } else {
        ToastError("Kan consument niet aanmaken");
      }
      console.error(err);
    }
  };

  useEffect(() => {
    if (show) {
      setShowCustomer(true);
    }
  }, [show]);

  const closeCustomerModal = () => {
    setShowCustomer(false);
    close(true);
  };

  const handleFormSubmit = (e) => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { cancelable: true, bubbles: true })
      );
    }
  };

  return (
    <Modal
      id="modalBlueprint"
      show={showCustomer}
      onHide={() => closeCustomerModal()}
      
      size="xl"
      backdrop="static"
    >
      <Modal.Header className="modalTopCustomers justify-content-between">
        <div className="d-flex flex-row">
          <h3 className="ms-3 text-nowrap me-3">Consument aanmaken</h3>
          <select
            className="form-select inputFieldAddCustomer dropdownsNewCustomers"
            // onChange={(e) => setCountryIdInfo(JSON.parse(e.target.value))}
          >
            <option hidden>Kies een land</option>
            <option>Nederland</option>
            <option>België</option>
            {/* {countryInfo?.length > 0 && countryInfo.map(country => (
              <option key={country.country_code_id} value={JSON.stringify(country)}>
                {country.country_code_2}
              </option>
            ))} */}
          </select>
        </div>

        <button
          variant="transparent"
          className=" btn"
          onClick={() => closeCustomerModal()}
        >
          <FontAwesomeIcon icon={faXmark} className="closeModal text-dark" />
        </button>
      </Modal.Header>
      <Modal.Body className="modalCenterCustomers">
        <div className="contendModal w-100">
          <form className="col-12" ref={formRef} onSubmit={handleSubmit}>
            <div className="col-12 d-flex flex-row align-content-end mb-3">
              <InputComponent
                type={"text"}
                onValueChange={getValueInput}
                alignmentInputs={"justify-content-start"}
                parentCol={6}
                labelName={"Voornaam"}
                classnamesInput={"col-12 widthInputComponents px-3"}
              />
              <InputComponent
                type={"text"}
                alignmentInputs={"justify-content-end"}
                parentCol={6}
                onValueChange={getValueInput}
                labelName={"Achternaam"}
                classnamesInput={"col-12 widthInputComponents px-3"}
              />
            </div>
            <div className="col-12 d-flex flex-row align-content-end my-3 ">
              <InputComponent
                type={"email"}
                onValueChange={getValueInput}
                alignmentInputs={"justify-content-start"}
                parentCol={6}
                AddFunc={() => console.log("Email")}
                AddIcon={true}
                labelName={"E-mailadres"}
                classnamesInput={"col-12 widthInputComponents px-3"}
              />
              <InputComponent
                type={"tel"}
                alignmentInputs={"justify-content-end"}
                AddIcon={true}
                AddFunc={() => console.log("TelNum")}
                parentCol={6}
                onValueChange={getValueInput}
                labelName={"Telefoonnummer"}
                classnamesInput={"col-12 widthInputComponents px-3"}
              />
            </div>
            <div className="col-12 d-flex flex-row align-content-end my-3">
              <InputComponent
                type={"date"}
                onValueChange={getValueInput}
                parentCol={6}
                labelName={"Geboortedatum"}
                classnamesInput={"col-12 widthInputComponents diffDateIcon px-3"}
              />
              <div className="col-6 d-flex justify-content-start">
                <div className="col-4 d-flex flex-column">
                  <label className="mb-2 ms-5 fw-medium">Nieuwsbrief</label>
                  <label className="switchNewCustomer ms-4">
                    <input type="checkbox" />
                    <span className="sliderNewCustomer round"></span>
                  </label>
                </div>
                <div className=" col-4  d-flex flex-column">
                  <label className="mb-2 ms-5 fw-medium">Verjaardagactie</label>
                  <label className="switchNewCustomer ms-4">
                    <input type="checkbox" />
                    <span className="sliderNewCustomer round"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 d-flex flex-row align-content-end my-3">
              <InputComponent
                type={"text"}
                onValueChange={getValueInput}
                parentCol={6}
                labelName={"Postcode"}
                classnamesInput={"col-12 widthInputComponents px-3"}
              />
              <div className="col-6 d-flex justify-content-end">
                <div className="col-12 d-flex widthInputComponents flex-row justify-content-end">
                  <InputComponent
                    alignmentInputs={"justify-content-start"}
                    onValueChange={getValueInput}
                    type={"number"}
                    parentCol={6}
                    labelName={"Huisnummer"}
                    classnamesInput={"col-12 widthInputComponents px-3"}
                  />
                  <InputComponent
                    alignmentInputs={"justify-content-end"}
                    onValueChange={getValueInput}
                    type={"text"}
                    parentCol={6}
                    labelName={"Toevoeging"}
                    classnamesInput={"col-12 widthInputComponents px-3"}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 d-flex flex-row align-content-end my-3 ">
              <InputComponent
                type={"text"}
                onValueChange={getValueInput}
                alignmentInputs={"justify-content-start"}
                parentCol={6}
                labelName={"Straat"}
                classnamesInput={"col-12 widthInputComponents px-3"}
              />
              <InputComponent
                type={"text"}
                onValueChange={getValueInput}
                alignmentInputs={"justify-content-end"}
                parentCol={6}
                labelName={"Stad"}
                classnamesInput={"col-12 widthInputComponents px-3"}
              />
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer className="modalBottomCustomers">
        <button
          type="button" // It's important to specify type="button" to prevent default button behavior
          className="btn addButtonCustomer px-4 mb-4 rounded-pill"
          onClick={handleFormSubmit} // Use the new method for form submission
        >
          Consument aanmaken
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCustomerModal;
