import React, { useState, useEffect } from "react";
import PageTemplate from "../../Templates/PageTemplate";
import middleware from "../../Api/Middleware";
import { useNavigate, useParams } from "react-router";
import Cookies from "universal-cookie";
import NavbarIcon from "../../WarehouseSettings/Icons/categories.svg"
import "./CategoryEdit.css";
import CategoryList from "../../Products/subpages/sub_components/CategoryList";
import ToastError from "../../Toasts/ToastError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";

const CategoryEdit = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [editCat, setEditCat] = useState([]); // useState for all users in the database
  const { primary_key } = useParams();
  const [initialCategoriesNew, setInitialCategoriesNew] = useState([
    { id: 1, headId: null, name: "Categories" },
  ]);
  const [searchQueryNew, setSearchQueryNew] = useState("");
  const [checkedCategoryPrimaryId, setCheckedCategorPrimaryId] = useState();
  const [categoryKeysArray, setCategoryKeysArray] = useState();
  const [headCat, setHeadCat] = useState(false);
  const [newCategoryPlace, setNewCategoryPlace] = useState("");

  const organizeCategories = (categories, parentId = null) => {
    return categories
      .filter((category) => category.headId === parentId)
      .map((category) => {
        return {
          ...category,
          subcategories: organizeCategories(categories, category.id),
        };
      });
  };

  const [categoriesTreeNew, setCategoriesTreeNew] = useState(
    organizeCategories(initialCategoriesNew)
  );
  const [filteredCategoriesNew, setFilteredCategoriesNew] =
    useState(categoriesTreeNew);
  const [checkedCategory, setCheckedCategory] = useState({});
  const [defaultCheckedCategory, setDefaultCheckedCategory] = useState();
  const [pageIsLoading, setPageIsLoading] = useState(true);

  const fetchAll = async () => {
    try {
      setPageIsLoading(true);
      const [fetchCategory, fetchEditCat] = await Promise.all([
        middleware.get(`products/category?status=0`),
        middleware.get(`products/category?primary_key=${primary_key}`),
      ]);
      const cat = fetchCategory.data.map((map) => {
        return {
          primary_key: map.primary_key,
          id: map.category_id,
          headId: map.head_category_id,
          name: map.title,
        };
      });
      const editCat = fetchEditCat.data;
      setEditCat(editCat);
      const id = `${editCat.primary_key}-${editCat.category_id}-${editCat.head_category_id}`;
      setCheckedCategory({ [id]: id });
      setCategoryKeysArray(extractNumericId(id))
      setCheckedCategorPrimaryId(editCat.primary_key);
      setInitialCategoriesNew(cat);
      setPageIsLoading(false);
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
  };

  // fetches all the data when pagination.page_size, pagination.page, order or location.pathname changes
  useEffect(() => {
    fetchAll();
  }, []);

  useEffect(() => {
    setFilteredCategoriesNew(categoriesTreeNew);
  }, [categoriesTreeNew]);

  useEffect(() => {
    filterCategoryNew();
  }, [initialCategoriesNew]);

  useEffect(() => {
    filterCategoryNew();
}, [searchQueryNew]);


  useEffect(() => {
    const id = `${editCat.primary_key}-${editCat.category_id}-${editCat.head_category_id}`;
    if (checkedCategory[id] == id) {
      setDefaultCheckedCategory(true);
    } else {
      setDefaultCheckedCategory(false);
    }
  }, [checkedCategory]);

  const filterCategoryNew = () => {
    const query = searchQueryNew;
    if (!query) {
      setCategoriesTreeNew(organizeCategories(initialCategoriesNew));
      return;
    }
    setCategoriesTreeNew(
      filterCategoryRecursivelyNew(query, initialCategoriesNew)
    );
  };

  const filterCategoryRecursivelyNew = (query, categories) => {
    const lowerCaseQuery = query.toLowerCase();

    return categories
      .map((category) => {
        let categoryMatches = category.name
          .toLowerCase()
          .includes(lowerCaseQuery);
        let filteredSubcategories = [];
        let includedDueToChild = false;

        if (category.subcategories) {
          filteredSubcategories = filterCategoryRecursivelyNew(
            query,
            category.subcategories
          );
          includedDueToChild = filteredSubcategories.length > 0;
        }

        if (categoryMatches || includedDueToChild) {
          return {
            ...category,
            subcategories: filteredSubcategories,
            isIncludedDueToChild: !categoryMatches && includedDueToChild,
          };
        }

        return null;
      })
      .filter(Boolean);
};

  const extractNumericId = (complexId) => {
    const regex = /(\d+)-(\d+)-(\d+)$/;
    const match = complexId.match(regex);
    if (!match) {
      return null;
    }
    return { primary_key: match[1], category_id: match[2], headId: match[3] };
  };

  const toggle = async (id) => {
    // Extract only the numeric ID
    const keysArray = extractNumericId(id);
    setCheckedCategorPrimaryId(keysArray.primary_key);
    setCategoryKeysArray(keysArray);
    setNewCategoryPlace(id);
    if (id !== null) {
      if (checkedCategory[id]) {
        setCheckedCategorPrimaryId(null);
        setCheckedCategory({}); // Clear the state if already checked
      } else {
        if (
          checkedCategoryPrimaryId &&
          checkedCategoryPrimaryId !== keysArray.primary_key
        ) {
          if (editCat.category_id == keysArray.headId) {
            // Check to ensure the category is not the same as its sub-category
            ToastError(
              "Een categorie kan niet een subcategorie zijn van zichzelf",
              9999
            );
          }
        }
        setCheckedCategory({ [id]: id }); // Only the clicked item will be set to true
      }
    }
  };

  const submitUpdateCategory = async (event) => {
    try {
      if (event) {
        event.preventDefault();
      }
      if (editCat.category_id == categoryKeysArray.headId) {
        // Check to ensure the category is not the same as its sub-category
        ToastError(
          "Een categorie kan niet een subcategorie zijn van zichzelf",
          9999
        );
      } else {
        if (headCat) {
          await middleware.put(`products/category`, {
            primary_key: editCat.primary_key,
            head_category_id: null,
          });

          window.location.reload();
        } else {
          if (categoryKeysArray.category_id == editCat.category_id) {
            ToastError("Je kan niet de categorie onder zichzelf zetten");
          } else {
            await middleware.put(`products/category`, {
              primary_key: editCat.primary_key,
              head_category_id: categoryKeysArray.category_id,
            });
  
            window.location.reload();
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PageTemplate
      navbarTitle={"Categorie bewerken"}
      pageIsLoading={pageIsLoading}
      iconNavbar={NavbarIcon}
    >
      <form onSubmit={submitUpdateCategory} className="categorieEditResponsive">
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn bg-white fw-semibold rounded-pill mb-4"
            onClick={() => navigate("/warehouse/instellingen/categoriën")}
          >
            <FontAwesomeIcon icon={faCaretLeft} /> Categoriën
          </button>
          <button
            className="btn text-white fw-semibold rounded-pill mb-4"
            style={{ backgroundColor: "#79b893" }}
          >
            Opslaan
          </button>
        </div>

        <div className="whiteBox parentBoxEditCategorie">
          <div className="m-3">
            <h3>Categorie wijzigen</h3>
            <div className="m-3 my-5">
              <div className="d-flex col-12 border-bottom py-4">
                <h5 className="titlesEditUsers inputTitle col-3 ps-3">
                  Categorie titel
                </h5>
                <input
                  className="inputFieldEditUser mx-2 col-8 ps-2"
                  type="text"
                  placeholder="Titel"
                  defaultValue={editCat.title ?? ""}
                ></input>
              </div>
              <div className="d-flex col-12 border-bottom py-4">
                <h5 className="titlesEditUsers inputTitle col-3 ps-3">
                  Omschrijving
                </h5>
                <textarea
                  className="inputFieldEditUser mx-2 col-8 ps-2 textareaStyle"
                  type="text"
                  placeholder="Omschrijving"
                  defaultValue={editCat.description ?? ""}
                ></textarea>
              </div>
              <div className="d-flex col-12 border-bottom py-4">
                <h5 className="titlesEditUsers inputTitle col-3 ps-3">
                  Plek van categorie
                </h5>
                <div
                  className="categorieënBox bg-white col-8"
                  style={{ maxHeight: "300px" }}
                >
                  <div className="px-2 d-flex position-sticky py-2 inputFieldCategory">
                    <input
                      type="text"
                      value={searchQueryNew}
                      onChange={(e) => setSearchQueryNew(e.target.value)}
                      placeholder="Search categories"
                      className="w-100 searchInputCategory me-2"
                    />
                    <div className="mainCat">
                      <input 
                        type="checkbox"
                        checked={headCat}
                        onChange={(e) => setHeadCat(e.target.checked)}
                        className="checkboxMainCat me-2"
                      />
                      <label className='user-select-none text-nowrap labelMainCat'>Hoofd categorie</label>
                    </div>
                  </div>
                  <div className="categoryList">
                    <CategoryList
                      categories={filteredCategoriesNew}
                      checkedCategories={checkedCategory}
                      defaultCat={defaultCheckedCategory}
                      toggle={toggle}
                      textsub={`Nieuwe plek van ${editCat.title}`}
                      show={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </PageTemplate>
  );
};

export default CategoryEdit;
