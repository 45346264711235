import React, { useState, useEffect } from 'react';
import PageTemplate from '../../Templates/PageTemplate';
import { Tab, Tabs } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCakeCandles, faCaretLeft, faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import middleware from "../../Api/Middleware";
import '../../Main/Main.css';
import './EditCustomers.css';
import { useNavigate, useParams } from "react-router";
import NavbarIcon from "../user-solid.svg";
import Overview from './TabPages/Overview';
import AddressData from './TabPages/AddressData';
import OrdersTab from './TabPages/OrdersTab';
import Quotation from './TabPages/Quotation';
import Invoice from './TabPages/Invoice';
import Loyalty from './TabPages/Loyalty';
import Pin from './Icons/Pin.svg';
import Mail from './Icons/Mail.svg';
import Phone from './Icons/Telephone.svg';
import Cake from './Icons/Birthday-cake.svg';
import dayjs from 'dayjs';
import countries from 'i18n-iso-countries';
import GeneralInfoTab from './TabPages/GeneralInfoTab';
import Notes from './TabPages/Notes';
import NoteModal from './CustomerModals/NoteModal';
countries.registerLocale(require("i18n-iso-countries/langs/nl.json"));

const EditCustomers = () => {

const [loading, setLoading] = useState(true);
  const [key, setKey] = useState("tab1");
  const [activeTab, setActiveTab] = useState("Overzicht");
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [consumerData, setConsumerData] = useState({});
  const [consumerAddress, setConsumerAddress] = useState({});
  const [businesses, setBusinesses] = useState([]);
  const [countryId, setCountryId] = useState([]);
  const [customerType, setCustomerType] = useState("");
  const [contentTabs, setContentTabs] = useState();
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [typeOfCustomer, setTypeOfCustomer] = useState("C");
  const { internal_id } = useParams();

  const fetchAll = async () => {
    setLoading(true);

    try {
      const [ConsumerEdit] = await Promise.all([
        middleware.get(`sp/customersTableAll?searchTerm=${internal_id}`),
      ]);
      const internalId = ConsumerEdit.data.internal_id;
      setConsumerData(ConsumerEdit.data)
      const [ordersResponse, businessResponse, consumerAddress] = await Promise.all([
        middleware.get(`orders?internal_id=${internal_id}`),
        middleware.get(`businesses`),
        middleware.get(`addresses?internal_id=${internal_id}`)
      ]);

      setCountryId(consumerAddress.data[0].country_code_id || "");
      setConsumerAddress(consumerAddress.data[0]);
      setData(ordersResponse.data.data);
      setBusinesses(businessResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    try {
      fetchAll();

      let customerTypes = "";

      if (consumerData && typeof consumerData.internal_id === 'string') {
          customerTypes = consumerData.internal_id.split('-')[0];
      }
      
      if (customerTypes === 'C') {
          setCustomerType("Consumer");
      } else if (customerTypes === 'B') {
          setCustomerType("Company");
      }
    } catch (error) {
      console.warn(error);
    }
  }, [consumerData.internal_id]);

  const closeModalNote = () => setShowNoteModal(false)

  const navigateTabs = (e) => {
    const selectedTab = tabs.find(tab => tab.eventKey === e);
    if (selectedTab) {
      console.log('Selected Tab:', selectedTab);
      setKey(e);
      setActiveTab(selectedTab.navbarTitle);
    } else {
      console.log('Tab not found');
    }
  };

  const tabs = [
    {
      eventKey: "tab1",
      title: "Overzicht",
      navbarTitle: "Overzicht",
      content: <Overview NavigateTab={(e) => navigateTabs(e)} typeCustomer={typeOfCustomer} consumerData={consumerData} consumerAddress={consumerAddress} />,
    },
    {
      eventKey: "tab2",
      title: "Algemene gegevens",
      navbarTitle: "Algemene gegevens",
      content: <GeneralInfoTab typeCustomer={typeOfCustomer} consumerData={consumerData} />,
    },
    {
      eventKey: "tab3",
      title: "Adresgegevens",
      navbarTitle: "Adresgegevens",
      content: <AddressData typeCustomer={typeOfCustomer} consumerAddress={consumerAddress} />,
    },
    {
      eventKey: "tab4",
      title: "Bestellingen",
      navbarTitle: "Bestellingen",
      content: <OrdersTab typeCustomer={typeOfCustomer} data={data} />,
    },
    {
      eventKey: "tab5",
      title: "Offertes",
      navbarTitle: "Offertes",
      content: <Quotation typeCustomer={typeOfCustomer} data={data} />,
    },
    {
      eventKey: "tab6",
      title: "Facturen",
      navbarTitle: "Facturen",
      content: <Invoice typeCustomer={typeOfCustomer} data={data} />,
    },
    {
      eventKey: "tab7",
      title: "Loyaliteit",
      navbarTitle: "Loyaliteit",
      content: <Loyalty typeCustomer={typeOfCustomer} />,
    },
    {
      eventKey: "tab8",
      title: "Notities",
      navbarTitle: "Notities",
      content: <Notes typeCustomer={typeOfCustomer} />,
    },
  ];

  return (
    <PageTemplate navbarTitle={"Customer HUB"} pageIsLoading={loading} iconNavbar={NavbarIcon}>
      <NoteModal openNote={showNoteModal} closeNote={closeModalNote}/>
      <div className='parentBoxSize'>
        <div className='col-12 d-flex flex-row justify-content-between responsiveEditCustomers'>
          {activeTab === "Overzicht" ? 
            <div className='col-9'>
              <div className="tabContainer whiteBox px-4 tabsOverviewPadding ">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => {
                    setKey(k);
                    const tab = tabs.find((t) => t.eventKey === k);
                    setActiveTab(tab.navbarTitle);
                  }}
                >
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.eventKey}
                      eventKey={tab.eventKey}
                      title={
                        <span
                          className={`tab-title ${
                            key === tab.eventKey ? "active" : ""
                          } fw-semibold`}
                        >
                          {tab.title}
                        </span>
                      }
                      disabled={tab.disabled}
                    >
                      {activeTab !== "Overzicht" ? (
                        <div key={tab.eventKey} className="tabContent">{tab.content}</div>
                      ) : (
                        ""
                      )}
                    </Tab>
                  ))}
                </Tabs>
              </div>
              {activeTab === "Overzicht" &&
                tabs.map((tab) => (
                  
                  tab.title === "Overzicht" && (
                    <div key={tab.eventKey} className='mt-4'> {/* Ensure key is here and unique */}
                      <div className="tabContent fitcontentHeight">{tab.content}</div>
                    </div>
                  )
                ))
              }
            </div>
          :
            <div className="tabContainer whiteBox col-9 px-4 fitcontentHeight">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => {
                  setKey(k);
                  const tab = tabs.find((t) => t.eventKey === k);
                  setActiveTab(tab.navbarTitle);
                }}
              >
                {tabs.map((tab) => (
                  <Tab
                    key={tab.eventKey}
                    eventKey={tab.eventKey}
                    title={
                      <span
                        className={`tab-title ${
                          key === tab.eventKey ? "active" : ""
                        } fw-semibold`}
                      >
                        {tab.title}
                      </span>
                    }
                    disabled={tab.disabled}
                  >
                    <div key={tab.eventKey} className="tabContent h-100">{tab.content}</div>
                  </Tab>
                ))}
              </Tabs>
            </div>
          }
          {activeTab === "Overzicht" ? 
            <div className='convertToAndNotes'>
              <div className='convertToBoxOverview'>
                <button className={`btn convertToBusinessButton ${typeOfCustomer === "C" ? "" : "d-none"}`} onClick={() => setTypeOfCustomer("B")}>Omzetten naar bedrijf</button>
              </div>
              <div className='mt-4 whiteBox latestNotes '>
                <h5 className='fw-bold mb-3'>Laatste notities</h5>
                <div>
                  <h6 className='darkBlueLinkText'>01-03-2023</h6>
                  <p className='noteTitle fw-semibold cursorPointer' onClick={() => setShowNoteModal(true)}>Notitie title</p>
                  <p className='fw-semibold'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer malesuada consequat felis, a posuere libero. Mauris maximus ultricies est vitae accumsan. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Etiam ultrices mattis neque pellentesque facilisis. Quisque porttitor nisi ac cursus condimentum. Sed sed nunc turpis. Donec nec sodales augue, ut tempor nisl. Aenean auctor </p>
                </div>
              </div>
            </div>
          :
            <>
              {activeTab === "Notities" ? (
                <div className='ms-4'>
                  <div className='py-4 px-5 whiteBox fitcontentHeight' style={{paddingLeft: "25px"}}>
                    <div className='mb-4'>
                      <h3 className='mb-3'>Overzicht</h3>
                      <h5>John Doe</h5>
                    </div>
                    <div className='d-flex flex-column justify-content-between fitcontentHeight mb-2'>
                      <div className='d-flex flex-row my-3'>
                          <img className='text-white rounded-2 infoIcons' src={Pin} />
                          <p className='textInfoCustomer d-flex align-self-center fw-medium'>
                            Appeltaartstraat 1
                            1234 AB Appeldorp
                            Nederland
                          </p>
                      </div>
                      <div className='d-flex flex-row my-3'>
                          <img className='text-white rounded-2 infoIcons' src={Phone} />
                          <p className='textInfoCustomer d-flex align-self-center fw-medium'>+31 12345678</p>
                      </div>
                      <div className='d-flex flex-row my-3'>
                          <img className='text-white rounded-2 infoIcons' src={Mail} />
                          <p className='textInfoCustomer d-flex align-self-center fw-medium'>john.doe@email.nl</p>
                      </div>
                      <div className='d-flex flex-row my-3'>
                          <img className='text-white rounded-2 infoIcons' src={Cake} />
                          <p className='textInfoCustomer d-flex align-self-center fw-medium'>1 Januari 1990 (34)</p>
                      </div>
                    </div>
                  </div>
                  <div className='mt-4 whiteBox latestNotesQuotation mb-5'>
                    <h5 className='fw-bold mb-3'>Laatste notities</h5>
                    <div>
                      <h6 className='darkBlueLinkText'>01-03-2023</h6>
                      <p className='noteTitle fw-semibold cursorPointer' onClick={() => setShowNoteModal(true)}>Notitie title</p>
                      <p className='fw-semibold'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer malesuada consequat felis, a posuere libero. Mauris maximus ultricies est vitae accumsan. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Etiam ultrices mattis neque pellentesque facilisis. Quisque porttitor nisi ac cursus condimentum. Sed sed nunc turpis. Donec nec sodales augue, ut tempor nisl. Aenean auctor </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='col-2 py-4 px-5 customerInfoBox whiteBox fitcontentHeight' style={{paddingLeft: "25px"}}>
                  <div className='mb-4'>
                    <h3 className='mb-3'>Overzicht</h3>
                    <h5>John Doe</h5>
                  </div>
                  <div className='d-flex flex-column justify-content-between fitcontentHeight mb-2'>
                    <div className='d-flex flex-row my-3'>
                        <img className='text-white rounded-2 infoIcons' src={Pin} />
                        <p className='textInfoCustomer d-flex align-self-center fw-medium'>
                          Appeltaartstraat 1
                          1234 AB Appeldorp
                          Nederland
                        </p>
                    </div>
                    <div className='d-flex flex-row my-3'>
                        <img className='text-white rounded-2 infoIcons' src={Phone} />
                        <p className='textInfoCustomer d-flex align-self-center fw-medium'>+31 12345678</p>
                    </div>
                    <div className='d-flex flex-row my-3'>
                        <img className='text-white rounded-2 infoIcons' src={Mail} />
                        <p className='textInfoCustomer d-flex align-self-center fw-medium'>john.doe@email.nl</p>
                    </div>
                    <div className='d-flex flex-row my-3'>
                        <img className='text-white rounded-2 infoIcons' src={Cake} />
                        <p className='textInfoCustomer d-flex align-self-center fw-medium'>1 Januari 1990 (34)</p>
                    </div>
                  </div>
                </div>
              )}
            </>
          }
        </div>
      </div>
    </PageTemplate>
  );
}

export default EditCustomers;
