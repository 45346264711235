import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../Modals/ModalStyle.css";

const NewLabelModal = ({ openNewLabel, closeNewLabel }) => {
  const [showNewLabel, setShowNewLabel] = useState(false);

  useEffect(() => {
    if (openNewLabel) {
      setShowNewLabel(true)
    }
  }, [openNewLabel]);
  
  const closeNewLabelModal = () => {
    setShowNewLabel(false);
    closeNewLabel(true)
  }

  // const submitNewLabel = async (event) => {
  //   try {
  //     if (event) {
  //       event.preventDefault();
  //     }
  //     if (LabelName) 
  //     {
  //       await middleware.post("labels", {
          
  //       }).then(() => window.location.reload());      
        
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  
  return (
    <Modal
        id="modalNewLabel"
        show={showNewLabel}
        onHide={() => closeNewLabelModal()}
        centered
        size="xl"
        backdrop="static"
      >
        <Modal.Header className="modalTop justify-content-between">
          <h4 className='ms-3 fw-semibold'>Label toevoegen</h4>
          <button variant="transparent" className=' btn' onClick={() => closeNewLabelModal()}>
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <Modal.Body className="modalCenter">
          <div className="contendModal w-100 d-flex flex-column">
            <label className='fw-semibold mb-2'>Naam</label>
            <input className='standardInput'/>
          </div>
        </Modal.Body>
        <Modal.Footer className="modalBottom">
          <button
            type="sumbit"
            className="btn lightBlueStandardButton"
            onClick={() => {
              closeNewLabelModal()
            }}
          >
            opslaan
          </button>
        </Modal.Footer>
      </Modal>
  );
}

export default NewLabelModal;
