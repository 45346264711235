import React, { useState, useMemo, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./TableDrag.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronLeft,
  faChevronRight,
  faPen,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import middleware from "../../Api/Middleware";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router";

// TableDrag Component: Used for rendering draggable tables with the capability of pagination.
const TableDrag = ({
  dataParameter,
  tableLoading,
  pagination,
  handlePaginationChange,
  columns,
  primaryKey,
  urlEndPoint,
  editColor,
  openCorrectModal,
  closeCorrectModal,
  dataEditModalSize,
  openModal,
  searchTerm,
  closeEditModalSize,
  newItemTitle,
}) => {
  // State for keeping track of selected IDs
  const [selectedIds, setSelectedIds] = useState([]);
  const navigate = useNavigate();
  const [openEditSizeModal, setOpenEditSizeModal] = useState(false);
  const [dataEditSizes, setDataEditSizes] = useState(false);
  const [show, setShow] = useState(false);
  const [inputChangeInternalCode, setInputChangeInternalCode] = useState("");
  const [inputValue, setInputValue] = useState();
  const [timerId, setTimerId] = useState(null);
  const [searchengine, setSearchengine] = useState("");

  // State for holding the table data
  const [data, setData] = useState([]);

  const handleClose = () => setShow(false);


  useEffect(() => {
    dataEditModalSize([dataEditSizes, openEditSizeModal]);
  }, [openEditSizeModal]);

  useEffect(() => {
    if (!closeEditModalSize) {
      setOpenEditSizeModal(false);
    }
  }, [closeEditModalSize]);

  // Effect: Open the correct modal when closeCorrectModal is true
  useEffect(() => {
    if (closeCorrectModal) {
      openCorrectModal(false);
    }
  }, [closeCorrectModal, openCorrectModal]);

  // Effect: Sort data based on sorting_order and update the data state
  useEffect(() => {
    const sortedData = [...(dataParameter ?? [])].sort(
      (a, b) => a.sorting_order - b.sorting_order
    );
    setData(sortedData);
  }, [dataParameter]);

  // a function that delays something for 0,5sec
  function delayedFunction(Func) {
    setTimeout(() => {
      Func();
    }, 500);
  }

  // Handles the selection of IDs for color changes
  const handleSelection = (selectedId, isSelected) => {
    if (isSelected) {
      setSelectedIds((previous) => [...previous, selectedId]);
    } else {
      setSelectedIds((previous) => previous.filter((id) => id !== selectedId));
    }
  };

  const suspend = async () => {
    // Iterate over each product id in the selectedIds array
    let bulkData = [];
    selectedIds.map((selectedId) => {
      bulkData.push({
        primary_key: selectedId,
        status: 1,
        sorting_order: null,
      });
    });

    await middleware.put(urlEndPoint, {
      bulk_data: bulkData,
    });
  };

  const handleOpenContend = (data, openModal) => {
    if (urlEndPoint === "products/colors") {
      // Opens modal for editing color
      editColor(data);
      openCorrectModal(openModal);
    } else if (urlEndPoint === "products/sizes") {
      setOpenEditSizeModal(true);
      setDataEditSizes(data);
    } else if (urlEndPoint === "products/brands") {
      navigate(`/warehouse/instellingen/merken/bewerken/${data.brand_id}`)
    } else if (urlEndPoint === "products/category") {
      navigate(`/warehouse/instellingen/categoriën/bewerken/${data.primary_key}`)
    }
  };

  //handles to show the de-activate modal
  const handleShow = () => setShow(true);

  const select_all = () => {
    setSelectedIds(dataParameter.map((data) => data[primaryKey]));
  };

  // a function that unselects all the selects in the table
  const unselect_all = () => {
    setSelectedIds(dataParameter.map((data) => data[primaryKey]));
    setSelectedIds([]);
  };

  const searchDelay = async (value, setSearchEngine, setInput) => {
    // Set the input value (possibly reflecting user's typed value).
    setInput(value);
    // searchTerm(value);

    // If there's an existing timer (from a previous keypress or action),
    // clear it to prevent multiple unnecessary actions.
    if (timerId) {
      clearTimeout(timerId);
    }

    // Set a delay of 500ms (0.5 seconds) before initiating the search.
    // If user types or triggers the function again within this timeframe, the above clearTimeout will
    // prevent the search from executing until the 500ms delay has passed without interruption.
    const newTimerId = setTimeout(() => {
      // Reset the pagination to the first page.

      // Start the search with the given value.
      setSearchEngine(value);
    }, 500);

    // Store the timer ID, so it can be cleared if function is triggered again
    // before the 500ms delay has passed.
    setTimerId(newTimerId);
  };

  // Icons for drag and edit operations
  const DragIcon = <FontAwesomeIcon icon={faBars} />;
  const EditIcon = <FontAwesomeIcon icon={faPen} />;

  // Handles the drag end operation and sends a PUT request with reordered data
  const handleDragEnd = async (result) => {
    // If the dragged item was not dropped into a valid destination, we return
    if (!result.destination) return;

    // Copy the data to a new array called items
    const items = Array.from(data);

    // Take out the item that was dragged from its original position
    // "splice" returns an array of the removed items, so we grab the first item
    const [reorderedItem] = items.splice(result.source.index, 1);

    // Insert the dragged item into its new position
    items.splice(result.destination.index, 0, reorderedItem);

    // Update the sorting_order of each item in the list based on its new position
    const updatedItems = items.map((item, index) => ({
      ...item,
      // Calculate new sorting order based on its position
      // If the current page is the first one, sorting order starts from 1
      // Otherwise, it's adjusted based on page size and the current page number
      sorting_order:
        index +
        (pagination.page === 1
          ? 0
          : pagination.page_size * (pagination.page - 1)) +
        1,
    }));

    // Extract necessary information for the PUT request: primary key and sorting order
    const sortedItems = updatedItems.map((item) => ({
      [primaryKey]: item[primaryKey],
      sorting_order: item.sorting_order,
    }));

    // Send the reordered items to the server using a PUT request
    middleware.put(urlEndPoint, {
      bulk_data: sortedItems,
    });

    // Update the local state with the new order of items
    setData(updatedItems);
  };

  // handles the action that the user clicked in the actie  dropdown
  const handleAction = async (event) => {
    if (event === "suspend") {
      // shows an modal to converm that u want to suspend the selected colors
      handleShow();
    } else if (event === "select-all") {
      // selects all the colors
      select_all();
    } else if (event === "unselect-all") {
      // unselects all colors u selected
      unselect_all();
    }
  };

  // Generates columns for the table including special columns for drag, edit, and selection
  const generateColumnsFromData = (dataConfig) => {
    const dragIconColumn = {
      Header: "",
      accessor: "drag",
      Cell: () => <div className="hover">{DragIcon}</div>,
    };

    const EditIconColumn = {
      Header: "",
      accessor: "Edit",
      Cell: ({ row }) => (
        <div
          className="hover"
          onClick={() => handleOpenContend(row.original, true)}
        >
          {EditIcon}
        </div>
      ),
    };

    const selectionColumn = {
      Header: (
        <>
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={selectedIds.length > 0}
              onChange={(e) => {
                const checked = e.target.checked;
                setSelectedIds(
                  checked ? data.map((dataItem) => dataItem[primaryKey]) : []
                );
              }}
            />
            <span className="checkmark"></span>
          </label>
        </>
      ),
      accessor: "select",
      Cell: ({ row }) => {
        const isSelected = selectedIds.includes(row.original[primaryKey]);
        return (
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isSelected}
              onChange={(e) => {
                handleSelection(row.original[primaryKey], e.target.checked);
              }}
            />
            <span className="checkmark"></span>
          </label>
        );
      },
    };

    const columnsFromData = dataConfig.map((conf) => {
      return {
        Header: conf.title,
        accessor: conf.key,
        Cell: conf.customRenderer
          ? (props) => conf.customRenderer(props.row.original)
          : conf.maxLength
          ? ({ row }) => {
              const text = row.original[conf.key];
              return (
                <span>
                  {text?.length >= conf.maxLength ? (
                    <span>{text?.slice(0, conf.maxLength)}...</span>
                  ) : (
                    text
                  )}
                </span>
              );
            }
          : undefined,
      };
    });

    return [
      selectionColumn,
      ...columnsFromData,
      EditIconColumn,
      dragIconColumn,
    ];
  };

  const dataConfig = columns;

  const tableColumns = useMemo(() => {
    return generateColumnsFromData(dataConfig);
  }, [selectedIds, dataParameter.dataParameter, data]);


  // Render the draggable table along with the pagination controls
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="bg-white">
          <Modal.Title>Let op!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-white">
          U gaat {selectedIds.length}{" "}
          {selectedIds.length === 1 ? "item" : "items"} de-activeren. Weet U dat
          zeker?
        </Modal.Body>
        <Modal.Footer className="bg-white">
          <button className="btn bg-danger text-white" onClick={handleClose}>
            Annuleren
          </button>
          <button
            className="btn bg-success text-white"
            onClick={() => {
              handleClose();
              delayedFunction(suspend);
              setSelectedIds([]);
            }}
          >
            Ik weet het zeker
          </button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex align-center actionItems w-100 mb-3 flex-row justify-content-between">
        <div className="d-flex flex-row">
          <div className="d-flex flex-row justify-content-center">
            <select
              className="btn-custom form-select actionOptions"
              value={"actie"}
              onChange={(e) => handleAction(e.target.value)}
            >
              <option hidden disabled value={"actie"}>
                Actie
              </option>
              <option
                onClick={handleShow}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                value={"suspend"}
              >
                de-activeer {selectedIds.length}{" "}
                {selectedIds.length === 1 ? "product" : "producten"}
              </option>
              <option value={"select-all"}>
                selecteer alles van huidige pagina
              </option>
              <option value={"unselect-all"}>deselecteer alles</option>
            </select>
            <a className="btn-custom filterButton">Filteren</a>
          </div>
          <div className="tableSearchBarContainer ms-4">
            <input
              className="tableSearchBar w-100"
              type="text"
              placeholder="Zoeken..."
              value={inputChangeInternalCode}
              onChange={(e) =>
                setInputValue(e.target.value) +
                searchDelay(
                  e.target.value,
                  setSearchengine,
                  setInputChangeInternalCode
                )
                + searchTerm(e.target.value)
              }
            ></input>
            <FontAwesomeIcon className="" icon={faMagnifyingGlass} color="gray" />
          </div>
        </div>
        <button
          className="btn nieuwColor h-50 text-white"
          onClick={() => openModal(newItemTitle)}
        >
          Nieuwe {newItemTitle}
        </button>
      </div>

      { tableLoading ? 
          <div className="tableLoadingScreen flex-column">
              <div className="spinner-border text-white mb-3" role="status" style={{ height: '100px', width: '100px'}}/>
              <h3 style={{maxWidth: 'fit-content'}} className=" text-white mb-5">Laden...</h3>
          </div>
        :
          <div className="tableBackground">
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="table">
                {(provided) => (
                  <table
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="table-drag table"
                  >
                    <thead>
                      <tr>
                        {tableColumns.map((column) => (
                          <th key={column.accessor}>{column.Header}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.length > 0 &&
                        data.map((row, index) => (
                          <Draggable
                            key={row[primaryKey]}
                            draggableId={row[primaryKey].toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <tr
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  width: "100%",
                                  height: "auto",
                                  backgroundColor: "transparent",
                                }}
                              >
                                {tableColumns.map((column) => (
                                  <td key={column.accessor}>
                                    {column.Cell ? (
                                      <column.Cell row={{ original: row }} />
                                    ) : (
                                      row[column.accessor]
                                    )}
                                  </td>
                                ))}
                              </tr>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </tbody>
                  </table>
                )}
              </Droppable>
            </DragDropContext>
            <div className="bg-white d-flex justify-content-between ps-4 pe-5 py-1 w-100">
              <div className="d-flex gap-0 ">
                <select
                  value={pagination.page_size}
                  onChange={(e) =>
                    handlePaginationChange({
                      ...pagination,
                      page_size: e.target.value,
                      page: 1,
                    })
                  }
                  className="page-sizer form-select"
                >
                  {/* <option value={1}>1</option> */}
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={999999999999999}>All</option>
                </select>
                <button
                  disabled
                  className="btn rounded-0 border-0 text-nowrap page-display"
                >
                  {pagination.begin_of_page}-{pagination.end_of_page} van{" "}
                  {pagination.max_items}
                </button>
              </div>
              <div className="mb-2 d-flex gap-2">
                <button
                  onClick={(e) =>
                    handlePaginationChange({ ...pagination, page: 1 })
                  }
                  disabled={pagination.page === 1 ? true : false}
                  type="button"
                  className="btn rounded-2 border-0 text-nowrap"
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <button
                  onClick={(e) =>
                    handlePaginationChange({
                      ...pagination,
                      page: pagination.page - 1,
                    })
                  }
                  disabled={pagination.page <= 1 ? true : false}
                  type="button"
                  className="btn rounded-2 border-0"
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <button
                  onClick={(e) =>
                    handlePaginationChange({
                      ...pagination,
                      page: pagination.page - 2,
                    })
                  }
                  hidden={pagination.page <= 2 ? true : false}
                  type="button"
                  style={{
                    backgroundColor: "#e8e8e8",
                    fontWeight: "600",
                    color: "black",
                    cursor: "default",
                  }}
                  className="btn rounded-2 border-0 "
                >
                  {pagination.page - 2}
                </button>
                <button
                  onClick={(e) =>
                    handlePaginationChange({
                      ...pagination,
                      page: pagination.page - 1,
                    })
                  }
                  hidden={pagination.page <= 1 ? true : false}
                  type="button"
                  style={{
                    backgroundColor: "#e8e8e8",
                    fontWeight: "600",
                    color: "black",
                    cursor: "default",
                  }}
                  className="btn rounded-2 border-0 "
                >
                  {pagination.page - 1}
                </button>
                <button
                  type="button"
                  className="btn rounded-2 border-1 border-dark pageIndicator"
                >
                  {pagination.page}
                </button>
                <button
                  onClick={(e) =>
                    handlePaginationChange({
                      ...pagination,
                      page: pagination.page + 1,
                    })
                  }
                  hidden={
                    Math.ceil(pagination.max_items / pagination.page_size) <=
                    pagination.page
                      ? true
                      : false
                  }
                  type="button"
                  style={{
                    backgroundColor: "#e8e8e8",
                    fontWeight: "600",
                    color: "black",
                    cursor: "default",
                  }}
                  className="btn rounded-2 border-0 "
                >
                  {pagination.page + 1}
                </button>
                <button
                  onClick={(e) =>
                    handlePaginationChange({
                      ...pagination,
                      page: pagination.page + 2,
                    })
                  }
                  hidden={
                    Math.floor(pagination.max_items / pagination.page_size) <=
                    pagination.page + 1
                      ? true
                      : false
                  }
                  type="button"
                  style={{
                    backgroundColor: "#e8e8e8",
                    fontWeight: "600",
                    color: "black",
                    cursor: "default",
                  }}
                  className="btn rounded-2 border-0 "
                >
                  {pagination.page + 2}
                </button>
                <button
                  onClick={(e) =>
                    handlePaginationChange({
                      ...pagination,
                      page: pagination.page + 1,
                    })
                  }
                  disabled={
                    Math.ceil(pagination.max_items / pagination.page_size) ===
                      pagination.page || pagination.max_items <= 0
                      ? true
                      : false
                  }
                  type="button"
                  className="btn rounded-2 border-0"
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
                <button
                  onClick={(e) =>
                    Math.floor(pagination.max_items / pagination.page_size) === 0
                      ? handlePaginationChange({ ...pagination, page: 1 })
                      : handlePaginationChange({
                          ...pagination,
                          page: Math.ceil(
                            pagination.max_items / pagination.page_size
                          ),
                        })
                  }
                  disabled={
                    Math.ceil(pagination.max_items / pagination.page_size) ===
                      pagination.page || pagination.max_items <= 0
                      ? true
                      : false
                  }
                  type="button"
                  className="btn rounded-2 border-0 text-nowrap"
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </div>
            </div>
          </div>
        }
    </div>
  );
};

export default TableDrag;
