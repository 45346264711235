import React, { useState, useEffect } from 'react';
import Plus from "./Icons/Add.svg";

const InputComponent = ({labelName, type, placeholder, required, classnamesInput, parentCol, classnamesParent, alignmentInputs, onValueChange, max, min, AddIcon, AddFunc}) => {
  const [valueInput, setvalueInput] = useState("");

  const today = new Date().toISOString().split('T')[0];

  useEffect(() => {
    if (onValueChange) {
      onValueChange(valueInput, labelName);
    }
  }, [valueInput, onValueChange]);

  return (
    <div className={`d-flex flex-column col-${parentCol} ${classnamesParent || ""}`}>
      <div className={`d-flex flex-column`}>
        <div className={`d-flex w-100 ${alignmentInputs ?? "justify-content-start"}`}>
          <div className='ps-1 mb-2 widthInputComponents d-flex flex-row justify-content-between'>
            <label className='fw-medium '>{labelName}</label>
            {AddIcon ? 
              <img className='pe-3 addIconSizeModals' onClick={() => AddFunc()} src={Plus}/>
            :
              ""
            }
          </div>
        </div>
        <div className={`d-flex w-100 ${alignmentInputs ?? "justify-content-start"}`}>
          <input 
            className={`inputFieldAddCustomer ${classnamesInput}`}
            type={type}
            placeholder={placeholder}
            required={required}
            max={type == 'date' ? today : ""  || type == 'number' ? max : ""}
            min={min}
            onChange={(e) => setvalueInput(e.target.value)}
          />
        </div>
          
      </div>
    </div>
  );
}

export default InputComponent;
