import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useEffect } from 'react';



const DashboardBoxes = ({ titlebox, HubIcon, subtext, statusCode, numberOfApps, apps, InactiveText, InstallingText }) => {
  const [iconBgColor, setIconBgColor] = useState("");
  const [installationStatus, setInstallationStatus] = useState("");

  const active = "#79b893"
  const inactive = "#f14242"
  const installing = "#fc9303"

  useEffect(() => {
    switch (statusCode) {
      case 0:
        setIconBgColor(active)
        setInstallationStatus("Active")
        break;
      case 1:
        setIconBgColor(installing)
        setInstallationStatus("Installing")
        break;
      case 2:
        setIconBgColor(inactive)
        setInstallationStatus("Inactive")
        break;
      default:
        console.warn("Invalid status code Available codes: 1:Active, 2:Installing, 3:Inactive ");
        break;
    }
  }, [statusCode]);

  // const renderIcon = () => {
  //   // If IconSVG is a string, assume it's raw SVG markup
  //   if (typeof HubIcon === 'string') {
  //     return <div dangerouslySetInnerHTML={{ __html: HubIcon }} />;
  //   }
  //   return null;
  // };


  return (
    <div className="boxes d-flex">
      <div className="d-flex justify-content-between">
        <div className="hubBoxes text-white rounded-circle d-flex p-3" style={{backgroundColor: iconBgColor}}>
          <div className='DashBoxIconCenteren'>
            {/* {HubIcon ? renderIcon() : <img/>} */}
              <img
                key={1}
                src={HubIcon}
                alt="App Icon"
                width={40}
                height={40}
              />
          </div>
        </div>
        <div className="appstoreBox d-flex text-center justify-content-center">
          <p className=" d-flex text-wrap appstoreText">Appstore {numberOfApps}</p>
        </div>
      </div>
      <div className="boxText px-4">
        <h4>{titlebox}</h4>
        <p className="subTitle mb-2">
          {subtext}
        </p>
        <p className="fw-bold mb-2">{installationStatus}</p>
        <div className="col-12">
          <div className="d-flex justify-content-start mb-2 flex-wrap widthParentApps">
            <div>
              <div>
                {statusCode === 0 &&
                  <div>
                    {apps && apps[0].map((app, index) => {
                      return (
                        <img
                          key={index}
                          src={app.logo}
                          alt="App Icon"
                          className="iconActive p-1 me-3 imgStyleBoxes"
                        />
                      );
                    })}
                  </div>
                }
                {statusCode === 1 &&
                  <p className="subTitle">{InstallingText}</p>
                }
                {statusCode === 2 &&
                  <p className="subTitle">{InactiveText}</p>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


export default DashboardBoxes;

DashboardBoxes.propTypes = {
  // ... other propTypes definitions ...
  InactiveText: function(props, propName, componentName) {
    if (props.statusCode === 2 && (typeof props[propName] !== 'string' || !props[propName])) {
      return new Error(`The prop \`${propName}\` is required and must be a string when \`statusCode\` is 2 in \`${componentName}\`.`);
    }
  },

  apps: function(props, propName, componentName) {
    if (props.statusCode === 1 && (props[propName] === undefined || props[propName] === null)) {
      return new Error(`The prop \`${propName}\` is marked as required in \`${componentName}\` when statusCode is 1, but its value is \`${props[propName]}\`.`);
    }
    if (props[propName] && !Array.isArray(props[propName])) {
      return new Error(`Invalid prop \`${propName}\` of type \`${typeof props[propName]}\` supplied to \`${componentName}\`, expected an array.`);
    }
  },
  // ... other propTypes definitions ...
};
