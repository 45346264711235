import React, { useMemo, useState, useRef, forwardRef, useEffect } from "react";
import Cookies from "universal-cookie";
import { useTable } from "react-table";
import { useNavigate, useParams } from "react-router";
import { Editor } from "@tinymce/tinymce-react";
import middleware from "../../../Api/Middleware";
import { Modal } from 'react-bootstrap';
import VariationModal from "../sub_components/VariationModals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPenToSquare, faCaretLeft, faGear,} from "@fortawesome/free-solid-svg-icons";
import "../css/AddProductPages.css";
import "../../../Main/Main.css";
import "../../product.css";
import Loading from "../../../LoadingScreen/LoadingScreen";
import TablePreset from "../../../Table/TablePreset";
import ToastError from "../../../Toasts/ToastError";
import ToastSuccess from "../../../Toasts/ToastSuccess";
import ToastWarning from "../../../Toasts/ToastWarning";
// Media
import { CSS } from '@dnd-kit/utilities';
import '../css/UploadArea.css'
import { MouseSensor as LibMouseSensor,  KeyboardSensor, TouchSensor} from '@dnd-kit/core';
import { arrayMove, SortableContext, rectSortingStrategy, useSortable} from '@dnd-kit/sortable';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import warning from '../images/warning.png';
import { DndContext, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
// import ProductsList from '../sub_components/ProductsList';

// ======== Media Upload ========

function shouldHandleEvent(element) {
  let cur = element;

  while (cur) {
    if (cur.dataset && cur.dataset.noDnd) {
      return false;
    }
    cur = cur.parentElement;
  }
  return true;
}

class MouseSensor extends LibMouseSensor {
  static activators = [
    {
      eventName: "onMouseDown",
      handler: ({ nativeEvent: event }) => {
        return shouldHandleEvent(event.target);
      },
    },
  ];
}

// The grid for the photo's
function Grid({ children, columns }) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gridGap: 10,
        padding: 10,
      }}
    >
      {children}
    </div>
  );
}

// This is used to render the photo's. For the actual styling and format of the image draggable you need to look at the [Photo] function
const SortablePhoto = (props) => {
  const sortable = useSortable({ id: props.position });
  const { attributes, listeners, setNodeRef, transform, transition } = sortable;
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <Photo
      style={style}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      {...props}
    />
  );
};

// This is used to render the image draggable. This contains the styling and the html in order to render the photo.
const Photo = forwardRef(
  (
    {
      error,
      required,
      index,
      colorId,
      file,
      name,
      handleDelete,
      handleColorChange,
      colorOptions,
      ...props
    },
    ref
  ) => {
    //Return the image div
    return (
      <div ref={ref} {...props} className="col-12 objectWithinMU">
        <div className={error ? "errorImageBoxMU imageBoxMU" : "imageBoxMU"}>
          {/* If the file exists and it's under 10mb it will render it normally */}
          {file && file.size < 10 * 1024 * 1024 ? (
            <div>
              <img
                className="col-12 imageInObjectMU"
                src={URL.createObjectURL(file)}
                draggable="false"
              />
              <div>
                <div className="imageInformationContainerMU">
                  {/* Image size */}
                  <b className="imageNameInObjectMU" id="imageSize">
                    {file.size.toString().length >= 6 ? (
                      <span>
                        {(file.size / 1024 / 1000).toFixed(1) + " MB"}{" "}
                      </span>
                    ) : (
                      <span>{(file.size / 1024).toFixed(1) + " kB"} </span>
                    )}
                  </b>

                  {/* File name */}
                  <div className="imageNameInObjectMU" id="imageName">
                    <span>{name}</span>
                  </div>

                  {/* Delete button */}
                  <div
                    data-no-dnd="true"
                    className="imageNameInObjectMU"
                    id="deleteButton"
                  >
                    <span className="d-flex justify-content-center">
                      <button
                        type="submit"
                        className="deleteImageButtonMU"
                        onClick={() => handleDelete(index)}
                      >
                        <FontAwesomeIcon
                          icon={faXmark}
                          className="deleteIconMU"
                        />{" "}
                        Verwijder
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            // This renders the error image and styling
            <div>
              <img
                className="col-12 imageInObjectMU"
                src={warning}
                draggable="false"
              />
              <div>
                <div className="imageInformationContainerMU">
                  {/* File name */}
                  <div className="imageNameInObjectMU" id="imageName">
                    <span>{name}</span>
                  </div>

                  {/* Delete button */}
                  <div
                    data-no-dnd="true"
                    className="imageNameInObjectMU"
                    id="deleteButton"
                  >
                    <span className="d-flex justify-content-center">
                      <button
                        type="submit"
                        className="deleteImageButtonMU"
                        onClick={() => handleDelete(index)}
                      >
                        <FontAwesomeIcon
                          icon={faXmark}
                          className="deleteIconMU"
                        />{" "}
                        Verwijder
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* The select object for the color options */}
        <div data-no-dnd="true">
          {/* If there is nothing wrong with the image it will render out all the color options given in [colorOptions] */}
          {!error ? (
            <select
              className=" mb-3 py-1  col-12 colorSelectForObjectMU"
              value={colorId}
              id={colorId === undefined && required ? "required" : ""}
              onChange={(event) => handleColorChange(index, event.target.value)}
            >
              <option hidden defaultValue>
                Selecteer een kleur
              </option>
              {colorOptions.map((color, index) => (
                <option key={index} value={color.color_id}>
                  {color.name}
                </option>
              ))}
            </select>
          ) : (
            // If there is an error it will display the error given to the object in the function [handleFileUpload]
            <div
              className=" mb-3 py-1  col-12 colorSelectForObjectMU"
              id="error"
            >
              {error}
            </div>
          )}
        </div>
      </div>
    );
  }
);

// ======== End edia Upload ========

const OldEditPage = () => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [pagination, setPagination] = useState({
    page_size: 10,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  });
  const [producten, setProducten] = useState([]);
  const [product, setProduct] = useState([]);
  const [brands, setBrands] = useState([]);
  const [barcode, setBarcode] = useState([]);
  const [stock, setStock] = useState([]);
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
const [loading, setLoading] = useState(true);
  const [file, setFile] = useState(null);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [edit, setEdit] = useState(false);
  const [generatedProductNum, setGeneratedProductNum] = useState();
  const [language, setLanguage] = useState("nl");
  const [showBlueprintModal, setShowBlueprintModal] = useState(false);
  const [invalidSubmit, setInvalidSubmit] = useState(false);
  const [merkName, setMerkName] = useState("");
  const [description, setdescription] = useState("");
  const [keywords, setKeywords] = useState("");
  const [titleCategory, setTitleCategory] = useState("");
  const [descriptionCategory, setDescriptionCategory] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [sellingPrice, setSellingPrice] = useState(0);
  const [salePrice, setSalePrice] = useState(0);
  const [selectedOption, setSelectedOption] = useState("0");
  const [barcodeValue, setBarcodeValue] = useState("");
  const [userTypedValue, setUserTypedValue] = useState("");
  const [productNumberValue, setProductNumberValue] = useState("");
  const [btw, setBtw] = useState("1");
  const [show, setShow] = useState(false);
  const [showBrand, setShowBrand] = useState(false);
  const [subCategory, setSubCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [colorVariations, setColorVariations] = useState([]);
  const [windowSize, setWindowSize] = useState([window.innerWidth]);
  const [values, setValues] = useState({
    product_number: generatedProductNum,
  });

  const [initialCategories, setInitialCategories] = useState([
    {id: 1, headId: null, name: 'Categories'}
  ]);

  const organizeProducts = (products, parentId = null) => {
    return products
      .filter((product) => product.headId === parentId)
      .map((product) => {
        return {
          ...product,
          subproducts: organizeProducts(products, product.id),
        };
      });
  };

  const [selectedType, setSelectedType] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [attributesConn, setAttributesConn] = useState([]);
  const [genders, setGenders] = useState([]);
  const [checkedGenders, setCheckedGenders] = useState({
    male: false,
    female: false,
    unisex: false,
  });
  const [checkedProducts, setCheckedProducts] = useState({});
  const [productsTree, setProductsTree] = useState(
    organizeProducts(initialCategories)
  );
  const [filteredProducts, setFilteredProducts] = useState(productsTree);
  const { product_id } = useParams();

  const fetchAll = async () => {
    try {
      setLoading(true);
      const [
        fetchProduct,
        fetchStock,
        fetchBarcode,
        fetchAttributesConn,
        fetchGenders,
        fetchCategories,
        fetchBrands,
      ] = await Promise.all([
        middleware.get(`products?product_id=${product_id}`),
        middleware.get(`products/stock?product_id=${product_id}`),
        middleware.get(`products/barcodes?product_id=${product_id}`),
        middleware.get(`products/attributes/connection?product_id=${product_id}`),
        middleware.get(`products/genders`),
        middleware.get(`products/category`),
        middleware.get(`products/brands`),
      ]);
      getImages();
      const cat = fetchCategories.data.map(map => {
        return {id: map.category_id, headId: map.head_category_id, name: map.title}
      });
      setInitialCategories(cat)
      setAttributesConn(...fetchAttributesConn.data);
      setGenders(fetchGenders.data);
      setBrands(fetchBrands.data)
      setProduct(fetchProduct.data);
      setBarcode(fetchBarcode.data[0]);
      setProductNumberValue(fetchProduct.data.product_number);
      setStock(fetchStock.data[0]);
      // setSeos(fetchSEOS.data[0]);
      setLoading(false);
    } catch (error) {
      console.warn(error);
      if (error.response.status === 401) {
        cookies.remove("token", { path: "/" });
        navigate("/");
      }
    }
  };


  useEffect(() => {
    setFilteredProducts(productsTree);
  }, [productsTree])

  useEffect(() => {
    filterProducts(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    fetchAll();
  }, [pagination.page_size, pagination.page]);

  useEffect(() => {
    const colors = localStorage.getItem("colorsAddVariation");
    if (!colors) {
    } else {
      setColorVariations(JSON.parse(colors));
    }
  }, [showBlueprintModal]);

  useEffect(() => {
    (async () => {
      let newBarcodeValue = "";

      if (selectedOption === "0") {
        newBarcodeValue = userTypedValue;
      } else if (selectedOption === "2") {
        newBarcodeValue = await handleGenerateBarcode();
      } else if (selectedOption === "1") {
        const timeoutId = setTimeout(() => {
          newBarcodeValue = productNumberValue;
          setBarcodeValue(newBarcodeValue);
        }, 500);

        // Cleanup the timeout if the component unmounts or the selected option changes
        return () => clearTimeout(timeoutId);
      }

      setBarcodeValue(newBarcodeValue);
    })();
  }, [selectedOption, userTypedValue]);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize([window.innerWidth]);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      // Define an async function within useEffect.

      // The following line of code waits for the middleware.get('producten') promise to be resolved.
      // Only after it is resolved, the execution moves to the next line.
      const [response] = await Promise.all([middleware.get("products")]);

      const producten = response.data;

      // This if statement is executed only after the middleware.get('producten') promise is resolved,
      // i.e., only after the 'producten' data has been fetched.
      if (product.length === 0) {
        setGeneratedProductNum(0);
      } else {
        const lastRow = product.length - 1;
        setGeneratedProductNum(Number(producten[lastRow].product_number) + 1);
        setValues({
          product_number:
            `${Number(producten[lastRow].product_number) + 1}` ?? "",
        });
        setProductNumberValue(generatedProductNum);
      }

      setIsLoading(false); // Set loading to false after everything is done.
    };

    fetchData(); // This is the call to the async function.
  }, []);

  useEffect(() => {
    if (genders.length == 0) {
      return;
    }

    // Checks if the product has a attributesConn
    if (!attributesConn || !attributesConn.gender_ids) {
      return;
    }

    let newGenderState = {};

    // Split the string into an array of values
    let genderIds = attributesConn.gender_ids
      .split(",")
      .map((id) => parseInt(id.trim()));

    genderIds.forEach((genderId) => {
      switch (genderId) {
        case 1:
          newGenderState.male = true;
          break;
        case 2:
          newGenderState.female = true;
          break;
        case 3:
          newGenderState.unisex = true;
          break;
        default:
          console.error("Unknown genderId:", genderId);
          break;
      }
    });

    setCheckedGenders((prevState) => ({ ...prevState, ...newGenderState }));
  }, [genders]);

  useEffect(() => {
    filterProducts();
  }, [initialCategories])

  let editorInstance = null; // define a variable to hold the editor instance

  const generateGenderString = () => {
    let result = [];

    if (checkedGenders.male) {
      result.push("1");
    }

    if (checkedGenders.female) {
      result.push("2");
    }

    if (checkedGenders.unisex) {
      result.push("3");
    }

    return result.join(",");
  };

  const changeGender = (event) => {
    setCheckedGenders({
      ...checkedGenders,
      [event.target.name]: !checkedGenders[event.target.name],
    });
  };

  const filterProductsRecursively = (query, products) => {
    const lowerCaseQuery = query.toLowerCase();
    return products
      .map((product) => {
        let productMatches = product.name
          .toLowerCase()
          .includes(lowerCaseQuery);

        if (productMatches) {
          return product;
        }

        let filteredSubproducts = [];
        if (product.subproducts) {
          filteredSubproducts = filterProductsRecursively(
            query,
            product.subproducts
          );
          if (filteredSubproducts.length > 0) {
            return {
              ...product,
              subproducts: filteredSubproducts,
            };
          }
        }

        return null;
      })
      .filter(Boolean);
  };

  const toggleProduct = (id) => {
    setCheckedProducts((prev) => {
      const newCheckedProducts = { ...prev };
      const parts = id.split("-");

      newCheckedProducts[id] = !newCheckedProducts[id];

      for (let i = 1; i < parts.length; i++) {
        const parentId = parts.slice(0, i).join("-");
        newCheckedProducts[parentId] = true;
      }

      return newCheckedProducts;
    });
  };

  const filterProducts = (query) => {
    if (!query) {
      setProductsTree(organizeProducts(initialCategories));
      return;
    }

    setProductsTree(filterProductsRecursively(query, initialCategories));
  };

  const handleModal1Open = () => {
    setShowBlueprintModal(true);
    const productNumber = values.product_number;
    setValues([]);
    setValues({ product_number: productNumber });
  };

  function handleKeyUp () {
    if (editorInstance) {
      const text = editorInstance.getContent({ format: "text" });

      if (text.length > 20000) {
        // cut the extra text
        editorInstance.setContent(text.substring(0, 20000));
      }
    }
  }

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  function setLoadingScreenFunc() {
    setIsLoading(false);
    setTimeout(() => {
      setLoadingScreen(false);
    }, 500);
  }

  const handleProductSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedProductIds([...selectedProductIds, productId]);
    } else {
      setSelectedProductIds(
        selectedProductIds.filter((id) => id !== productId)
      );
    }
  };

  const handleSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedIds((previous) => [...previous, productId]);
    } else {
      setSelectedIds((previous) => previous.filter((id) => id !== productId));
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <>
            <label className="checkbox-container">
              <input
                type="checkbox"
                onChange={(e) => {
                  const checked = e.target.checked;
                  setSelectedProductIds(
                    checked ? product.map((product) => product.product_id) : []
                  );
                }}
              />
              <span className="checkmark"></span>
            </label>
          </>
        ),
        accessor: "select",
        Cell: ({ row }) => {
          const isSelected = selectedProductIds.includes(
            row.original.product_id
          );
          return (
            <label className="checkbox-container">
              <input
                type="checkbox"
                checked={isSelected}
                onChange={(e) =>
                  handleProductSelection(
                    row.original.product_id,
                    e.target.checked
                  )
                }
              />
              <span className="checkmark"></span>
            </label>
          );
        },
      },
      {
        Header: (
          <select className="tableDropdown px-1">
            <option hidden>Actie</option>
          </select>
        ),
        accessor: "product_name",
        Cell: ({ row }) => {
          return <span>Lorem ipsum</span>;
        },
      },
      {
        Header: (
          <select className="tableDropdown px-1">
            <option hidden>Filter kleuren</option>
          </select>
        ),
        accessor: "name",
        Cell: ({ row }) => {
          return (
            <span className="d-flex flex-row">
              <div
                className=" rounded-circle me-2 mt-1 colorCircle"
                style={{
                  backgroundColor: row.original.hex,
                  width: "15px",
                  height: "15px",
                }}
              ></div>
              <div className="text-nowrap">{row.original.name}</div>
            </span>
          );
        },
      },
      {
        Header: (
          <select className="tableDropdown px-1">
            <option hidden>Filter maten</option>
          </select>
        ),
        accessor: "size",
        Cell: ({ row }) => {
          return <span>128</span>;
        },
      },
      {
        Header: "Voorraad",
        accessor: "stock",
        Cell: ({ row }) => {
          return <span>2</span>;
        },
      },
      {
        Header: <div>Barcode</div>,
        accessor: "barcode",
        Cell: ({ row }) => {
          return <span></span>;
        },
      },
      {
        Header: "Basisprijs",
        accessor: "basicPrice",
      },
      {
        Header: "Prijs incl.BTW",
        accessor: "price_incl_tax",
      },
    ],
    [stock, selectedProductIds, producten]
  );

  //   Table instance
  const tableInstance = useTable({ columns, data: colorVariations });

  // Destructure table properties
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const updateNew = async (event) => {
    try {
      setLoading(true)
      const genderstring = generateGenderString();
      event.preventDefault();
      await middleware.put(`products`, {
        product_id: product_id,
        product_name: product.product_name,
        price_excl_tax:
          product.price_excl_tax - (product.price_excl_tax / 100) * 21,
        product_number: product.product_number,
        product_description: product.product_description,
        brand_id: product.brand_id,
      });

      if (attributesConn) {
        await middleware.put(`products/attributes/connection`, {...attributesConn});
      }

      await middleware.put(
        `products/barcodes?barcode_id=${barcode.barcode_id}`,
        {
          product_id: product_id,
          barcode: barcode.barcode,
        }
      );

      await saveImages(event);
      setLoading(false);
      ToastSuccess('Wijzigingen opgeslagen');
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditorChange = (content) => {
    setEditorContent(content);
    setValues((prevValues) => ({
      ...prevValues,
      product_description: content,
    }));
  };

  const handleBtwChange = (event) => {
    setBtw(event.target.value);
  };

  const handleGenerateBarcode = async () => {
    // Generate a 13-digit barcode
    try {
      const res = await middleware.get("products/generateBarcode");
      const barcode = res.data.generatedBarcode;
      return barcode;
    } catch (error) {
      console.log(error);
    }
  };

  const handleProductNumberChange = (event) => {
    setProducten({ ...producten, product_number: event.target.value });
    if (selectedOption == "1") {
      setBarcodeValue(event.target.value);
    }
    setProductNumberValue(event.target.value);
  };

  const handleSameAsProductNumber = () => {
    return productNumberValue;
  };

  const handleInputChange = (event) => {
    setUserTypedValue(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked == true) {
      setSelectedType([...selectedType, event.target.value]);
    } else {
      setSelectedType([
        ...selectedType.filter((e) => e !== event.target.value),
      ]);
    }
  };

  // ======= Media Upload =======

  const imageAPIUrl = "http://localhost:5000"; //This is a temporary url, you can start this by typing [node server.js] in the terminal.
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor)
  );
  const fileInputRef = useRef(null);
  const [mouseDownX, setMouseDownX] = useState(null);
  const [mouseDownY, setMouseDownY] = useState(null);
  const [requiredCheck, setRequiredCheck] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [downloadCount, setDownloadCount] = useState(0);
  const [images, setImages] = useState([]);
  const [colors, setColors] = useState([]);
  const [uploadCount, setUploadCount] = useState(0);
  const [uploadImgStatus, setUploadImgStatus] = useState("");
  const allowedImageFormats = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/jpeg",
    "image/svg",
  ];

  window.isDeleteOrDragEndTriggered = true;
  window.mouseMoved = true;
  window.clickedOnTheColor = true;

  // This is only for development, when the upload gets implemented on the actual page you can delete this
  const tableLog = () => {
    console.table(images);
  };

  // This function converts a URL into a Blob object.
  async function urlToBlob(url) {
    try {
      // Use fetch API to get a response from the specified URL
      const response = await fetch(url);

      if (!response.ok) {
        // If the response was not ok (status in the range 200-299),
        // throw an error with the status text.
        throw new Error(
          `HTTP error! status: ${response.status} ${response.statusText}`
        );
      }
      // Wait for the response to resolve into an ArrayBuffer.
      // An ArrayBuffer is a low-level data structure for representing a generic, fixed-length raw binary data buffer.
      const arrayBuffer = await response.arrayBuffer();

      // Create a new Blob object from the ArrayBuffer.
      // A Blob represents immutable raw data that can be read as text or binary data, or used as a source for an img, etc.
      // The Blob constructor parameters are an array of ArrayBuffer, ArrayBufferView, Blob, DOMString,
      // which will be put inside the Blob, and an optional BlobPropertyBag.
      // BlobPropertyBag is an object that sets the MIME type, here we are getting it from the response headers.
      const blob = new Blob([arrayBuffer], {
        type: response.headers.get("content-type"),
      });

      // Return the Blob object.
      return blob;
    } catch (error) {
      // Log any errors that occur during the process to the console.
      console.error("Error converting URL to Blob:", error);

      // Re-throw the error to be handled by the calling function.
      throw error;
    }
  }

  // Save the current changes
  async function saveImages(e) {
    e.preventDefault(); //Prevent the form from actually submitting
    try {
      const currentMonth = (new Date().getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const currentYear = new Date().getFullYear();

      const folderPath = `./uploads/${currentYear}/${currentMonth}/${product_id}`;
      const url = `${imageAPIUrl}/api/image`;

      //Check if there are any images that are faulty (like too big or unsupported file type)
      let faultyImages = false;
      for (let index = 0; index < images.length; index++) {
        const CurrentImage = images[index];
        if (CurrentImage.error) {
          faultyImages = true;
        }
      }

      //Check if every file has a color assigned to it
      let everythingFilledIn = true;
      for (let index = 0; index < images.length; index++) {
        const CurrentImage = images[index];
        if (CurrentImage.colorId === undefined) {
          everythingFilledIn = false;
        }
      }

      // bullshit message, not every product requires a image
      // If there are no images uploaded yet it will tell the user to add images and stop the code from running any further
      // if (images.length <= 0) {
      //   ToastError("Voeg eerst foto's toe voordat je opslaat");
      //   return false;
      // }
      //If there are any images that are faulty, a pop-up will appear and will stop the code from running any further
      if (faultyImages) {
        ToastError("Verwijder de foto's die ongeldig zijn voordat je opslaat.");
        return false;
      }
      //If there are any images that have no color assigned to them, a pop-up will appear and will stop the code from running any further
      else if (!everythingFilledIn) {
        setRequiredCheck(true);
        ToastError("Selecteer voor elke foto een kleur.");
        return false;
      } else {
        //This is so if the upload went succesful and you add a new image, it won't have a red ring around it.
        setRequiredCheck(false);
      }

      //UploadCount is for feedback so the user knows they are being uploaded.
      setUploadCount(0);

      // Makes the upload screen appear for the upload media box
      setUploading(true);
      setUploadImgStatus("Oude bestanden aan het wissen");
      //The string with all the data that will be send to the API so it saves the image path, order and color
      let bulkDataString = "";

      // Delete the images in the folder so it won't contain images from the previous save
      await fetch(`${url}?folderPath=${encodeURIComponent(folderPath)}`, {
        method: "DELETE",
      })
        .then((response) => response.json())
        .then((data) => {})
        .catch((error) => {
          console.error(error);
        });

      //Loop through the images and preparing them to upload
      for (let index = 0; index < images.length; index++) {
        const CurrentImage = images[index];

        // This displays the image that is currently being uploaded
        setUploadImgStatus(
          `${CurrentImage.name} (${
            CurrentImage.file.size.toString().length >= 6
              ? (CurrentImage.file.size / 1024 / 1000).toFixed(1) + " MB"
              : (CurrentImage.file.size / 1024).toFixed(1) + " kB"
          })`
        );

        //Data that get's send to the api to save the images on react (server.js)
        const formData = new FormData();
        formData.append(`name`, CurrentImage.name);
        formData.append(`index`, index);
        formData.append(`color`, CurrentImage.color);
        formData.append(`id`, CurrentImage.id);
        formData.append(`productId`, product_id);
        formData.append(`folderPath`, folderPath);
        formData.append(`image`, CurrentImage.file); //This should always be last, anything appended after this will be ignored (no idea why but I've had this before).

        // Posts the data to the server.js
        await fetch(url, {
          method: "POST",
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {})
          .catch((error) => {
            console.error(error);
          });

        // Increase the upload count to notify the user the image has been uploaded
        setUploadCount((prev) => prev + 1);

        //Add the data to the bulkdata that will eventually be sent to the middleware API
        bulkDataString += `${CurrentImage.name}|${
          CurrentImage.colorId
        }|${folderPath}/${CurrentImage.name.replace(/\s/g, "_")}|${index + 1}`;
        if (index < images.length - 1) {
          bulkDataString += ",";
        }
      }
      setUploadImgStatus("Foto's versturen naar database");
      //Post request to the middleware api to save the image data to the database and delete the older information
      const response = await middleware.post(
        `products/media/upload?product_id=${product_id}&bulk_data=${encodeURIComponent(
          bulkDataString
        )}`
      );

      // not necessary, already a toast for if the changes have been saved
      // Toast to notify the user of the succesful image upload
      // ToastSuccess("Foto's zijn succesvol opgeslagen!");

      setUploading(false);
      return true;
    } catch (error) {
      // When something goes wrong the user gets logged out and send to the login page
      console.warn(error);
      cookies.remove("token");
      navigate("/");
      return false;
    }
  }

  // When the user drops an image from their folder onto the image uploader
  function handleDrop(e) {
    handleFileUpload(e, true);
  }

  // Handles the image and adds it to the [images] variable.
  function handleFileUpload(e, isDropEvent) {
    e.preventDefault(); // Prevents the user from openening the image on a new page when dragging the image on the image upload field
    e.stopPropagation(); // Ensures that the event is not passed along to any higher-level elements in the DOM tree.

    const droppedFiles = isDropEvent ? e.dataTransfer.files : e.target.files;
    const newImages = [...images];
    let counter = 0;

    // Loop through the dropped files
    for (const uploadedFile of droppedFiles) {
      //If the image exists, is smaller than 10 MB and is an allowed file type (check [allowedImageFormats] for the full list of allowed file types)
      if (
        uploadedFile &&
        uploadedFile.size <= 10 * 1024 * 1024 &&
        allowedImageFormats.includes(uploadedFile.type)
      ) {
        const newImage = {
          file: uploadedFile, //The file containing the size and the image
          name: createUniqueName(uploadedFile.name.replace(/\s/g, "_"), images), // This function makes sure the name doesn't exist yet
          color: "default", // Default shows 'selecteer een kleur'
          id: `${Date.now()}${+counter}`, // A unique id for the image
        };

        //Push the new image object into the newImages list
        newImages.push(newImage);
        counter++;
      } else if (uploadedFile.size >= 10 * 1024 * 1024) {
        // If the image is bigger than 10 MB it will give the user a popup
        //Toast with the warning
        ToastWarning(
          `${uploadedFile.name} (${
            Math.round((uploadedFile.size / 1024 / 1024) * 100) / 100
          } MB) is te groot! De maximale bestandsgrootte is 10 MB per afbeelding.`
        );

        //New image object
        const newImage = {
          file: { name: uploadedFile.name, size: uploadedFile.size }, // Makes sure it can still display the image name and the image size even when the file can't be loaded correctly
          name: createUniqueName(uploadedFile.name, images), // This function makes sure the name doesn't exist yet
          color: "default", // Default shows 'selecteer een kleur'
          id: `${Date.now()}${+counter}`, // A unique id for the image
          error: "Afbeelding is te groot", // This error will be shown in the 'select' menu under the image
        };

        //Push the error image object into the newImages list
        newImages.push(newImage);
        counter++;
      } else if (!allowedImageFormats.includes(uploadedFile.type)) {
        // If the image isn't allowed notify the user using a toast
        //Toast telling the user which file isn't allowed and which types are.
        ToastWarning(
          `${uploadedFile.name} is niet toegestaan. Bestanden met het type .png, .jpg, .jpeg, .svg en .gif zijn wel toegestaan. `
        );

        // New image object
        const newImage = {
          file: undefined, // Makes sure the [Photo] function doesn't try to display the faulty image
          name: createUniqueName(uploadedFile.name.replace(/\s/g, "_"), images), // This function makes sure the name doesn't exist yet
          color: "default", // Default shows 'selecteer een kleur'
          id: `${Date.now()}${+counter}`, // A unique id for the image
          error: "Ongeldig bestandtype", // This error will be shown in the 'select' menu under the image
        };

        //Push the error image object into the newImages list
        newImages.push(newImage);
        counter++;
      } else {
        //A fallback for when something goes wrong which can't be explained.
        ToastError(
          `Er ging iets fout tijdens het uploaden van ${uploadedFile.name}. Probeer het later opnieuw`
        );
      }
    }

    // Sets the new list of images to the variable
    setImages(newImages);

    // When they selected images by clicking on the file uploader
    if (!isDropEvent) {
      clearFileInput(); // Clears the file input so the user can upload the same image again if they want to
    }
  }

  // Triggers everytime the user is dragging an image inside the drag and drop box (only when moving)
  function handleDragEnter(e) {
    e.preventDefault(); // Prevents the user from openening the image on a new page when dragging the image on the image upload field
    e.stopPropagation(); // Ensures that the event is not passed along to any higher-level elements in the DOM tree.
  }

  // Triggers everytime the user is holding an image inside the drag and drop box (even when holding still)
  function handleDragOver(e) {
    e.preventDefault(); // Prevents the user from openening the image on a new page when dragging the image on the image upload field
    e.stopPropagation(); // Ensures that the event is not passed along to any higher-level elements in the DOM tree.
  }

  // Sets the new color for the image
  function handleColorChange(index, newColor) {
    const newImages = [...images];
    newImages[index].colorId = newColor;
    setImages(newImages);
    window.isDeleteOrDragEndTriggered = true;
  }

  // Removes image from the images variable
  function handleDelete(index) {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
    window.isDeleteOrDragEndTriggered = true;
  }

  // Triggers when the user holds down the left or right mouse button
  function handleMouseDown(event) {
    if (event.target.matches("select, option, button, span, svg, path")) {
      return;
    } else {
      setMouseDownX(event.clientX);
      setMouseDownY(event.clientY);
    }
  }

  // Triggers when the users lets go of their left or right mouse button
  function handleMouseUp(event) {
    if (event.clientX === mouseDownX && event.clientY === mouseDownY) {
      window.isDeleteOrDragEndTriggered = false;
      window.mouseMoved = false;
    } else {
      window.mouseMoved = true;
    }
    setMouseDownX(null);
    setMouseDownY(null);

    handleClick();
  }

  // Triggers when the user let's go of the mouse button their holding
  function handleClick() {
    if (!window.isDeleteOrDragEndTriggered && !window.mouseMoved) {
      fileInputRef.current.click();
    }
  }

  // Triggers when the user stops dragging an image
  function handleDragEnd(event) {
    const { active, over } = event;
    if (!over) {
      return;
    }
    if (active.id !== over.id) {
      setImages((images) => {
        // fixed issue with help of Tijn :D
        const oldIndex = images.indexOf(
          images.find((obj) => obj["id"] === active.id)
        );
        const newIndex = images.indexOf(
          images.find((obj) => obj["id"] === over.id)
        );
        return arrayMove(images, oldIndex, newIndex);
      });
    }
  }

  // Clears the image input so the user can upload the same image again if they want to.
  function clearFileInput() {
    var fileInput = document.getElementById("fileInput");
    fileInput.value = "";
  }

  // Checks if a file with the same name exists
  function fileExists(imageName, imagesArray) {
    return imagesArray.some((image) => image.name === imageName);
  }

  // Returns a unique name by adding (x) to the image name
  function createUniqueName(imageName, imagesArray) {
    const originalName = imageName; // The name of the image
    const lastDotIndex = originalName.lastIndexOf("."); // Index of the last .
    const filenameWithoutExt = originalName.substring(0, lastDotIndex); // Only the file name without the executor
    const fileExtension = originalName.substring(lastDotIndex + 1); // Only the executor so for example: png or jpeg
    let fileName = `${filenameWithoutExt}.${fileExtension}`;
    let fileCount = 0;

    // Loop through possible names until it doesn't exist in the current image array
    while (fileExists(fileName, imagesArray)) {
      fileCount++;
      fileName = `${filenameWithoutExt}(${fileCount}).${fileExtension}`;
    }

    return fileName;
  }

  // Get the images that are bound to the product_id. This will also get the color options so this function should always be ran before trying to save new images
  async function getImages() {
    try {
      setDownloading(true);
      setUploadImgStatus("Foto's aan het ophalen");
      setUploadCount(0);
      setDownloadCount("?");

      const mediaRepsonse = await middleware.get(
        `products/media/upload?product_id=${product_id}`
      ); //Get the media from the database
      const mediaData = mediaRepsonse.data.images;
      const colorData = mediaRepsonse.data.colors;
      const newImages = [];

      // Loop through the given images
      setDownloadCount(mediaData.length);
      for (let i = 0; i < mediaData.length; i++) {
        let imgData = mediaData[i];
        // The path url to the current file
        const filePathURL = imageAPIUrl + "/" + imgData.path;
        const lastSeparatorIndex = filePathURL.lastIndexOf("/");

        // Extract the file name using substring()
        const fileName = filePathURL.substring(lastSeparatorIndex + 1);

        // Turns the url into a blob so it can be processed by the image handler
        const blob = await urlToBlob(filePathURL);

        const newImage = {
          file: blob,
          name: createUniqueName(fileName, newImages),
          color: "default",
          colorId: imgData.color_id,
          id: `${Date.now()}${i + 1}`,
        };
        newImages[i] = newImage;
        setImages(newImages);
        setUploadCount((prev) => prev + 1);
      }

      setDownloading(false);
      setImages(newImages);
      setColors(colorData);
    } catch (error) {
      console.warn(error);
      cookies.remove("token");
      navigate("/");
    }
  }

  async function uploadFile(file) {
    let formData = new FormData();
    formData.append("file", file);

    const response = await fetch("/upload", {
      // This should be the URL to your server-side upload script
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Upload failed");
    }

    const data = await response.json();
    return data.url; // This should be the URL to the uploaded file
  }

  // ======= End Media Upload =======

  const submitNewBrand = async (e) => {
    try {
      if (merkName) 
      {
        const res = await middleware.post("products/brands", {
          name: merkName,
          description: description,
          keywords: keywords,
        });      
        
      }
    } catch (err) {
      console.error(err);
    }
  };

  const submitNewCategorie = async (event) => {
    try {
      event?.preventDefault();
  
      if (titleCategory) {
        const payload = {
          title: titleCategory,
          description: descriptionCategory,
          status: 0,
        };
  
        if (subCategory && selectedCategory) payload.head_category_id = selectedCategory.id;
  
        await middleware.post("products/category", payload);
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  };


  if (isLoading) {
    return (
      <div
        className={`loading-container ${
          loadingScreen ? "fade-in" : "fade-out"
        }`}
      >
        <Loading />
      </div>
    );
  }

  return (
    <div className="d-flex flex-nowrap">
      <Modal
        id="modalBlueprint"
        show={show}
        onHide={e => setShow(false)}
        
        size="md"
        backdrop="static"
      >
        <Modal.Header className="modalTop justify-content-between">
          <h4 className='ms-3'>Voeg een categorie toe</h4>
          <button variant="transparent" className=' btn' onClick={e => setShow(false)}>
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <form onSubmit={submitNewCategorie}>
        <Modal.Body className="modalCenter">
        <div className="contendModal w-100">
              <div className='d-flex w-100 flex-column'>
                <label className='inputTitle'>Titel :</label>
                <input onBlur={(e) => setTitleCategory(e.target.value)} required className='w-100 optionsList ps-2 borderInputs mb-1 pe-1 ps-0 text-nowrap'></input>              
              </div>
              <div className='d-flex w-100 flex-column mb-2'>
                <label className='inputTitle'>Beschrijving :</label>
                <textarea
                  className="borderInputs rounded-2 mb-2 w-100"
                  type="text"
                  name="categoryDescription"
                  onBlur={(e) => setDescriptionCategory(e.target.value)}
                ></textarea>   
              </div> 
              <div className='d-flex w-100 flex-column'>
                <div className="d-flex flex-row mb-2">
                  <label className="checkbox-container checkSimple">
                    <input
                      type="checkbox"
                      value="subProduct"
                      checked={subCategory ?? ""}
                      onChange={(e) => setSubCategory(e.target.checked)}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <p className="inputTitle user-select-none" style={{position: 'relative',bottom: '6px'}}>Subcategorie</p>
                </div>
              </div>
              { subCategory === true ?
                <div className='d-flex flex-column'>
                  <label className='inputTitle m-0'>Plek van de Subcategorie</label>
                  <small className='mb-1'>(komt onder de categorie die je hebt geselecteerd)</small>
                  <select
                    className="optionsList optionListWidth w-100 mb-2"
                    required
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      const selectedItem = initialCategories.find((item) => item.name === selectedValue);
                      setSelectedCategory(selectedItem); // This will give you the entire selected item from the initial array
                    }}
                  >
                    <option hidden></option>
                      {initialCategories.map(Categories => {
                        return <option key={Categories.id}>{Categories.name}</option>;
                      })}
                  </select>
                </div>
                :
                ""
              }

            </div>
        </Modal.Body>
        <Modal.Footer className="modalBottom">
          <button
            className="btn bg-white text-secondary buttonStyle border-0"
            type="submit"
          >
            Toevoegen
          </button>
        </Modal.Footer>
        </form>
      </Modal>

      <Modal
        id="modalBlueprint"
        show={showBrand}
        onHide={e => setShowBrand(false)}
        
        size="md"
        backdrop="static"
      >
        <Modal.Header className="modalTop justify-content-between">
          <h4 className='ms-3'>Voeg een merk toe</h4>
          <button variant="transparent" className=' btn' onClick={e => setShowBrand(false)}>
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <Modal.Body className="modalCenter">
          <div className="contendModal w-100">
            <h6 className="inputTitle">Merk naam : </h6>
            <input
              className="inputField borderInputs mb-2 w-100"
              type="text"
              name="merkName"
              onBlur={(e) => setMerkName(e.target.value)}
            ></input>
            <h6 className="inputTitle">Beschrijving : </h6>
            <textarea
              className="borderInputs rounded-2 mb-2 w-100"
              type="text"
              name="merkDescription"
              onBlur={(e) => setdescription(e.target.value)}
            ></textarea>
            <h6 className="inputTitle">Trefwoorden : </h6>
            <textarea
              className="borderInputs rounded-2 w-100"
              type="text"
              name="merkKeywords"
              onBlur={(e) => setKeywords(e.target.value)}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer className="modalBottom">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => setShowBrand(false)}
          >
            Annuleren
          </button>
          <button
            type="sumbit"
            className="btn btn-success"
            onClick={() => {

              setSelectedProductIds([]);
              submitNewBrand();
              setShowBrand(false)

            }}
          >
            Voeg toe
          </button>
        </Modal.Footer>
      </Modal>
      <VariationModal
        show={showBlueprintModal}
        handleClose={e => setShowBlueprintModal(false)}
      />
      {/* <Sidebar
      /> */}
      <div className="d-flex flex-column col-10">
        {/* <Navbar title={"Warehouse HUB"} /> */}
        <div className="d-flex justify-content-center align-items-center">
          <div className="spinner-border text-primary spinner-border">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
        <div className="mainBox d-flex m-3 justify-content-center pe-5">
          <div className=" d-flex px-3 pe-2 mt-4 ms-4 col-11 justify-content-around flex-column position-absolute mainMargin">
            <form onSubmit={updateNew} method="post" className=" fitContent">
              <div className="mb-3 d-flex flex-column fitContent">
                <div className=" fitContent">
                  <button
                    type="button"
                    className="buttonStyleLight fitContent d-flex text-nowrap"
                    onClick={() => navigate("/producten")}
                  >
                    <FontAwesomeIcon icon={faCaretLeft} className="me-2 pt-1" />
                    Alle producten
                  </button>
                </div>
                <div className="d-flex justify-content-between mt-4 parentSaveButtons variatonButtons">
                  <h3 className="text-nowrap titleVariation">
                    Product bewerken
                  </h3>
                  <div className=" saveButtonsBox fitContent">
                    <button
                      type="submit"
                      className="btn buttonStyle saveButton px-4 saveProduct mb-3 "
                    >
                      Opslaan
                    </button>
                  </div>
                </div>
                <div className="d-flex flex-column parentContainer">
                  <div className="d-flex flex-row productAndSellChannel">
                    <div className="d-flex flex-column">
                      <div className="d-flex flex-column whiteBox px-4 mb-4 formVariationProduct">
                        <div className="d-flex flex-row parentBoxSellChannelProduct">
                          <div className="w-100">
                            <div className="d-flex flex-column mb-3">
                              <label className="inputTitle mt-3 mb-2">
                                Productnaam
                              </label>
                              <input
                                className="inputField col-12 productInputWidth"
                                type="text"
                                name="product_name"
                                value={product.product_name}
                                onChange={e =>
                                  setProduct({
                                    ...product,
                                    product_name: e.target.value,
                                  })
                                }
                                required
                              ></input>
                            </div>
                            <div className="d-flex flex-row productNumSelectLists fitContent w-100">
                              <div className="d-flex flex-column me-4 marginRight0">
                                <div className="d-flex flex-nowrap">
                                  <label className="inputTitle me-2">
                                    Productnummer
                                  </label>
                                  <FontAwesomeIcon
                                    icon={faPenToSquare}
                                    onClick={() => setEdit(!edit)}
                                    color="#6a6a6a"
                                    className="hover"
                                  />
                                </div>
                                <input
                                  type="number"
                                  className="inputField mb-4 productNumInputWidth webkitRemove"
                                  name="product_number"
                                  defaultValue={product.product_number ?? ""}
                                  onChange={(e) => {
                                    handleProductNumberChange(e);
                                    handleChange(e);
                                  }}
                                  disabled={!edit}
                                  required
                                />
                              </div>
                              <div className="d-flex flex-column barcodeParent">
                                <div className="d-flex flex-row justify-content-between">
                                  <label className="inputTitle me-2">
                                    Barcode
                                  </label>
                                  <select
                                    className="optionsList productOptions me-4"
                                    id="sel1"
                                    name="sellist1"
                                    value={selectedOption}
                                    onChange={e => setSelectedOption(e.target.value)}
                                  >
                                    <option value="0">
                                      Aangepaste barcode
                                    </option>
                                    <option value="1">
                                      Gelijk aan productnummer
                                    </option>
                                    <option value="2">
                                      Automatisch gegenereerd
                                    </option>
                                  </select>
                                </div>
                                <div className="d-flex flex-column me-4 mt-0 marginRight0">
                                  {selectedOption === "0" && (
                                    <input
                                      type="number"
                                      className="inputField mb-4 barcodeInputfield inputBarcode"
                                      placeholder="Barcode (ISBN, UPS, GTIN, etc.)"
                                      value={barcode.barcode ?? ""}
                                      onChange={handleInputChange}
                                    />
                                  )}
                                  {selectedOption == "2" && (
                                    <input
                                      type="number"
                                      className="inputField mb-4 barcodeInputfield inputBarcode"
                                      placeholder="Barcode (ISBN, UPS, GTIN, etc.)"
                                      value={barcodeValue ?? ""}
                                      disabled
                                    />
                                  )}
                                  {selectedOption == "1" && (
                                    <input
                                      type="number"
                                      className="inputField mb-4 barcodeInputfield inputBarcode"
                                      placeholder="Barcode (ISBN, UPS, GTIN, etc.)"
                                      value={product.product_number ?? ""}
                                      disabled
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="d-flex flex-column me-4 marginRight0">
                                <label className="inputTitle">
                                  Merk{" "}
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    color="#6a6a6a"
                                    className="hover"
                                    onClick={() => setShowBrand(true)}
                                    data-bs-toggle="exampleModal"
                                    data-bs-target="#exampleModal"
                                  />
                                </label>
                                <select
                                  className="optionsList mb-2 brandOptions form-select"
                                  id="sel1"
                                  name="sellist1"
                                  value={product.brand_id}
                                  onChange={e => setProduct({...product, brand_id: e.target.value})}
                                >
                                  <option hidden></option>
                                    {brands.map(brands => {
                                      return <option key={brands.brand_id} value={brands.brand_id}>{brands.name}</option>;
                                    })}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="mb-4 textEditorSimple mt-3 me-3">
                            <Editor
                              onInit={(evt, editor) =>
                                (editorInstance = editor)
                              }
                              initialValue={product.product_description ?? ""}
                              key={language}
                              init={{
                                height: 300,
                                menubar: false,
                                statusbar: true,
                                plugins: [
                                  "advlist",
                                  "autolink",
                                  "lists",
                                  "link",
                                  "image",
                                  "charmap",
                                  "emoticons",
                                  "preview",
                                  "anchor",
                                  "wordcount",
                                  "accordion",
                                  "table",
                                  "fontsize",
                                ],
                                toolbar:
                                  "undo redo | bold italic underline | fontsize accordion | \
                                      alignleft aligncenter alignright alignjustify image | \
                                      bullist numlist outdent indent table | removeformat preview ",

                                language: language,

                                file_picker_callback: function (
                                  callback,
                                  value,
                                  meta
                                ) {
                                  let input = document.createElement("input");
                                  input.setAttribute("type", "file");
                                  input.setAttribute("accept", "image/*"); // or other types you want to accept

                                  input.onchange = function () {
                                    let file = this.files[0];
                                    let reader = new FileReader();

                                    reader.onload = function (e) {
                                      callback(e.target.result, {
                                        alt: file.name,
                                      });
                                    };
                                    reader.readAsDataURL(file);
                                  };

                                  input.click();
                                },

                                setup: function (editor) {
                                  editorInstance = editor;
                                  editor.on("keyup", handleKeyUp);
                                },
                              }}
                              onEditorChange={handleEditorChange}
                            />
                            <div className="d-flex flex-row justify-content-between mt-2">
                              <select
                                className="languageSelect"
                                onChange={(e) => setLanguage(e.target.value)}
                              >
                                <option value="nl">Dutch</option>
                                <option value="en">English</option>
                                <option value="fr_FR">French</option>
                                <option value="es">Spanish</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="whiteBox p-3 formVariationProduct mb-4"
                        hidden={product.type !== 1}>
                        <h4>Huidige variaties</h4>
                        <div className="widthColorTable">
                          {!isLoading ? (
                            <TablePreset
                              data={[
                                {
                                  selectedIds: selectedIds,
                                  tableInstance: tableInstance,
                                  pagination: pagination,
                                  paginationHidden: true,
                                },
                              ]}
                              setPagination={setPagination}
                              handleSelection={handleSelection}
                              primary_key={"order_id"}
                            />
                          ) : (
                            <div className="table-container">
                              <table>
                                <thead>
                                  <tr>
                                    <td>
                                      <div>Loading</div>
                                    </td>
                                  </tr>
                                </thead>
                              </table>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex sellChannelMedia flex-column">
                      <div className=" d-flex flex-column genderPrices">
                        <div className="whiteBox mb-4 col-12 p-3 ps-5 pt-4 widthGender me-3">
                          <h5 className="inputTitle mb-4">Geslacht</h5>
                          <div>
                            {genders.map((gender) => 
                              <div className="d-flex flex-row mb-3">
                                <label className="checkbox-container checkSimple">
                                  <input
                                    type="checkbox"
                                    value={gender.gender_id}
                                    name={gender.name}
                                    checked={attributesConn.gender_ids.includes(gender.gender_id)}
                                    onChange={e =>{
                                      const arrayGenders = attributesConn.gender_ids.split(',');
                                      const filteredOut = arrayGenders.filter(filt => filt !== String(gender.gender_id));
                                      const addedTo = [...arrayGenders, String(gender.gender_id)];
                                      arrayGenders.includes(String(gender.gender_id)) ?
                                        setAttributesConn({...attributesConn, gender_ids: filteredOut.join(',')}) :
                                        setAttributesConn({...attributesConn, gender_ids: addedTo.join(',')})
                                    }}
                                  />
                                  <span className="checkmark"></span>
                                </label>
                                <h5 className="inputTitle">{gender.name}</h5>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="whiteBox p-4 boxSellChannel mb-4">
                          <div className="d-flex flex-column ">
                            <div className="d-flex justify-content-between">
                              <h5 className="inputTitle mb-4 ">Prijzen</h5>
                              <div className="BTWSetting">
                                <select
                                  className="inputField widthInput placeholderStyle me-3 hover"
                                  onChange={handleBtwChange}
                                >
                                  <option hidden value="0">
                                    Prijzen incl. BTW
                                  </option>
                                  <option
                                    value="1"
                                    {...(btw === "1"
                                      ? { hidden: true }
                                      : { hidden: false })}
                                  >
                                    21%
                                  </option>
                                  <option
                                    value="2"
                                    {...(btw === "2"
                                      ? { hidden: true }
                                      : { hidden: false })}
                                  >
                                    9%
                                  </option>
                                </select>
                                <button className=" rounded-pill px-2 text-secondary gearButtonPrices">
                                  <FontAwesomeIcon icon={faGear} />
                                </button>
                              </div>
                            </div>
                            <div className="px-1">
                              <div className="d-flex flex-column">
                                <div className="d-flex flex-row justify-content-between standardSaleParent">
                                  <div className="d-flex flex-column me-2 standardPrice">
                                    <label className="inputTitle text-small">
                                      Standaard prijs
                                    </label>
                                    <input
                                      type="number"
                                      step="any"
                                      className="inputField standardSalePrice placeholderStyle text-small"
                                      placeholder="0.00"
                                      name="selling_price"
                                      value={product.price_incl_tax ?? 0}
                                      onChange={(e) =>
                                        setProduct({
                                          ...product,
                                          price_incl_tax: e.target.value,
                                        })
                                      }
                                    />
                                    <p className="bottomText">
                                      €{" "}
                                      {(
                                        (product.price_incl_tax / 100) *
                                        (100 -
                                          (product.tax_class == "high"
                                            ? 21
                                            : product.tax_class == "reduced"
                                            ? 9
                                            : 0))
                                      ).toFixed(2)}{" "}
                                      Excl. BTW
                                    </p>
                                  </div>

                                  <div className="d-flex flex-column">
                                    <label className="inputTitle text-small">
                                      Sale prijs
                                    </label>
                                    <input
                                      type="number"
                                      className="inputField standardSalePrice placeholderStyle"
                                      placeholder="0,00"
                                      name="sale_price"
                                      value={salePrice}
                                      onChange={(e) =>
                                        setSalePrice(e.target.value)
                                      }
                                    />
                                    <p className="bottomText">
                                      €{" "}
                                      {(
                                        (salePrice / 100) *
                                        (100 - (btw == 1 ? 21 : 9))
                                      ).toFixed(2)}{" "}
                                      Excl BTW
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex flex-row justify-content-between standardSaleParent">
                                  <div className="d-flex flex-column heightSalePrice mt-1">
                                    <label className="inputTitle text-small text-nowrap">
                                      Inkoopprijs excl. BTW
                                    </label>
                                    <input
                                      type="number"
                                      className="inputField buyPrice placeholderStyle"
                                      placeholder="0,00"
                                    ></input>
                                  </div>
                                  <div className="d-flex flex-column heightSalePrice mt-1">
                                    <label className="inputTitle text-small text-nowrap">
                                      Marge excl. BTW
                                    </label>
                                    <input
                                      type="number"
                                      className="inputField buyPrice placeholderStyle"
                                      placeholder="€ -"
                                      disabled
                                    ></input>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column sellChannelCategories">
                        <div className="whiteBox p-3 boxSellChannel mb-4 me-3">
                          <div className="d-flex flex-column">
                            <h5 className="inputTitle mb-4">Verkoopkanalen</h5>
                            <div className="px-2">
                              <div className="d-flex flex-row mb-3 me-3 slidersSellchannel justify-content-between">
                                <h6 className="switchText">Bedrijfsnaam</h6>
                                <label className="switch ms-4">
                                  <input type="checkbox" />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div className="d-flex flex-row mb-3 me-3 slidersSellchannel justify-content-between">
                                <h6 className="switchText">Bedrijfsnaam</h6>
                                <label className="switch ms-4">
                                  <input type="checkbox" />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <div className="d-flex flex-row mb-3 me-3 slidersSellchannel justify-content-between">
                                <h6 className="switchText">Bedrijfsnaam</h6>
                                <label className="switch ms-4">
                                  <input type="checkbox" />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="whiteBox boxSellChannel">
                          <div className="d-flex flex-column">
                            <div className=" widthBoxes w-100 mb-2 p-3 categories">
                              <div className='d-flex  justify-content-between '>
                                <h5 className="inputTitle mb-4">
                                  Categorieën
                                </h5>
                                <div className='pt-1' onClick={() => setShow(true)}><FontAwesomeIcon className='inputTitle hover' icon={faPlus}/></div>
                              </div>
                              <div className="sellChannelBoxContainer">
                                <div className="categorieënBox">
                                  <input
                                    type="text"
                                    value={searchQuery}
                                    onChange={(e) =>
                                      setSearchQuery(e.target.value)
                                    }
                                    placeholder="Search products"
                                    className="w-100 searchInput"
                                  />
                                  {/* <ProductsList
                                    products={filteredProducts}
                                    checkedProducts={checkedProducts}
                                    toggleProduct={toggleProduct}
                                  /> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`whiteBox my-5 py-4 ps-4 pe-5 fitContent widthBoxUploader`}
                  >
                    <div>
                      <label className="inputTitle mb-2">Media Uploaden</label>
                      <div className="fileUploader fileUploaderWidthHeight">
                        <DndContext
                          sensors={sensors}
                          collisionDetection={closestCenter}
                          onDragEnd={handleDragEnd}
                        >
                          <div
                            className="mediaUploader"
                            onDragEnter={handleDragEnter}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                            onClick={handleClick}
                            onMouseDown={handleMouseDown}
                            onMouseUp={handleMouseUp}
                          >
                            {uploading || downloading ? (
                              <div className="uploadScreenMU">
                                <div className="uploadScreenContainerMU">
                                  <div
                                    className="spinner-border me-2"
                                    role="status"
                                  >
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                  <span className="uploadScreenTextMU">
                                    {uploadImgStatus}
                                  </span>
                                  <span className="uploadScreenTextMU uploadScreenSubTextMU">
                                    (
                                    {uploading
                                      ? `${uploadCount} / ${images.length}`
                                      : downloading
                                      ? `${uploadCount} / ${downloadCount}`
                                      : ""}
                                    )
                                  </span>
                                </div>
                              </div>
                            ) : null}
                            <input
                              id="fileInput"
                              ref={fileInputRef}
                              hidden
                              type="file"
                              onChange={handleFileUpload}
                              multiple
                            />
                            <div className="textForTheMU">
                              Sleep of selecteer uw media-bestanden om te
                              uploaden
                            </div>
                            <SortableContext
                              items={images}
                              strategy={rectSortingStrategy}
                            >
                              <Grid columns={4}>
                                {images.map(
                                  (
                                    { id, file, name, error, colorId },
                                    index
                                  ) => (
                                    <SortablePhoto
                                      key={id}
                                      error={error}
                                      required={requiredCheck}
                                      index={index}
                                      colorId={colorId}
                                      file={file}
                                      name={name}
                                      handleDelete={handleDelete}
                                      handleColorChange={handleColorChange}
                                      colorOptions={colors}
                                      images={images}
                                      position={id}
                                      id={id}
                                    />
                                  )
                                )}
                              </Grid>
                            </SortableContext>
                          </div>
                        </DndContext>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OldEditPage;
