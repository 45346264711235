import React from 'react'

const FourthBox = (props) => {
  return (
    <>
        <div className="whiteBox p-5 m-5 mt-0 align-center">
            <h4>Leveranciers</h4>
            <div className=''>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Nummer</th>
                            <th>Leverancier</th>
                            <th>Website</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.suppliers.map(supplier => 
                        <tr key={supplier.supplier_id} onClick={() => console.log(supplier.internal_id)}>
                            <td className='py-3 ps-2'>
                                <input type='checkbox'
                                
                                />
                            </td>
                            <td className='py-3 ps-2'>{supplier.internal_id}</td>
                            <td>{props.businesses.filter(bus => bus.internal_id == supplier.internal_id).map(busi => busi.company_name)}</td>
                            <td><a className='btn btn-warning h-50' target='_' href={props.businesses.filter(bus => bus.internal_id == supplier.internal_id).map(busi => busi.url)}>Bezoek website</a></td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    </>
  )
}

export default FourthBox