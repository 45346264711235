import React from 'react'


const CheckboxCell = ({row, handleSelection, selectedIds, pk}) => {
    const isSelected = selectedIds.includes(row.original[pk]);
    return (
      <label className="checkbox-container">
        <input
        type="checkbox"
        checked={isSelected}
        onChange={e => handleSelection(row.original[pk], e.target.checked)}
        />
        <span className="checkmark"></span>
      </label>
    );
}

export default CheckboxCell