import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './sidebar.css';
import Dashboard from './Icons/Dashboard.svg';
import Customers from './Icons/Customer.svg';
import Orders from './Icons/Order.svg';
import Payments from './Icons/Payments.svg';
import Warehouse from './Icons/Warehouse.svg';
import envelope from './Icons/envelope.png';
import globe from './Icons/globe.png';
import gear from './Icons/gear.png';
import money from './Icons/money.png';
import calendar from './Icons/calendar.png';
import sun from './Icons/sun.svg';
import moon from './Icons/moon.svg';
import logo from './Logo Unified SMB - Wit.svg';
import warehouseInstellingen from './Icons/distribution-center.png';
import { removeLoginCookies } from '../../Login/Login';
import TDSTextNavigation from '../../Templates/TDSTextNavigation';
import { faAngleLeft, faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Sidebar = ({ shortcuts, callbackFuncIsLoading, contentClick }) => {
  const location = useLocation();
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
  const [iosDevice, setIosDevice] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    callbackFuncIsLoading(false);

    const handleResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setIsActive(false);
  }, [windowSize[0]]);

  useEffect(() => {
    setIsActive(false);
  }, [contentClick]);

  useEffect(() => {
    // Check if it's an iOS device
    setIosDevice(isIOSDevice());
  }, []);

  const isIOSDevice = () => {
    return /iPad|iPhone|iPod/.test(navigator.platform) ||
           (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  }


  const SidebarLinks = [
    { to: '/', text: 'Dashboard', icon: <img src={Dashboard} alt="Fast truck" className='iconsSidebar text-white' />, dropdown: false, subPagesPath: [] },
    { to: '/klanten', text: 'Customers', icon: <img src={Customers} alt="Fast truck" className='iconsSidebar text-white' />, dropdown: false, subPagesPath: [] },
    { to: '/bestellingen', text: 'Orders', icon: <img src={Orders} alt="Fast truck" className='iconsSidebar text-white' />, dropdown: false, subPagesPath: [] },
    { to: '/verkoopkanalen', text: 'Payments', icon: <img src={Payments} alt="Fast truck" className='iconsSidebar text-white' />, dropdown: false, subPagesPath: [] },
    { to: '/producten', text: 'Warehouse', icon: <img src={Warehouse} alt="Fast truck" className='iconsSidebar text-white' />, dropdown: false, subPagesPath: [] },
    { to: '/warehouse/instellingen', text: 'Warehouse-instelingen', icon: <img src={warehouseInstellingen} alt="Fast truck" className='iconsSidebar text-white' />, dropdown: false, subPagesPath: [] },
    // { to: '/instellingen', text: 'Instellingen', icon: <img src={gear} alt="Fast truck" className='iconsSidebar text-white' />, dropdown: false, subPagesPath: []  },
    // { to: '/algemene/instellingen', text: 'Algemene instellingen', icon: <img src={gear} alt="Fast truck" className='iconsSidebar text-white' />, dropdown: true, subPagesPath: ['gebruikers', 'rechten']  },
  ];

  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const logout = queryParameters.get("logout");

  if (logout) {
    removeLoginCookies(navigate);
  }

  const isActiveLink = (linkPath, subPages) => {
    const locationPath = urlDecoding(location.pathname);
    // Split paths into segments
    const locationSegments = locationPath.split('/');
    const linkPathSegments = linkPath.split('/');

    // Compare base paths (excluding dynamic segments)
    const basePathMatches = locationSegments.slice(0, linkPathSegments.length).join('/') === linkPath;
    
    const isSubpageActive = subPages?.some(subpage => locationPath.includes(`${linkPath}/${subpage}`));
    return basePathMatches || isSubpageActive;
};


  const shouldShowDropdown = (dropdown, subPagesPath, to) => {
    return dropdown && subPagesPath.length > 0 && !isActiveLink(to, subPagesPath);
  };

  const urlDecoding = urlPath => {
    return urlPath.replace('%C3%AB', 'ë');
  };

  let locationLastItem = location.pathname.split('/').pop();

  const SidebarLink = ({ to, text, icon, dropdown, subPagesPath }) => (
    <div className={`my-2 icon-container d-flex flex-nowrap flex-column ${iosDevice ? "lastSidbarItemPlacment" : ""} ${isActiveLink(to, subPagesPath) ? 'activeSidebarItem ' : ''}`}>
      <Link className='text-decoration-none d-flex flex-nowrap iconsCenter px-4 w-100 align-items-center' to={to}>
        <p className={`text-white alignCenter mb-0 iconSidebarCenter  ${isActiveLink(to) ? 'active-icon' : ''}`}>{icon}</p>
        <p className={`icon-text alignCenter ${isActiveLink(to) ? 'active-text' : ''}`}>{text}</p>
      </Link>
      {shouldShowDropdown(dropdown, subPagesPath, to) ? (
        <div className="dropdownSidebar ">
          <div className='dropdownSidebarContent'>
            {subPagesPath?.map((linkPath, index) => (
              <TDSTextNavigation key={index} text={`${linkPath.charAt(0).toUpperCase()}${linkPath.slice(1)}`} link={`${to}/${linkPath}`} extraClassNames={"ps-3 textColorSidebarDropdown"} />
            ))}
          </div>
        </div>
      ) : (
        <div>
          {dropdown ? (
            <div>
              <ul className='pb-2 d-flex flex-column mb-0 subLinksList'>
                {subPagesPath?.map((linkPath, index) =>
                  <TDSTextNavigation key={index} text={`${linkPath.charAt(0).toUpperCase()}${linkPath.slice(1)}`} link={`${to}/${linkPath}`} extraClassNames={`ms-2 text-black subPages px-3 ${urlDecoding(locationLastItem) === linkPath ? 'pageIndicator' : ''}`} />
                )}
              </ul>
            </div>
          ) : ""}
        </div>
      )}
    </div>
  );

  return (
    <>
      {/* {windowSize[0] <= 1112 ? (
        <div>
          <button className="hamburger-button" onClick={() => setIsActive(!isActive)}>
            ☰
          </button>
          <div className={isActive ? 'burgerSidebar open' : 'burgerSidebar'} onBlur={() => setIsActive(!isActive)}>
            <div className='w-100 d-flex justify-content-between flex-row-reverse mb-5'>
              <button className='btn text-Black fw-bold' onClick={() => setIsActive(!isActive)} style={{height: "fit-content"}}>X</button>   
              <img className='logo d-flex justify-content-center' src={logo} alt='logo'></img>
            </div>
            <div>
              {SidebarLinks.map((link, index) => (
                <SidebarLink key={index} to={link.to} text={link.text} icon={link.icon} dropdown={link.dropdown} subPagesPath={link.subPagesPath}/>
              ))}
              {shortcuts.map((link, index) => (
                <SidebarLink key={index} to={link.to} text={link.text} icon={link.icon} dropdown={link.dropdown} subPagesPath={link.subPagesPath}/>
              ))}
            </div>
          </div>
        </div>
      ) : ( */}
        <div className='widthSidebar'>
          <nav className='sidebar'>
            <div className='d-flex justify-content-center px-2 py-3 mb-5'>
              <img className='logo d-flex justify-content-center' src={logo} alt='logo'></img>
            </div>
            <div className='d-flex flex-column align-items-center'>
              {SidebarLinks.map((link, index) => (
                <SidebarLink key={index} to={link.to} text={link.text} icon={link.icon} dropdown={link.dropdown} subPagesPath={link.subPagesPath}/>
              ))}
              {shortcuts.map((link, index) => (
                <SidebarLink key={index} to={link.to} text={link.text} icon={link.icon} dropdown={link.dropdown} subPagesPath={link.subPagesPath}/>
              ))}
            </div>
            <div className='position-absolute bottom-0 w-100 d-flex justify-content-center flex-column mb-3'>
              <div className=' d-flex justify-content-center'>
                {/* <div className=' d-flex flex-row mb-3'>
                  <img src={moon} className='modeToggleIcons d-flex align-self-center'></img>
                  <div className='d-flex align-self-center mx-2'>
                    <label className="switchMode">
                      <input type="checkbox" defaultChecked/>
                      <span className="sliderMode round"></span>
                    </label>
                  </div>
                  <img src={sun} className='modeToggleIconsSun'></img>
                </div> */}
              </div>
              <div className='d-flex justify-content-center'>
                <div className='d-flex flex-row'>
                  <FontAwesomeIcon className='d-flex align-self-center text-white me-2' icon={faCircleChevronLeft} />
                  <p className='text-white mb-0 collapseText'>Menu invouwen</p>
                </div>
              </div>
            </div>
          </nav>
        </div> 
    </>
  );
};

export default Sidebar;