import React from 'react'

const SecondBox = (props) => {
  return (
    <>
      {/* Second Box */}
      <div className="whiteBox p-5 m-5">
        <div className="d-flex justify-content-between">
          <h4>Prijzen</h4>
          <select value={props.taxSelect} onChange={e => props.setTaxSelect(e.target.value)} className=" productInputWidth w-25">
            <option
              value={0}
              hidden={props.taxSelect == 0}
            >Incl. BTW</option>
            <option
              value={1}
              hidden={props.taxSelect == 1}
            >Excl. BTW</option>
          </select>
        </div>
        {/* bovenste rij prijzen box */}
        <div className="d-flex flex-row justify-content-between">
          {/* standaardprijs veld */}
          <div className="d-flex flex-column w-50">
          <label className="inputTitle">Standaard Prijs ({props.taxSelect == 0 ? "Incl" : "Excl"}. BTW)</label>
          <input 
            value={props.ShowPrice("price")}
            onChange={e => props.UpdatePrice(e, "price")}
            type="text" // Change type to text to allow non-numeric characters
            placeholder="--,--" 
            className=" w-25 productInputWidth"
          />
          <label className="text-secondary ms-1">
              €{props.ShowPrice("price", true, true)} {props.taxSelect !== 0 ? "In" : "Ex"}cl. BTW
          </label>
        </div>
        {/* standaardprijs veld eind */}
        {/* kortingsprijs veld */}
          <div className="d-flex flex-column w-50">
            <label className="inputTitle">Kortings Prijs ({props.taxSelect == 0 ? "Incl" : "Excl"}. BTW)</label>
            <input 
              value={props.prices.filter(filter => filter.type == "sale_price").map(map => map.incl_tax)}
              onChange={e => {
                const index = props.prices.findIndex(price => price.type == "sale_price")
                if(index) {
                  const updated = [...props.prices]
                  updated[index][!props.taxSelect == 0 ? "incl_tax" : "excl_tax"] = e.target.value
                  props.setPrices(updated)
                }
              }}
              type="number" 
              placeholder="--,--" 
              className=" w-25 productInputWidth"
            />
            <label className="text-secondary ms-1">€ {props.ShowPrice("sale_price", true, true)} {props.taxSelect !== 0 ? "In" : "Ex"}cl. BTW</label>
          </div>
        {/* kortingsprijs veld eind*/}
        </div>
        {/* bovenste rij prijzen box eind*/}
        {/* onderste rij prijzen box*/}
        <div className="d-flex flex-row justify-content-between border-top pt-2 mt-2">
          {/* inkoopprijs box */}
          <div className="d-flex flex-column w-50">
            <label className="inputTitle">Inkoopprijs Prijs (Excl. BTW)</label>
            <input 
              value={Number(props.prices.filter(filter => filter.type == "buy_in_price").map(map => map.excl_tax))}
              onChange={e => {
                const index = props.prices.findIndex(price => price.type == "buy_in_price")
                if(index) {
                  const updated = [...props.prices]
                  updated[index].incl_tax = e.target.value
                  props.setPrices(updated)
                }
              }}
              type="number" 
              placeholder="--,--" 
              className=" w-25 productInputWidth"/>
            {/* <label className="text-secondary ms-1">€ --,-- Excl BTW</label> */}
          </div>
          {/* inkoopprijs box eind */}
          {/* marge box */}
          <div className="d-flex flex-column w-50">
            <label className="inputTitle">Marge (Excl. BTW)</label>
            <input 
              value={`€ ${(props.ShowPrice("price") - props.ShowPrice('buy_in_price')).toFixed(2)}`}
              disabled 
              className=" w-25 productInputWidth" 
              style={{backgroundColor: "lightgray", userSelect: "none"}} />
          </div>
          {/* marge box eind */}
        </div>
        {/* onderste rij prijzen box*/}
        {/* onderste rij prijzen box eind*/}
      </div>
    {/* Second Box end */}
  </>
  )
}

export default SecondBox