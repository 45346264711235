import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const nav = useNavigate();
  return (
    <div className='m-5 text-center'>
      <h1>404</h1>
      <h1>Oops looks like this page does not exist</h1>
      <div className='d-flex flex-row justify-content-center gap-1'>
        <div>Go</div>
        <div className='link-primary hover text-decoration-underline' onClick={e => nav(-1)}>back</div>
      </div>
    </div>
  );
}

export default NotFound;