import React, { useState, useEffect, useCallback, useRef } from "react";
import InputComponent from '../../AddCustomers/InputComponent'

const GeneralInfoTab = ({ typeCustomer }) => {

  const [showCustomer, setShowCustomer] = useState(false);
  const [countryInfo, setCountryInfo] = useState({});
  const [countryIdInfo, setCountryIdInfo] = useState([]);
  const [prefixId, setPrefixId] = useState();
  const [formData, setFormData] = useState({});
  const formRef = useRef(null); // Step 1: Create a ref for the form

  console.log(typeCustomer);
  const normalizeLabel = (label) => {
    // Verwijder spaties aan het begin en einde, verwijder alle tussenliggende spaties en streepjes
    return label.trim().replace(/[\s\-]+/g, "");
  };

  const getValueInput = useCallback((value, label) => {
    // Normaliseer het label om consistentie te waarborgen
    const normalizedLabel = normalizeLabel(label);

    setFormData((prev) => ({ ...prev, [normalizedLabel]: value }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Voorkom de standaardactie van het formulier

    
  };

  return (
    <div className="px-4 py-4">
      <form className="col-12" ref={formRef} onSubmit={handleSubmit}>
        {typeCustomer == "B" ?
          <div>
            <label className=' fw-bold mb-2 secondaryTitles'>Bedrijfsgegevens</label>
            <div className='col-12 d-flex flex-row align-content-end mb-3'>
              <InputComponent
                type={"text"}
                onValueChange={getValueInput}
                alignmentInputs={"justify-content-start"}
                parentCol={12}
                labelName={"Bedrijfsnaam"}
                classnamesInput={"col-12 SearchIcon px-3"}
              />
            </div>
            <div className="col-12 d-flex flex-row align-content-end mb-4">
              <InputComponent
                type={"text"}
                onValueChange={getValueInput}
                alignmentInputs={"justify-content-start"}
                parentCol={6}
                labelName={"KVK nummer"}
                classnamesInput={"col-12 widthInputComponents SearchIcon px-3"}
              />
              <InputComponent
                type={"text"}
                alignmentInputs={"justify-content-end"}
                parentCol={6}
                onValueChange={getValueInput}
                labelName={"BTW nummer"}
                classnamesInput={"col-12 widthInputComponents px-3"}
              />
            </div>
          </div>
        :
          ""
        }
        <div>
          <label className={`fw-bold mb-2 secondaryTitles ${typeCustomer == "B" ? "" : "d-none"}`}>Contactpersoon</label>
          <div className="col-12 d-flex flex-row align-content-end mb-3">
            <InputComponent
              type={"text"}
              onValueChange={getValueInput}
              alignmentInputs={"justify-content-start"}
              parentCol={6}
              labelName={"Voornaam"}
              classnamesInput={"col-12 widthInputComponents px-3"}
            />
            <InputComponent
              type={"text"}
              alignmentInputs={"justify-content-end"}
              parentCol={6}
              onValueChange={getValueInput}
              labelName={"Achternaam"}
              classnamesInput={"col-12 widthInputComponents px-3"}
            />
          </div>
          <div className="col-12 d-flex flex-row align-content-end my-3 ">
            <InputComponent
              type={"email"}
              onValueChange={getValueInput}
              alignmentInputs={"justify-content-start"}
              parentCol={6}
              AddFunc={() => console.log("Email")}
              AddIcon={true}
              labelName={"E-mailadres"}
              classnamesInput={"col-12 widthInputComponents px-3"}
            />
            <InputComponent
              type={"tel"}
              alignmentInputs={"justify-content-end"}
              parentCol={6}
              onValueChange={getValueInput}
              labelName={"Telefoonnummer"}
              classnamesInput={"col-12 widthInputComponents px-3"}
            />
          </div>
          <div className="col-12 d-flex flex-row align-content-end my-3">
            <InputComponent
              type={"date"}
              onValueChange={getValueInput}
              parentCol={6}
              labelName={"Geboortedatum"}
              classnamesInput={"col-12 widthInputComponents diffDateIcon px-3"}
            />
            <div className="col-6 d-flex justify-content-start">
              <div className="col-4 d-flex flex-column">
                <label className="mb-2 ms-5 fw-medium">Nieuwsbrief</label>
                <label className="switchNewCustomer ms-4">
                  <input type="checkbox" />
                  <span className="sliderNewCustomer round"></span>
                </label>
              </div>
              <div className=" col-4  d-flex flex-column">
                <label className="mb-2 ms-5 fw-medium">Verjaardagactie</label>
                <label className="switchNewCustomer ms-4">
                  <input type="checkbox" />
                  <span className="sliderNewCustomer round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default GeneralInfoTab;
