import React, { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import middleware from "../Api/Middleware";
import "./product.css";
import { useNavigate, useLocation } from 'react-router';
import "../Main/Main.css";
import NavbarIcon from "../Navbars/Sidebar/Icons/globe.png"
import Loading from '../LoadingScreen/LoadingScreen'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import Cookies from "universal-cookie";
import PageTemplate from "../Templates/PageTemplate";
import TablePreset from "../Table/TablePreset";
import SortableHeader from '../Table/TableComponents/SortableHeader';
import CheckboxHeader from "../Table/TableComponents/CheckboxHeader";
import CheckboxCell from "../Table/TableComponents/CheckboxCell";

//modals
import Modals from './subpages/sub_components/VariationModals';

function Products() {

  const navigate = useNavigate();
  const location = useLocation();
  const cookies = new Cookies();
const [loading, setLoading] = useState(true); // useState for page loading
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(true); // useState for the Loadingscreen
  const [producten, setProducten] = useState([]); // useState for all products in the database
  const [barcodes, setBarcodes] = useState([]); // useState for all products in the database
  const [stock, setStock] = useState([]); // useState for the stock of every Product in the database
  const [prices, setPrices] = useState([]); // useState for the prices of every Product in the database
  const [pagination, setPagination] = useState({
    "page_size": 10,
    "page": 1,
    "max_items": 0,
    "begin_of_page": 0,
    "end_of_page": 0,
  }); // the useState for the pagination for the table
  const [order, setOrder] = useState({orderBy: '', direction: ''}); // useState for ordering the columns in the table
  const [selectedProductIds, setSelectedProductIds] = useState([]); // usestate for the selected checkboxes
  const [fullyShownProducts, setFullyShownProducts] = useState([]); // usestate fpr the productname that u can fully show and shortend it
  const [showBlueprintModal, setShowBlueprintModal] = useState(false);
  const [inputValue, setInputValue] = useState();
  const [timerId, setTimerId] = useState(null);
  const [productsSearchengine, setProductsSearchengine] = useState('');
  const [productsSearch, setProductsSearch] = useState('');
  const [show, setShow] = useState(false);
  const [showReActivate, setShowReActivate] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  
  // fetched all the products and the stock an the colors from the database
  const fetchAll = async () => {
    try {
      setLoading(true);
      const [fetchProducts, fetchStock, fetchBarcode, fetchPrices] = await Promise.all([
        middleware.get(`${location.pathname === "/producten/alles" ? 'sp/productsTableAll?' : 'sp/productsTableActive?' }page=${pagination.page}&page_size=${pagination.page_size}${order.orderBy !== '' && order.direction !== '' ? `&orderBy=${order.orderBy}&direction=${order.direction}` : ''}`),
        middleware.get("products/stock"),
        middleware.get("products/barcodes"),
        middleware.get("products/prices"),

      ]);
      setBarcodes(fetchBarcode.data)
      setProducten(fetchProducts.data.data); // sets the the data in the products usestate
      setPagination({...pagination, "max_items": fetchProducts.data.total, "begin_of_page": fetchProducts.data.from, "end_of_page": fetchProducts.data.to,}) // sets the pagination usestate with the max items begin of the page and the end of the page
      setStock(fetchStock.data); // sets the stock usestate with all the stocks of the products
      setPrices(fetchPrices.data);
      setLoadingScreenFunc();
      setLoading(false);
    } catch (error) {
      if (error.response.status === 401) {
        cookies.remove('token', { path: '/' });
        navigate('/');
      }
    }
  };


  // fetches all the data when pagination.page_size, pagination.page, order or location.pathname changes
  useEffect(() => {
    fetchAll();
  }, [pagination.page_size, pagination.page, order, location.pathname]);

  //a useEffect that makes the search work 
  useEffect(() => {
    if(productsSearchengine !== '') {
        const doIt = async() => {
            const [fetchProducts] = await Promise.all([
                middleware.get(`${location.pathname === "/producten/alles" ? 'sp/productsTableAll?' : 'sp/productsTableActive?' }page=${pagination.page}&page_size=${pagination.page_size}&searchTerm=${productsSearchengine}&noSearch=["product_id","product_description","updated_at","created_at"]`),
            ]);
            setProducten(fetchProducts.data.data)
            setPagination({...pagination, "max_items": fetchProducts.data.total, "begin_of_page": fetchProducts.data.from, "end_of_page": fetchProducts.data.to,}) // sets the pagination usestate with the max items begin of the page and the end of the page
        }
        doIt()
      } else {
        const doIt = async() => {
          const [fetchProducts] = await Promise.all([
              middleware.get(`${location.pathname === "/producten/alles" ? 'sp/productsTableAll?' : 'sp/productsTableActive?' }page=${pagination.page}&page_size=${pagination.page_size}`),
          ]);
          setProducten(fetchProducts.data.data)
          setPagination({...pagination, "max_items": fetchProducts.data.total, "begin_of_page": fetchProducts.data.from, "end_of_page": fetchProducts.data.to,}) // sets the pagination usestate with the max items begin of the page and the end of the page
        }
        doIt()
      }
  }, [productsSearchengine])
  // const closeBlueprintModal = () => {
  //   setShowBlueprintModal(false);
  // }

  // sets loading on false an waits 0.5 sec to also set the Loadingscreen to false
  function setLoadingScreenFunc() {
    setLoading(false);
    setTimeout(() => {
      setLoadingScreen(false);
    }, 500);
  }

  // Handle checkbox selection
  const handleProductSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedProductIds([...selectedProductIds, productId]);
    } else {
      setSelectedProductIds(selectedProductIds.filter(id => id !== productId));
    }
  };

  // a function that delays 
  function delayedFunction(Func) {
    setTimeout(() => {
      Func()
    }, 500);
  }

  // a function that creates a new modal and sets the state to show
  // const click = (e) => {
  //   const modal = new Modal(document.getElementById('exampleModal'));
  //   modal.show();
  // }

  // Handle checkbox selection
  const handleSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedIds(previous => [...previous, productId]);
    } else {
      setSelectedIds(previous => previous.filter(id => id !== productId));
    }
  };
  
  useEffect(() => {
    setProducten([]);
    setTimeout(() => {
      setProducten(producten);
      setLoading(false);
    }, 1);
  }, [fullyShownProducts]);
  //columns for the table
  const columns = useMemo(
    () => {
      let columnsArray = [
        {
            Header: (
              <CheckboxHeader currentItem={order} selectedIds={selectedIds} setSelectedIds={setSelectedIds} pk={'product_id'}>
              </CheckboxHeader>
            ),
            accessor: "select",
            Cell: ({ row }) => {
            return <CheckboxCell row={row} handleSelection={handleSelection} selectedIds={selectedIds} pk={'product_id'}/>
            }
        },
        {
            Header: (
              <SortableHeader currentItem={order} setItem={setOrder} field={'image'} pos={'center'}>
                Afbeelding
              </SortableHeader>
            ),
            accessor: "image",
            Cell: ({ row }) => {
              return <FontAwesomeIcon icon={faCamera} color="#e2eefb" size="3x" className="align-self-center"/>
            }
        },
        {
          Header: () => (
            <SortableHeader currentItem={order} setItem={setOrder} field={'product_name'} pos={'start'}>
              Productnaam
            </SortableHeader>
            // <div 
            //   onClick={e => setOrder({...order, orderBy: "product_name" , direction: `${order.direction === 'asc' ? (order.orderBy === "product_name" ? 'desc' : 'asc') : order.direction === 'desc' ? order.orderBy === 'product_name' ? '' : 'asc' : 'asc'}`})} 
            // className="header-filter">
            //   Naam {order.orderBy === 'product_name' ? 
            //   (order.direction === 'asc' ? <FontAwesomeIcon icon={faCaretUp} /> : order.direction === 'desc' ? <FontAwesomeIcon icon={faCaretDown}/> : '')
            //   : ''}
            // </div>
          ),
          accessor: 'product_name',
          Cell: ({ row }) => {
              return (
                  <span>
                      {row.original.product_name.length > 100 ? (
                          fullyShownProducts.some(element => element === row.original.product_id) ?
                          <>
                              <span className="linkColor" onClick={e => {
                                  e.stopPropagation(); 
                                  navigate(`/producten/bewerken/${row.original.product_id}`)
                              }}>
                                  {row.original.product_name}
                              </span>
                              <span 
                                  onClick={e => {
                                      e.stopPropagation();
                                      console.log(fullyShownProducts.some(element => element === row.original.product_id));
                                      setFullyShownProducts(fullyShownProducts.filter(id => id !== row.original.product_id));
                                  }} 
                                  className="text-info hover text-nowrap ms-1">
                                      Toon minder
                              </span>
                          </>
                          :
                          <>
                              <span className="linkColor" onClick={e => {
                                  e.stopPropagation(); 
                                  navigate(`/producten/bewerken/${row.original.product_id}`)
                              }}>
                                  {row.original.product_name.slice(0, 90)}
                              </span>
                              <span 
                                  onClick={e => {
                                      setFullyShownProducts([...fullyShownProducts, row.original.product_id]);
                                      setLoading(true);
                                      console.log(fullyShownProducts.some(element => element === row.original.product_id));
                                  }} 
                                  className="text-info hover text-nowrap ms-2">
                                  (...)
                              </span>
                          </>
                      ) 
                      : 
                      <span className="linkColor" onClick={e => {
                          e.stopPropagation(); 
                          navigate(`/producten/bewerken/${row.original.product_id}`)
                      }}>
                          {row.original.product_name}
                      </span>}
                  </span>
              );
          },
        },
        {
          Header: () => (

          <SortableHeader currentItem={order} setItem={setOrder} field={'price_incl_tax'} pos={'center'}>
            Prijs (inclusief BTW)
          </SortableHeader>
          ),
            accessor: "price_incl_tax",
            Cell: ({ row }) => {
              const priceDetail = prices.find(price => price.product_id === row.original.product_id) || {};
              const price_excl_tax = priceDetail.excl_tax || 0;
              const price_incl_tax = priceDetail.incl_tax || 0;
              return <div className="text-center">€ {price_incl_tax.toFixed(2)}</div>;
            }
        },
        {
          Header: () => (
            <SortableHeader currentItem={order} setItem={setOrder} field={'stock'} pos={'center'}>
              Voorraad
            </SortableHeader>
            ),
          accessor: "stock",
          Cell: ({ row }) => {
            const stockAmount = stock.filter(filter => filter.product_id == row.original.product_id).map(map => map.amount)
            return <div className="text-center">{stockAmount.length !== 0 ? stockAmount : "0"}</div>;
          }
        },
      ];
        return columnsArray; 
    },
    [order.orderBy, order.direction, selectedIds, setSelectedIds, producten]
  );

  //   Table instance
  const tableInstance = useTable({ columns, data: producten || [] });

  // Destructure table properties
  // const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  function handleTdClick(index, row, cell) {
    // Als het de checkbox-kolom is
    if (index === 0) {
        const isSelected = selectedProductIds.includes(row.original.product_id);
        if (isSelected) {
            handleProductSelection(row.original.product_id, false);
        } else {
            handleProductSelection(row.original.product_id, true);
        }
        return;
    }
    
    // Als het de product_name kolom is
    if (cell.column.id === 'product_name') {
        return;
    }

    // Voor alle andere kolommen
    navigate(`/producten/bewerken/${row.original.product_id}`);
}

  // const barcodeSearch = () => {
  //   const barcodeInfo = barcodes.filter(b => b.barcode === inputValue);
  //   // console.log(barcodeInfo[0].product_id);
  //   const productInfo = producten.filter(p => p.product_id === 1246);
  //   console.log(productInfo);

  //   // const id = barcode.map((barcode) => barcode.barcode)
  //   // const productIdBarcode = barcode.map((barcode) => barcode)
  //   // const productIds = producten.map((products) => products.product_id)


  //   // id.forEach(barcodes => {
  //   //   if (barcodes == inputValue) {
  //   //     console.log(productIdBarcode);
  //   //     const barcodeProductId = barcode.map((barcode) => barcode.product_id)
  //   //     console.log(barcodeProductId);
  //   //     productIdBarcode.forEach((barcodes1) => {
  //   //       // if (barcodes1 == ) {
            
  //   //       // }
  //   //     })
        
  //   //   }
  //   // });
  // }
  useEffect(() => {
    if(productsSearch != "") {
      if (timerId) {
          clearTimeout(timerId);
      }
      const newTimerId = setTimeout(() => {
          setPagination({...pagination, page: 1})
          setProductsSearchengine(productsSearch)
      }, 500);
      setTimerId(newTimerId);
    }
  }, [productsSearch])


  const searchDelay = async(value, setSearchEngine, setInput) => {
    // const value = await e.target.value
    setInput(value);
    if (timerId) {
        clearTimeout(timerId);
    }
    const newTimerId = setTimeout(() => {
        setPagination({...pagination, page: 1})
        setSearchEngine(value)
    }, 500);

    setTimerId(newTimerId);
  }
 
  const suspend = async () => {
    try {
      setShow(false)
        const updatePromises = selectedProductIds
            .filter(filter => producten.some(filt => filt.product_id === filter && filt.status === 0))
            .map(data => 
                middleware.put('products', {
                    product_id: data,
                    status: 1,
                })
            );
        
        await Promise.all(updatePromises);
        window.location.reload();
    } catch(error) {
        console.error('Failed to unsuspend products:', error);
    }
  };

  const unsuspend = async () => {
    try {
      setShowReActivate(false)
        const updatePromises = selectedProductIds
            .filter(filter => producten.some(filt => filt.product_id === filter && filt.status === 1))
            .map(data => 
                middleware.put('products', {
                    product_id: data,
                    status: 0,
                })
            );
        
        await Promise.all(updatePromises);
        window.location.reload();
    } catch(error) {
        console.error('Failed to unsuspend products:', error);
    }
  }

  // a function that selects all the selects in the table
  const select_all = () => {
    setSelectedProductIds(producten.map(product => product.product_id))
  }

  // a function that unselects all the selects in the table
  const unselect_all = () => {
    setSelectedProductIds([...producten, ...producten.filter(cons => !selectedProductIds.includes(cons.product_id)).map(e => e.product_id)])
  }
  
  // handles when and wich buton is clicked
  const handleAction = async (event) => {
    if(event === "suspend") {
      setShow(!show)
    } else if (event === "unsuspend") {
      setShowReActivate(!showReActivate)
    } else if (event === "select-all") {
      select_all();
    } else if (event === "unselect-all") {
      unselect_all();
    }
  }

  return (
    <PageTemplate navbarTitle={"Warehouse HUB"} pageIsLoading={loadingPage} iconNavbar={NavbarIcon}>
      <div className="productOverview">
        <Modals show={show} handleClose={e => setShow(false)} redirect={'/producten/nieuw/variatie'}/>
        <div className="productTable w-100">
          <TablePreset
            data={[{'selectedIds': selectedIds, 'tableInstance': tableInstance, 'pagination': pagination}]}
            tableLoading={loading}
            setPagination={(e) => setPagination(e)} 
            handleSelection={handleSelection} 
            primary_key={'product_id'}
            searchBar={[{ shown: true, value: productsSearch, function: setProductsSearch}]}
            actionOptions={[
              {title: "Selecteer alles", function: () => handleAction('select-all')}, 
              {title: "De-selecteer alles", function: () => handleAction('unselect-all')}
            ]}
            leftButtons={[{title: "Filteren", function: () => ""}]}
            rightButtons={[
              {title: "Nieuw simpel product", function: () => navigate('/producten/nieuw')},
              {title: "Nieuw variatie product", function: () => setShow((true))},
            ]}
          />
        </div>
      </div>
    </PageTemplate>
  );
}

export default Products;