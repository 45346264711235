import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const RouteChangeListener = () => {
    // const location = useLocation();

    // useEffect(() => {
    //     // Function to check if the current path is one of the specified paths
    //     const isOnTargetPath = ['/betalingproviders/details', '/betalingproviders/onboarding'].includes(location.pathname);

    //     // Clear sessionStorage if it is not one of the specified paths
    //     if (!isOnTargetPath) {
    //         // sessionStorage.clear(); // This clears all data in sessionStorage
    //         console.log("Session storage cleared because user navigated away from specific pages.");
    //     }
    // }, [location.pathname]); // Depend on pathname to trigger effect

    // return null; // This component does not render anything
}

export default RouteChangeListener