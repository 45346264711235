import React from 'react';
import "./css/CategoryList.css"

const CategoryList = ({ listId = 1, categories, checkedCategories, toggle, show, textsub, defaultCat }) => {
  const renderCategories = (categories, isDefaultCat = false, isSubcategory = false) => {
    return (
      <ul style={{ listStyleType: 'none' }} className={isSubcategory ? "subcategoryUL" : ""}>
        {categories.map((category) => {
          const id = `${listId}-${category.primary_key}-${category.id}-${category.headId || 0}`;
          const isCategoryChecked = checkedCategories[id] || false;
          const isSubcategoryOfDefaultCat = isDefaultCat || category.id === defaultCat;

          return (
            <li key={id} className={isSubcategory ? "subcategoryItem" : ""}>
              {isSubcategory ? (
                <div className="categoryCheckbox d-flex flex-row">
                  {/* Dit is de checkbox voor subcategorieën */}
                  <div className='branchIndicator'/>
                  <input 
                    type="checkbox" 
                    id={id} 
                    name={id}
                    checked={isCategoryChecked}
                    onChange={() => toggle(id)}
                    disabled={isSubcategoryOfDefaultCat}
                  />
                  <label htmlFor={id} className='user-select-none categoryName'>{category.name}</label>
                </div>
              ) : (
                <>
                <div className='categoryCheckbox'>
                  <input 
                    type="checkbox"
                    id={id} 
                    name={id}
                    checked={isCategoryChecked}
                    onChange={() => toggle(id)}
                    disabled={isSubcategoryOfDefaultCat}
                  />
                  <label htmlFor={id} className='user-select-none'>{category.name}</label>
                </div>
                </>
              )}
              {show && isCategoryChecked && !defaultCat && (
                <div className="subcategoryPlaceholder">
                  <div className='branchIndicator'/>
                  <label className='user-select-none ps-4'>{textsub}</label>
                </div>
              )}
              {category.subcategories && renderCategories(category.subcategories, isSubcategoryOfDefaultCat, true)}
            </li>
          );
        })}
      </ul>
    );
  };

  return renderCategories(categories);
};

export default CategoryList;
