import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import logo from '../../Navbars/Sidebar/Logo Unified SMB - Wit.svg';
import middleware from '../../Api/Middleware';
import ToastError from '../../Toasts/ToastError';
import ToastSuccess from '../../Toasts/ToastSuccess';

const ForgotPassword = () => {
    const navigate = new useNavigate()
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [ip, setIp] = useState('');

    useEffect(() => {
        fetch('https://api.ipify.org?format=json')
            .then(response => response.json())
            .then(data => setIp(data.ip))
            .catch(error => console.error('Error fetching IP:', error));
    }, []);

    const handleSendRequest = async(e) => {
        try {
            e.preventDefault();
            const [sendMailRes] = await Promise.all([
                middleware.post('mail/forgot-password', {
                    email: email,
                    resetUrl: `${window.location.protocol}//${window.location.host}/wachtwoord-resetten`,
                    ip: ip,
                })
            ])
            if(sendMailRes.data.ValidationError) {
                ToastError(sendMailRes.data.ValidationError)
            }
            if(sendMailRes.data.SendMailError) {
                ToastError(sendMailRes.data.SendMailError + ". Probeer het opnieuw om: " + sendMailRes.data.available_at)
            }
            if(sendMailRes.data.Success) {
                setError('')
                ToastSuccess(sendMailRes.data.Success)
            }
        } catch (err) {
            console.warn(err)
        }
    } 

  return (
    <div className='backgroundGradient'>
        <div className='position-fixed btn btn-light rounded-circle m-4' onClick={() => navigate(-1)}><FontAwesomeIcon icon={faArrowLeft}/></div>
        <div className='d-flex justify-content-center align-items-center h-100 flex-column'>
        <form onSubmit={handleSendRequest} className='' noValidate>
            <div className='h3 text-center text-white mb-5'>Verstuur een mail om je wachtwoord te resetten</div>
            <div className="mb-4 input-group-sm">
                <input type='email' name='email' onChange={e => setEmail(e.target.value)} className="form-control inputsUserCredentials" placeholder="Email"/>
            </div>
            <div className='mb-3 input-group-sm'>
                <button type="submit" className="btn w-100 rounded-pill loginButton">Verstuur mail</button>
            </div>
        </form>
        <span className='text-danger fw-bolder fs-5 text-wrap'>{error}</span>
        <span className='text-success fw-bolder fs-5 text-wrap'>{success}</span>
      </div>
    </div>
  )
}

export default ForgotPassword