import React, { useMemo, useState } from 'react';
import { useTable } from 'react-table';
import TablePreset from '../../../Table/TablePreset';
import AddPointsModal from '../CustomerModals/AddPointsModal';
import RemovePointsModal from '../CustomerModals/RemovePointsModal';
import LoyaltyParticipationModal from '../CustomerModals/LoyaltyParticipationModal';

export default function Loyalty() {
  const [isChecked, setIsChecked] = useState(false);
  const [pagination, setPagination] = useState({
    page_size: 10,
    page: 1,
    max_items: 0,
    begin_of_page: 0,
    end_of_page: 0,
  });
  const [order, setOrder] = useState({ orderBy: '', direction: '' });
  const [showAddPointsModal, setShowAddPointsModal] = useState(false);
  const [showRemovePointsModal, setShowRemovePointsModal] = useState(false);
  const [showLoyaltyParticipationModal, setShowLoyaltyParticipationModal] = useState(false);

  const testData = [
    {
      loyalty_id: "1",
      loyaltyTitle: "Notitie titel",
      loyaltyDescription: "kaas",
      loyaltyCreatedDate: "1 april 2023",
      loyaltyCreatedTime: "9:10",
      loyaltyOwner: "Medewerker",
    },
  ];

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setIsChecked(true);
    } else {
      setShowLoyaltyParticipationModal(true);
    }
  };

  const handleDecision = (userDecision) => {
    setIsChecked(!userDecision);
    setShowLoyaltyParticipationModal(false);
  };

  const closeModalLoyaltyParticipation = () => {
    setShowLoyaltyParticipationModal(false);
  };

  const [data, setData] = useState(testData);
  const [selectedIds, setSelectedIds] = useState([]);

  const handleSelection = (productId, isSelected) => {
    if (isSelected) {
      setSelectedIds(previous => [...previous, productId]);
    } else {
      setSelectedIds(previous => previous.filter(id => id !== productId));
    }
  };

  const closeModalAddPoints = () => setShowAddPointsModal(false);
  const closeModalRemovePoints = () => setShowRemovePointsModal(false);

  const columns = useMemo(() => [
    {
      Header: () => (
        <div className='text-center fw-bold'>Datum</div>
      ),
      accessor: 'loyaltyCreatedDate',
      Cell: ({ row }) => (
        <div className="text-center">
          <p className='mb-0 text-nowrap'>{row.original.loyaltyCreatedDate}</p>
          <p>{row.original.loyaltyCreatedTime}</p>
        </div>
      )
    },
    {
      Header: () => (
        <div className='text-center fw-bold'>Punten</div>
      ),
      accessor: 'loyaltyPoints',
      Cell: ({ row }) => (
        <div className="text-center">
          <p className='mb-0'>-100</p>
        </div>
      )
    },
    {
      Header: () => (
        <div className='text-center fw-bold'>Notitie</div>
      ),
      accessor: 'loyaltyNote',
      Cell: ({ row }) => (
        <div className="text-center">
          <p className='mb-0'>Notitie</p>
        </div>
      )
    },
    {
      Header: () => (
        <div className='text-center fw-bold'>Bestellingnummer</div>
      ),
      accessor: 'loyaltyBestelling',
      Cell: ({ row }) => (
        <div className="text-center">
          <p className='mb-0 linkColor'>TDSO-00O123</p>
        </div>
      )
    },
    {
      Header: () => (
        <div className='text-center fw-bold'>Medewerker</div>
      ),
      accessor: 'loyaltyOwner',
      Cell: ({ row }) => (
        <div className="text-center">
          <p className='mb-0'>Naam</p>
        </div>
      )
    },
  ], [order.orderBy, order.direction, selectedIds, setSelectedIds, data]);

  // Table instance
  const tableInstance = useTable({ columns, data: data });

  return (
    <>
      <AddPointsModal openAddPoints={showAddPointsModal} closeAddPoints={closeModalAddPoints} />
      <LoyaltyParticipationModal 
        openLoyaltyParticipation={showLoyaltyParticipationModal} 
        closeLoyaltyParticipation={closeModalLoyaltyParticipation} 
        decision={handleDecision} 
      />
      <RemovePointsModal openRemovePoints={showRemovePointsModal} closeRemovePoints={closeModalRemovePoints} />
      {isChecked ? 
      <div className='p-4 mb-3'>
        <div className='d-flex flex-row justify-content-between mb-3'>
          <div className='d-flex align-items-center'>
            <button className='btn lightGrayStandardButton me-3' onClick={() => setShowAddPointsModal(true)}>+ Punten toevoegen</button>
            <button className='btn lightGrayStandardButton px-3' onClick={() => setShowRemovePointsModal(true)}>- Gebruik / verwijder punten</button>
          </div>
          <div className=''>
            <div className="d-flex flex-column align-content-center">
              <label className="fw-medium">Deelnemen</label>
              <label className="switchNewCustomer d-flex align-self-center">
                <input 
                  type="checkbox" 
                  checked={isChecked}
                  onChange={handleCheckboxChange} 
                />
                <span className="sliderNewCustomer round"></span>
              </label>
            </div>
          </div>
        </div>
        <div className='px-4'>
          <div>
              <p className='fw-medium mb-0'>Puntentotaal: 0</p>
              <p className='fw-medium'>Aangemeld: 10 april 2024</p>
          </div>
          <div>
            <div className='loyaltyTable'>
              <div className="my-3 tablesCustomerEdit h-100">
                <TablePreset
                  data={[{'tableInstance': tableInstance, 'pagination': pagination, 'primary_key': 'order_id'}]} 
                  setPagination={setPagination} 
                  handleSelection={handleSelection}
                  noCheckbox={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      :
      <div className='p-4 mb-3 d-flex flex-row justify-content-between'>
        <div>
          <p className='mb-0 fw-medium'>Deze klant maakt geen deel uit van het loyaliteitsprogramma.</p>
          <p className='fw-medium'>Hij/zijn zal zich automatisch aansluiten bij de eerste ontvangerspunten</p>
        </div>
        <div>
          <div className="d-flex flex-column align-content-center">
            <label className="fw-medium">Deelnemen</label>
            <label className="switchNewCustomer d-flex align-self-center">
              <input 
                type="checkbox" 
                checked={isChecked}
                onChange={handleCheckboxChange} 
              />
              <span className="sliderNewCustomer round"></span>
            </label>
          </div>
        </div>
      </div>
      }
    </>
  );
}
