import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ModalStyle.css";
import middleware from "../Api/Middleware";
import CategoryList from '../Products/subpages/sub_components/CategoryList';


const CategoryModal = ({ openCategory, closeCategory }) => {

  const [showCategory, setShowCategory] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [initialCategoriesNew, setInitialCategoriesNew] = useState([
    {id: 1, headId: null, name: 'Categories'}
  ]);
  const [subCategory, setSubCategory] = useState(false);
  const [titleCategory, setTitleCategory] = useState("");
  const [searchQueryNew, setSearchQueryNew] = useState('');
  const [catId, setCatId] = useState();
  const [descriptionCategory, setDescriptionCategory] = useState("");
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [checkedCategoryId, setCheckedCategoryId] = useState({});
  
  const organizeCategory = (categories, parentId = null) => {
    // Ensure the input is an array
    return categories
        .filter(category => category.headId === parentId)
        .map(category => {
            return {
                ...category,
                subcategories: organizeCategory(categories, category.id)
            }
        });
};
  
  const [categoryTreeNew, setCategoryTreeNew] = useState(organizeCategory(initialCategoriesNew));
  const [filteredCategoryNew, setFilteredCategoryNew] = useState(categoryTreeNew);
  const [checkedCategoryNew, setCheckedCategoryNew] = useState({});
  const [checkedCategories, setCheckedCategories] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchAll = async () => {
    setIsLoading(true);
    const [fetchCategories] = await Promise.all([
      middleware.get(`products/category`),
    ]);
    const cat = fetchCategories.data.map(map => {
      return {
        primary_key: map.primary_key,
        id: map.category_id,
        headId: map.head_category_id,
        name: map.title,
      };
    });
    setInitialCategoriesNew(cat)
    setLoadingScreenFunc();
  };

  useEffect(() => {
    fetchAll();
  }, []);


  useEffect(() => {
    setFilteredCategoryNew(categoryTreeNew);
  }, [categoryTreeNew])

  useEffect(() => {
    filterCategoryNew();
  }, [initialCategoriesNew])

  useEffect(() => {
    if (openCategory) {
      setShowCategory(true)
    }
  }, [openCategory]);

  const filterCategoryNew = (query) => {
    if (!query) {
      setCategoryTreeNew(organizeCategory(initialCategoriesNew));
      return;
    }

    setCategoryTreeNew(filterCategoryRecursivelyNew(query, initialCategoriesNew));
  };

  const filterCategoryRecursivelyNew = (query, category) => {
    const lowerCaseQuery = query.toLowerCase();
  
    return category
      .map(category => {
        let categoryMatches = category.name.toLowerCase().includes(lowerCaseQuery);
        let filteredSubcategory = [];
        let includedDueToChild = false;
        
        if (category.subcategory) {
          filteredSubcategory = filterCategoryRecursivelyNew(query, category.subcategory);
          includedDueToChild = filteredSubcategory.length > 0;
        }
  
        if (categoryMatches || includedDueToChild) {
          return {
            ...category,
            subcategory: filteredSubcategory,
            isIncludedDueToChild: !categoryMatches && includedDueToChild
          };
        }
  
        return null;
      })
      .filter(Boolean);
  };

  const closeCategoryModal = () => {
    setShowCategory(false);
    closeCategory(true)
  }

  function setLoadingScreenFunc() {
    setIsLoading(false);
    setTimeout(() => {
      setLoadingScreen(false);
    }, 500);
  }


  

  const extractNumericId = (complexId) => {
    const regex = /(\d+)-(\d+)-(\d+)-(\d+)$/;
    const match = complexId.match(regex);
    console.log(match);
    if (!match) {
      return null;
    }
    return {
      listId: match[1],
      primary_key: match[2],
      category_id: match[3],
      headCatId: match[4],
    };
  };

  const toggle = (id) => {
    if (true) {
      // Extract only the numeric ID
      const numericId = extractNumericId(id);  
      setCatId(numericId.category_id)
      setCheckedCategoryId(numericId.primary_key);
  
      if (id !== null) {
        if (checkedCategoryNew[id]) {
          setCheckedCategoryNew({}); // Clear the state if already checked
        } else {
          setCheckedCategoryNew({ [id]: true }); // Only the clicked item will be set to true
        }
      }
    }
  };

  const submitNewCategorie = async (event) => {
    try {
      if (event) {
        event.preventDefault();
      }
      if (titleCategory) 
      {
        if (subCategory == true && selectedCategory) {
          const res = await middleware.post("products/category", {
            title: titleCategory,
            description: descriptionCategory,
            head_category_id: catId,
            status: 0,
          });  
        } else {
          const res = await middleware.post("products/category", {
            title: titleCategory,
            description: descriptionCategory,
            status: 0,
          });  
        }
        
          window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal
        id="modalBlueprint"
        show={showCategory}
        onHide={() => closeCategoryModal()}
        
        size="md"
        backdrop="static"
      >
        <Modal.Header className="modalTop justify-content-between">
          <h4 className='ms-3'>Voeg een categorie toe</h4>
          <button variant="transparent" className=' btn' onClick={() => closeCategoryModal()}>
            <FontAwesomeIcon icon={faXmark} className="closeModal" />
          </button>
        </Modal.Header>
        <form onSubmit={submitNewCategorie}>
          <Modal.Body className="modalCenter pb-1">
            <div className="contendModal w-100">
              <div className='d-flex w-100 flex-column'>
                <label className='inputTitle'>Titel * :</label>
                <input onBlur={(e) => setTitleCategory(e.target.value)} required className='w-100 optionsList ps-2 borderInputs mb-1 pe-1 ps-0 text-nowrap'></input>              
              </div>
              <div className='d-flex w-100 flex-column mb-2'>
                <label className='inputTitle'>Beschrijving :</label>
                <textarea
                  className="borderInputs rounded-2 mb-2 w-100"
                  type="text"
                  name="categoryDescription"
                  onBlur={(e) => setDescriptionCategory(e.target.value)}
                ></textarea>   
              </div> 
              <div className='d-flex w-100 flex-column'>
                <div className="d-flex flex-row mb-2">
                  <label className="checkbox-container checkSimple">
                    <input
                      type="checkbox"
                      value="subProduct"
                      checked={subCategory ?? ""}
                      onChange={(e) => setSubCategory(e.target.checked)}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <p className="inputTitle user-select-none" style={{position: 'relative',bottom: '6px'}}>Subcategorie</p>
                </div>
              </div>
              { subCategory === true ?
                <div className="sellChannelBoxContainer">
                  <div className=' d-flex flex-column'>
                    <label className='inputTitle m-0'>Plek van de Subcategorie</label>
                    <small className='mb-1'>(komt onder de categorie die je hebt geselecteerd)</small>
                  </div>
                  
                  <div className='categorieënBox bg-white' style={{ maxHeight: "300px"}}>
                    <div className='px-2 d-flex position-sticky py-2 inputFieldCategory'>
                      <input
                        type="text"
                        value={searchQueryNew}
                        onChange={(e) => setSearchQueryNew(e.target.value)}
                        placeholder="Search categories"
                        className='w-100 searchInput'
                      />
                    </div>
                    <div className='categoryList'>
                      <CategoryList 
                        categories={filteredCategoryNew} 
                        checkedCategories={checkedCategoryNew} 
                        toggle={toggle}
                        textsub={"Plek van subcategorie"}
                        show={showCategory}
                        listId={2}
                      />
                    </div>
                  </div>
                </div>
                
                :
                ""
              }

            </div>
          </Modal.Body>
          <Modal.Footer className="modalBottom">
            <button
              type='submit'
              className="btn bg-white text-secondary buttonStyle border-0"
            >
              Toevoegen
            </button>
          </Modal.Footer>
        </form>
      </Modal>
  );
}

export default CategoryModal;
