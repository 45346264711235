import React, { useState } from 'react';
import "../Main/Main.css";
import PageTemplate from '../Templates/PageTemplate';
import Accordion from './ComponentsCategory/AccordionCategory';
import { useNavigate } from 'react-router';


const Category = () => {
  const navigate = useNavigate();

  return (
    <PageTemplate navbarTitle={"Warehouse HUB"}>
      <div className='whiteBox w-100 px-5 py-4' style={{height: '80vh'}}>
        <div className='d-flex flex-row justify-content-between pb-3 mb-3 borderBottomGray'>
          <h3 className='mb-0 fw-bold'>Categorieën</h3>
          <button className=' lightBlueStandardButton' onClick={() => navigate("./category_Toevoegen")}>Nieuw toevoegen</button>
        </div>
        <div>
          <Accordion title={"Broek"} statusCategory={"Gepubliceerd"} content={<><Accordion title={"denim"} statusCategory={"Gepubliceerd"} content={"hoi"} /></>} />
          <Accordion title={"Broek"} statusCategory={"Gepubliceerd"} content={<><Accordion title={"denim"} statusCategory={"Gepubliceerd"} content={"hoi"} /></>} />
          <Accordion title={"Broek"} statusCategory={"Gepubliceerd"} content={
          <>
            <Accordion title={"denim"} statusCategory={"Gepubliceert"} content={""} />
            <Accordion title={"denim"} statusCategory={"Gepubliceert"} content={"hoi"} />
            <Accordion title={"denim"} statusCategory={"Gepubliceert"} content={""} />
          </>
          }/>
        </div>
      </div>
    </PageTemplate>
  );
}

export default Category;
